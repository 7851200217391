import { Checkbox, Tooltip } from '@material-ui/core';
import { escapeRegExp, startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { currencyFormat } from 'shared/common/string-format-utils';

import { FileDownloadLink } from '../../core-ui/file-download-link';
import { Invoice } from '../../graphql/queries/accounting';
import { CellValue, DataPanel, DataPanelColumn } from '../data-panel';
import { InvoiceBankTransactionsInfo } from '../invoice-bank-transactions-info';

export type Column =
    | 'month'
    | 'customer'
    | 'invoiceNumber'
    | 'amount'
    | 'status'
    | 'dueDate'
    | 'invoiceDate'
    | 'bankPayment'
    | 'unpaidAmount'
    | 'select'
    | 'payments';

const allColumns: Array<DataPanelColumn<Column>> = [
    { id: 'select', label: '' },
    { id: 'month', label: 'Month' },
    { id: 'customer', label: 'Customer' },
    { id: 'invoiceNumber', label: 'Invoice #' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' },
    { id: 'dueDate', label: 'Due Date' },
    { id: 'invoiceDate', label: 'Invoice Date' },
    { id: 'bankPayment', label: 'Bank Payment' },
    { id: 'unpaidAmount', label: 'Unpaid Amount' },
    { id: 'payments', label: 'Payments' }
];

export const InvoicesDataPanel: React.FC<{
    invoices: Invoice[];
    searchText: string;
    columns: Column[];
    selected?: string[];
    onSelect?: (invoiceId: string) => void;
}> = ({ invoices, searchText, columns, selected, onSelect }) => {
    const handleSelect = (invoiceId: string) => () => {
        onSelect?.(invoiceId);
    };

    const getCellValue = (column: Column) => (invoice: Invoice) => {
        switch (column) {
            case 'month':
                return invoice.invoiceDate;
            case 'customer':
                return invoice.customer?.name || '-';
            case 'invoiceNumber':
                return invoice.invoiceNumber;
            case 'amount':
                return invoice.totalAmount;
            case 'dueDate':
                return invoice.dueDate;
            case 'invoiceDate':
                return invoice.invoiceDate;
            case 'bankPayment':
                return invoice.bankTransactions?.[0]?.transaction?.postedAt ?? 0;
            case 'status': {
                if (invoice.status === 'OPEN' && invoice.dueDate < Date.now()) {
                    return 'Overdue';
                } else {
                    return startCase(invoice.status.toLocaleLowerCase());
                }
            }
            case 'unpaidAmount':
                return invoice.unpaidAmount;
            case 'select':
                return '';
            case 'payments':
                return invoice.payments?.reduce((acc, payment) => acc + payment.amount, 0) ?? '';
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue) => <span>${(value as number).toLocaleString()}</span>,
        bankPayment: (_1: CellValue, invoice: Invoice) => <InvoiceBankTransactionsInfo invoice={invoice} />,
        dueDate: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        invoiceDate: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        invoiceNumber: (value: CellValue, invoice: Invoice) => {
            const fileName = invoice.pdf?.split('/')?.pop();
            return invoice.pdf ? (
                <FileDownloadLink path={invoice.pdf} filename={fileName}>
                    <span className="clickable-cell">{value}</span>
                </FileDownloadLink>
            ) : (
                <span>{value}</span>
            );
        },
        month: (value: CellValue) => moment(value as number).format('MMM YYYY'),
        payments: (value: CellValue) => <span>${(value as number).toLocaleString()}</span>,
        select: (_1: CellValue, invoice: Invoice) => (
            <Checkbox checked={selected?.includes(invoice.id)} onChange={handleSelect(invoice.id)} />
        ),
        status: (value: CellValue, invoice: Invoice) => (
            <Tooltip title={startCase(invoice.status.toLocaleLowerCase())}>
                <span>{value}</span>
            </Tooltip>
        ),
        unpaidAmount: (value: CellValue) => <span>${(value as number).toLocaleString()}</span>
    };

    const getCellFilterValue = {
        bankPayment: (_1: CellValue, invoice: Invoice) =>
            invoice.status === 'OPEN'
                ? 'Not Paid'
                : invoice.status === 'PARTIAL_PAYMENT'
                  ? 'Partially Paid'
                  : invoice.bankTransactions?.length === 0
                    ? 'Missing'
                    : 'Matched',
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const renderSummary = React.useCallback(
        (list: Invoice[]) => {
            if (!list) return null;
            if (selected) {
                const selectedTotal = list
                    .filter((invoice) => selected?.includes(invoice.id))
                    .reduce((acc, invoice) => acc + invoice.totalAmount, 0);
                return <div>Selected: ${selectedTotal.toLocaleString()}</div>;
            }
            const total = list.reduce((acc, invoice) => acc + invoice.totalAmount, 0);
            return <div>Total: {currencyFormat(total)}</div>;
        },
        [invoices, selected]
    );

    const rowFilter = React.useCallback(
        (invoice: Invoice) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                getCellValue('customer')(invoice)?.toString().match(searchRegex) ||
                getCellValue('invoiceNumber')(invoice)?.toString().match(searchRegex) ||
                getCellValue('status')(invoice)?.toString().match(searchRegex) ||
                invoice.lineItems?.some((lineItem) => lineItem.description?.match(searchRegex)) ||
                getCellValue('amount')(invoice)?.toString().match(searchRegex) ||
                invoice.payments?.some((payment) => payment.amount.toString().match(searchRegex))
            );
        },
        [searchText, getCellValue]
    );

    const sortableColumns = ['month', 'customer', 'amount', 'status', 'dueDate', 'invoiceDate', 'bankPayment'];
    const displayedColumns = allColumns.filter((column) => columns.includes(column.id));

    return (
        <div className="data-panel">
            <DataPanel
                columns={displayedColumns}
                data={invoices}
                getCellValue={getCellValue}
                getCellRenderer={getCellRenderer}
                getCellFilterValue={getCellFilterValue}
                sortableColumns={sortableColumns}
                filterableColumns={['month', 'status', 'customer', 'bankPayment']}
                initialSortColumn="month"
                initialSortDirection="desc"
                renderSummary={renderSummary}
                rowFilter={rowFilter}
            />
        </div>
    );
};
