import { css } from '@emotion/core';
import { Button, Theme, Tooltip, useTheme } from '@material-ui/core';
import { Receipt } from '@material-ui/icons';
import { sortBy } from 'lodash';
import React from 'react';
import { hasRole } from 'shared/models/user';

import { BaseFee, Invoice } from '../graphql/queries/billing';
import { useSession } from '../hooks/use-session';
import { CreateInvoiceButton } from './create-invoice-button';
import { InvoiceDetailsDialog } from './invoice-details-dialog';

const styles = (theme: Theme) => css`
    &.form-row,
    &.form-field-row {
        flex-wrap: wrap;
        position: relative;
        padding-top: 24px;

        .field-label {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            transform: translate(0, 0) scale(0.75);
            transform-origin: top left;
            transition: all 200ms ease-out;
            color: ${theme.palette.text.secondary};
        }

        /* Hide margin if the row is empty */
        &:empty {
            margin-bottom: 0;
            display: none;
        }
    }
`;

interface FeeInvoicesProps {
    fee: BaseFee;
    invoices?: Invoice[];
    className?: string;
}

export const FeeInvoices: React.FC<FeeInvoicesProps> = ({ fee, invoices = [], className }) => {
    const { userPermissions } = useSession();
    const theme = useTheme();
    const [selectedInvoiceId, setSelectedInvoiceId] = React.useState<string | undefined>(undefined);

    const handleShowInvoice = (invoice: Invoice) => () => {
        setSelectedInvoiceId(invoice.id);
    };

    const handleCloseInvoiceDetails = () => {
        setSelectedInvoiceId(undefined);
    };

    const invoicesList = sortBy(invoices, 'invoiceDate').map((invoice) => (
        <div className="invoice" key={invoice.id}>
            <Tooltip title="View Invoice">
                <Button variant="outlined" startIcon={<Receipt />} onClick={handleShowInvoice(invoice)}>
                    {invoice.invoiceNumber}
                </Button>
            </Tooltip>
        </div>
    ));

    let invoiceDialog;
    if (selectedInvoiceId) {
        const invoice = invoices.find((i) => i.id === selectedInvoiceId);
        invoiceDialog = <InvoiceDetailsDialog open={true} onClose={handleCloseInvoiceDetails} invoice={invoice} />;
    }

    if (invoices.length === 0 && !hasRole(userPermissions, 'billing_admin')) {
        return null;
    }

    return (
        <div className={className} css={styles(theme)}>
            <div className="field-label">Invoice</div>
            {invoicesList}
            <CreateInvoiceButton clientId={fee.clientId} fee={fee} />
            {invoiceDialog}
        </div>
    );
};
