import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { List, ListItem, ListItemText, Theme, useTheme } from '@material-ui/core';
import { startCase } from 'lodash';
import React from 'react';
import { hasRole } from 'shared/models/user';
import { fullDate } from '../common/timestamp';

import { client as apolloClient } from '../graphql/apollo-client';
import { FeeCommission, GET_FEE_COMMISSIONS } from '../graphql/queries/commissions';
import { useSession } from '../hooks/use-session';

const styles = (theme: Theme) => css`
    &.form-row,
    &.form-field-row {
        flex-wrap: wrap;
        position: relative;
        padding-top: 10px;

        .field-label {
            position: absolute;
            top: 0;
            left: 0;
            font-size: 1rem;
            transform: translate(0, 0) scale(0.75);
            transform-origin: top left;
            transition: all 200ms ease-out;
            color: ${theme.palette.text.secondary};
        }

        /* Hide margin if the row is empty */
        &:empty {
            margin-bottom: 0;
            display: none;
        }
    }
`;

const payrollClient = apolloClient('payroll_admin');
const recruiterClient = apolloClient('recruiter');

export const FeeCommissions: React.FC<{ feeId: string; className?: string }> = ({ feeId, className }) => {
    const theme = useTheme();
    const { userPermissions } = useSession();
    const { data } = useQuery<{ commissions: FeeCommission[] }, { feeId: string }>(GET_FEE_COMMISSIONS, {
        client: hasRole(userPermissions, 'payroll_admin') ? payrollClient : recruiterClient,
        variables: { feeId }
    });

    if (!data || data.commissions.length === 0) return null;

    const commissions = data.commissions.map((commission) => {
        const commissionDate =
            commission.status === 'paid'
                ? `Paid: ${fullDate(commission.processedAt)}`
                : `Due: ${fullDate(commission.dueAt)}`;
        const secondaryText = (
            <div>
                ${commission.amount.toLocaleString()}
                <br />
                {startCase(commission.commissionType)}
                <br />
                {startCase(commission.status)}
                <br />
                {commissionDate}
            </div>
        );
        return (
            <ListItem key={commission._id} disableGutters={true}>
                <ListItemText primary={commission.user.name} secondary={secondaryText} />
            </ListItem>
        );
    });

    return (
        <div className={className} css={styles(theme)}>
            <div className="field-label">Commissions</div>
            <List dense={true} disablePadding={true}>
                {commissions}
            </List>
        </div>
    );
};
