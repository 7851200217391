import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React from 'react';

import { FeeType } from 'shared/models/fee';

import { DateTextField, SelectField, TextField } from '../core-ui/form-fields';
import { BaseFee, CANDIDATE_COMMISSION_PERCENTAGES, Invoice as InvoiceData } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { CandidateMonthlyFeeAutoGenerateSetting } from './candidate-monthly-fee-auto-generate-setting';
import { FeeCommissions } from './fee-commissions';
import { FeeInvoices } from './fee-invoices';
import { useFormContextData } from './form-context';

interface PlacementFeeFormProps {
    data: BaseFee & { invoices?: InvoiceData[]; person?: { name: string } };
    disabled: boolean;
    onClose: (e: React.MouseEvent) => void;
    onSave?: (data: BaseFee) => Promise<void>;
}

const styles = css`
    .MuiDialogContent-root {
        width: 720px;
    }

    .MuiFormLabel-root.Mui-disabled,
    .MuiInputBase-root.Mui-disabled {
        color: inherit;
        opacity: 0.8;
    }

    .form-field-row {
        margin-bottom: 32px;
        display: flex;
        gap: 24px;

        &.checkbox-field {
            margin-top: -16px;
            margin-bottom: 16px;
        }
    }
`;

export const PlacementFeeForm: React.FC<PlacementFeeFormProps> = ({
    data: initialData,
    onClose,
    onSave,
    disabled: propDisabled
}) => {
    const initialFeeData: BaseFee = {
        accountManagerCommission: initialData.accountManagerCommission,
        amount: initialData.amount,
        clientId: initialData.clientId,
        dueAt: initialData.dueAt,
        guaranteeCompletionAt: initialData.guaranteeCompletionAt,
        id: initialData.id,
        incurredAt: initialData.incurredAt,
        recruiterCommission: initialData.recruiterCommission,
        salary: initialData.salary,
        signOnBonus: initialData.signOnBonus,
        type: initialData.type
    };
    const { invoices, person } = initialData;
    const { data, onFieldChange, getError, validateFieldExists, isDirty } = useFormContextData<BaseFee>(initialFeeData);
    const { data: candidateCommissionData, loading: candidateCommissionLoading } = useQuery<
        { percentages: { recruiterPercentage: number; accountManagerPercentage: number } },
        { personId: string; jobId: string }
    >(CANDIDATE_COMMISSION_PERCENTAGES, {
        variables: { personId: initialData.personId, jobId: initialData.jobId }
    });
    const { getConfirmation } = useModal();
    const [isSaving, setIsSaving] = React.useState(false);

    const disabled = candidateCommissionLoading || propDisabled || !onSave;
    const validateData = () => {
        let valid = true;
        valid = validateFieldExists('incurredAt')(data.incurredAt) && valid;
        valid = validateFieldExists('dueAt')(data.dueAt) && valid;
        valid =
            (data.type === 'placement'
                ? validateFieldExists('guaranteeCompletionAt')(data.guaranteeCompletionAt)
                : true) && valid;
        valid = validateFieldExists('salary')(data.salary) && valid;
        valid = validateFieldExists('amount')(data.amount) && valid;
        valid = validateFieldExists('recruiterCommission')(data.recruiterCommission) && valid;
        valid = validateFieldExists('accountManagerCommission')(data.accountManagerCommission) && valid;
        return valid;
    };

    const handleSave = async () => {
        if (!validateData()) return;
        setIsSaving(true);
        try {
            await onSave(data);
        } finally {
            setIsSaving(false);
        }
    };

    const handleCancel = (e: React.MouseEvent) => {
        if (isDirty) {
            getConfirmation(() => onClose(e), 'Cancel and discard changes?', 'Confirm Discard');
        } else {
            onClose(e);
        }
    };

    const handleStartDateChange = (val: number) => {
        onFieldChange('dueAt')(val);
        validateFieldExists('dueAt')(val);
        if (data.type === 'placement') {
            // tslint:disable-next-line: no-magic-numbers
            const defaultGuaranteeDate = val + 90 * 24 * 60 * 60 * 1000; // 90 days
            onFieldChange('guaranteeCompletionAt')(defaultGuaranteeDate);
            validateFieldExists('guaranteeCompletionAt')(defaultGuaranteeDate);
        }
    };

    const handleFeeChange = (val: number) => {
        onFieldChange('amount')(val);
        validateFieldExists('amount')(val);
        const recruiterCommission = (val * candidateCommissionData.percentages.recruiterPercentage) / 100;
        onFieldChange('recruiterCommission')(recruiterCommission);
        validateFieldExists('recruiterCommission')(recruiterCommission);
        const amCommission = (val * candidateCommissionData.percentages.accountManagerPercentage) / 100;
        onFieldChange('accountManagerCommission')(amCommission);
        validateFieldExists('accountManagerCommission')(amCommission);
    };

    const readOnlyActions = <Button onClick={onClose}>Close</Button>;

    const editActions = (
        <React.Fragment>
            <Button onClick={handleCancel} disabled={isSaving}>
                Cancel
            </Button>
            <Button onClick={handleSave} disabled={isSaving}>
                {isSaving ? 'Saving...' : 'Save'}
            </Button>
        </React.Fragment>
    );

    const feeTypeOptions: Array<{ label: string; value: FeeType }> = [
        { label: 'Placement', value: 'placement' },
        { label: 'Monthly Contractor', value: 'monthly-contractor' }
    ];

    const guaranteeField =
        data.type === 'placement' ? (
            <DateTextField
                label="Guarantee Date"
                value={data.guaranteeCompletionAt}
                onChange={onFieldChange('guaranteeCompletionAt')}
                disabled={disabled}
                validate={validateFieldExists('guaranteeCompletionAt')}
                errorText={getError('guaranteeCompletionAt')}
            />
        ) : null;

    const salaryField =
        data.type === 'placement' ? (
            <TextField
                label="Sign-On Bonus"
                value={data.signOnBonus}
                onChange={onFieldChange('signOnBonus')}
                disabled={disabled}
            />
        ) : null;

    const autoGenerateFeesField =
        data.type === 'monthly-contractor' ? (
            <div className="form-field-row checkbox-field">
                <CandidateMonthlyFeeAutoGenerateSetting
                    disabled={disabled}
                    personId={initialData.personId}
                    jobId={initialData.jobId}
                />
            </div>
        ) : null;

    return (
        <Dialog open={true} onClose={onClose} maxWidth="md" css={styles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Placement Fees {person?.name ? ` - ${person.name}` : ''}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-field-row">
                    <SelectField
                        label="Fee Type"
                        value={data.type}
                        onChange={onFieldChange('type')}
                        disabled={disabled}
                        validate={validateFieldExists('type')}
                        errorText={getError('type')}
                        options={feeTypeOptions}
                    />
                </div>
                <div className="form-field-row">
                    <DateTextField
                        label={data.type === 'placement' ? 'Hired Date' : 'Contract Start Date'}
                        value={data.incurredAt}
                        onChange={onFieldChange('incurredAt')}
                        disabled={disabled}
                        validate={validateFieldExists('incurredAt')}
                        errorText={getError('incurredAt')}
                    />
                </div>
                <div className="form-field-row">
                    <DateTextField
                        label={data.type === 'placement' ? 'Start Date' : 'Contract End Date'}
                        value={data.dueAt}
                        onChange={handleStartDateChange}
                        disabled={disabled}
                        validate={validateFieldExists('dueAt')}
                        errorText={getError('dueAt')}
                    />
                    {guaranteeField}
                </div>
                <div className="form-field-row">
                    <TextField
                        label={data.type === 'placement' ? 'Base Salary' : 'Monthly Rate'}
                        value={data.salary}
                        onChange={onFieldChange('salary')}
                        disabled={disabled}
                        validate={validateFieldExists('salary')}
                        errorText={getError('salary')}
                    />
                    {salaryField}
                </div>
                <div className="form-field-row">
                    <TextField
                        label={data.type === 'placement' ? 'Placement Fees' : 'Monthly Fees'}
                        value={data.amount}
                        onChange={handleFeeChange}
                        disabled={disabled}
                        validate={validateFieldExists('amount')}
                        errorText={getError('amount')}
                    />
                </div>
                <div className="form-field-row">
                    <TextField
                        label="Recruiter Commission"
                        value={data.recruiterCommission}
                        disabled={disabled}
                        validate={validateFieldExists('recruiterCommission')}
                        errorText={getError('recruiterCommission')}
                    />
                    <TextField
                        label="AM Commission"
                        value={data.accountManagerCommission}
                        disabled={disabled}
                        validate={validateFieldExists('accountManagerCommission')}
                        errorText={getError('accountManagerCommission')}
                    />
                </div>
                {autoGenerateFeesField}
                <FeeInvoices fee={initialData} invoices={invoices} className="form-field-row" />
                <FeeCommissions feeId={initialData.id} className="form-field-row" />
            </DialogContent>
            <DialogActions>{disabled || (!isDirty && !!data?.id) ? readOnlyActions : editActions}</DialogActions>
        </Dialog>
    );
};
