import { Button, useTheme } from '@material-ui/core';
import { isEqual } from 'lodash';
import React from 'react';
import isEmail from 'validator/lib/isEmail';

import { standardizeUrl } from 'profile-parser';

import { hasRole } from 'shared/models/user';

import { standardizeBlacklistEntry } from '../common/blacklist-utils';
import { isValidEmailDomain } from '../common/email/utils';
import { isValidUrl } from '../common/utils';
import { Autocomplete, MultiAutocomplete, RichTextField, SelectField, TextField } from '../core-ui/form-fields';
import { ClientFormData } from '../graphql/queries/clients';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { useUsers } from '../hooks/use-users';
import { ClientContracts } from './client-contracts';
import { ClientFormCompanySpecificBlacklist } from './client-form-company-specific-blacklist';
import { ClientFormGlobalBlacklist } from './client-form-global-blacklist';
import { ClientFormHiringManagers } from './client-form-hiring-managers';
import { ClientFormPersonBlacklist } from './client-form-person-blacklist';
import { styles } from './client-form-styles';
import { FormContext, FormContextProps, useFormContextData } from './form-context';

export const ClientForm: React.FC<{ data: ClientFormData; onSave: (data: ClientFormData) => Promise<void> }> = ({
    data: initialData,
    onSave
}) => {
    const theme = useTheme();
    const context = useFormContextData<ClientFormData>(initialData);
    const { data, onFieldChange, onChange, getError, setError, validateFieldExists, isDirty, resetForm, hasErrors } =
        context;
    const { users } = useUsers([data.accountManagerId]);
    const [saving, setSaving] = React.useState(false);
    const { setSnackbar } = useSnackbar();
    const { userPermissions } = useSession();
    const { getConfirmation, setAlert } = useModal();

    const canEdit = hasRole(userPermissions, 'client_creator');
    const disabled = !canEdit || saving;

    React.useEffect(() => {
        if (!isDirty || isEqual(initialData, data)) {
            resetForm(initialData);
        } else {
            getConfirmation(
                () => {
                    resetForm(initialData);
                    setSnackbar('Data updated to the latest version');
                },
                'Client data has been updated elsewhere. Press OK to reset to the latest data.',
                'Client data changed',
                true
            );
        }
    }, [initialData]);

    const userOptions =
        users?.map((user) => ({
            label: user.name,
            value: user.id
        })) ?? [];

    const handleNameVariationsChange = (newValue: string[]) => {
        onFieldChange('links')(newValue.map(standardizeBlacklistEntry));
    };

    const handleChangeSendAutoReminders = (value: string) => {
        onFieldChange('sendAutoBillComReminders')(value === 'true');
    };

    const validateDomains = (domains: string[]) => {
        let valid = true;
        domains.forEach((domain) => {
            const validDomain = isValidEmailDomain(domain);
            if (!validDomain) {
                valid = false;
                setError('domains', 'Please enter valid domains');
            }
        });
        if (valid) {
            setError('domains', undefined);
        }
        return valid;
    };

    const validateInvoiceTo = (invoiceTo: string) => {
        if (!isEmail(invoiceTo)) {
            setError('invoiceTo', 'Please enter valid email addresses');
            return false;
        } else {
            setError('invoiceTo', undefined);
            return true;
        }
    };

    const validateInvoiceCc = (invoiceCc: string[]) => {
        let valid = true;
        invoiceCc.forEach((email) => {
            if (!isEmail(email)) {
                valid = false;
                setError('invoiceCc', 'Please enter valid email addresses');
            }
        });
        if (valid) {
            setError('invoiceCc', undefined);
        }
        return valid;
    };

    const validateInvoiceFollowUpContacts = (invoiceFollowUpContacts: string[]) => {
        let valid = true;
        invoiceFollowUpContacts.forEach((email) => {
            if (!isEmail(email)) {
                valid = false;
                setError('invoiceFollowUpContacts', 'Please enter valid email addresses');
            }
        });
        if (valid) {
            setError('invoiceFollowUpContacts', undefined);
        }
        return valid;
    };

    const validateLinkedInUrl = () => {
        const url = data.linkedinUrl;
        if (url) {
            const formatted =
                'https://' + standardizeUrl(url?.trim())?.replace(/(?<!\/recruiter)\/company\//, '/recruiter/company/');
            if (!isValidUrl(formatted) || !formatted?.match(/linkedin\.com\/recruiter\/company\/\d+$/)) {
                setError('linkedinUrl', 'Please enter a valid LinkedIn Recruiter URL');
                return false;
            } else {
                const standardizedUrl = standardizeBlacklistEntry(formatted);
                const newLinks = !data.links.includes(standardizedUrl) ? [...data.links, standardizedUrl] : data.links;
                onChange({
                    linkedinUrl: formatted,
                    links: newLinks
                });
                setError('linkedinUrl', undefined);
                return true;
            }
        } else {
            setError('linkedinUrl', undefined);
            return true;
        }
    };

    const handleSubmit = async () => {
        if (hasErrors) {
            setAlert('Errors exist', 'Please fix the errors before saving');
        } else {
            try {
                setSaving(true);
                setSnackbar('Saving...');
                await onSave(data);
                resetForm(data);
                setSnackbar('Saved');
            } catch {
                setAlert('Error', 'Failed to save');
            } finally {
                setSaving(false);
            }
        }
    };

    const handleResetButtonClick = () => {
        getConfirmation(
            () => {
                resetForm(initialData);
            },
            'Are you sure you want to discard all changes?',
            'Discard changes'
        );
    };

    const resetButton = isDirty ? (
        <Button onClick={handleResetButtonClick} disabled={disabled}>
            Discard
        </Button>
    ) : null;

    const formContext: FormContextProps<ClientFormData> = { ...context, disabled };

    const booleanOptions = [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ];

    return (
        <FormContext.Provider value={formContext}>
            <div css={styles(theme)}>
                <div className="client-form">
                    <div className="form-field-row">
                        <TextField
                            label="Name"
                            value={data.name}
                            onChange={onFieldChange('name')}
                            disabled={disabled}
                            errorText={getError('name')}
                            validate={validateFieldExists('name')}
                        />
                    </div>
                    <div className="form-field-row">
                        <Autocomplete
                            label="Account Manager"
                            options={userOptions}
                            value={data.accountManagerId}
                            onChange={onFieldChange('accountManagerId')}
                            validate={validateFieldExists('accountManagerId')}
                            disabled={disabled}
                            errorText={getError('accountManagerId')}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="Linkedin Recruiter URL"
                            value={data.linkedinUrl}
                            onChange={onFieldChange('linkedinUrl')}
                            disabled={disabled}
                            errorText={getError('linkedinUrl')}
                            onBlur={validateLinkedInUrl}
                        />
                    </div>
                    <div className="form-field-row">
                        <MultiAutocomplete
                            value={data.domains}
                            label="Domains"
                            onChange={onFieldChange('domains')}
                            disabled={disabled}
                            options={[]}
                            freeSolo={true}
                            validate={validateDomains}
                            errorText={getError('domains')}
                        />
                    </div>
                    <div className="form-field-row">
                        <MultiAutocomplete
                            value={data.links}
                            label="Client Name Variations and Links"
                            onChange={handleNameVariationsChange}
                            disabled={disabled}
                            options={[]}
                            freeSolo={true}
                        />
                    </div>
                    <div className="form-field-row">
                        <ClientFormGlobalBlacklist />
                    </div>
                    <div className="form-field-row">
                        <ClientFormCompanySpecificBlacklist />
                    </div>
                    <div className="form-field-row">
                        <ClientFormPersonBlacklist />
                    </div>
                    <div className="form-field-row">
                        <ClientFormHiringManagers />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="HQ Location"
                            value={data.hq}
                            onChange={onFieldChange('hq')}
                            disabled={disabled}
                            multiline={true}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="Founders"
                            value={data.founders}
                            onChange={onFieldChange('founders')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="Funding"
                            value={data.funding}
                            onChange={onFieldChange('funding')}
                            disabled={disabled}
                            multiline={true}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="Team Size"
                            value={data.teamSize}
                            onChange={onFieldChange('teamSize')}
                            disabled={disabled}
                            multiline={true}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            label="Founded Date"
                            value={data.foundedDate}
                            onChange={onFieldChange('foundedDate')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-field-row">
                        <RichTextField
                            value={data.description}
                            label="Detailed Description"
                            onChange={onFieldChange('description')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            value={data.salesPitch}
                            label="Memorable Selling Point"
                            onChange={onFieldChange('salesPitch')}
                            disabled={disabled}
                            multiline={true}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            value={data.paymentTermDays}
                            label="Payment Term Days"
                            onChange={onFieldChange('paymentTermDays')}
                            disabled={disabled}
                            type="number"
                        />
                        <TextField
                            value={data.defaultPONumber}
                            label="Default PO Number"
                            onChange={onFieldChange('defaultPONumber')}
                            disabled={disabled}
                        />
                    </div>
                    <div className="form-field-row">
                        <TextField
                            value={data.invoiceTo}
                            label="Invoice To"
                            onChange={onFieldChange('invoiceTo')}
                            disabled={disabled}
                            validate={validateInvoiceTo}
                            errorText={getError('invoiceTo')}
                        />
                    </div>
                    <div className="form-field-row">
                        <MultiAutocomplete
                            value={data.invoiceCc}
                            label="Invoice CC"
                            onChange={onFieldChange('invoiceCc')}
                            disabled={disabled}
                            options={[]}
                            freeSolo={true}
                            validate={validateInvoiceCc}
                            errorText={getError('invoiceCc')}
                        />
                    </div>
                    <div className="form-field-row">
                        <MultiAutocomplete
                            value={data.invoiceFollowUpContacts}
                            label="Invoice Follow Up Contacts"
                            onChange={onFieldChange('invoiceFollowUpContacts')}
                            disabled={disabled}
                            options={[]}
                            freeSolo={true}
                            validate={validateInvoiceFollowUpContacts}
                            errorText={getError('invoiceFollowUpContacts')}
                        />
                    </div>
                    <div className="form-field-row">
                        <SelectField
                            value={data.sendAutoBillComReminders ? 'true' : 'false'}
                            label="Send Automatic Bill.com Reminders"
                            onChange={handleChangeSendAutoReminders}
                            disabled={disabled}
                            options={booleanOptions}
                        />
                    </div>
                    <div className="form-field-row">
                        <ClientContracts clientId={data.id} />
                    </div>
                </div>
                <div className="form-action-buttons">
                    {resetButton}
                    <Button disabled={!isDirty || disabled} onClick={handleSubmit}>
                        Save
                    </Button>
                </div>
            </div>
        </FormContext.Provider>
    );
};
