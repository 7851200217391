import { css } from '@emotion/core';
import React from 'react';

const styles = (labelWidth = 150) => css`
    .row {
        display: flex;
        margin-bottom: 5px;

        .label {
            width: ${labelWidth}px;
            flex: 0 0 auto;
            font-weight: 500;
        }
        .value {
            flex: 1 1 auto;
        }
    }
`;

export const KeyValueInfoList: React.FC<{
    data: Array<{ key: string; value: string | React.ReactNode }>;
    labelWidth?: number;
}> = ({ data, labelWidth }) => {
    const rows = data.map((item) => (
        <div className="row" key={item.key}>
            <div className="label">{item.key}</div>
            <div className="value">{item.value}</div>
        </div>
    ));
    return <div css={styles(labelWidth)}>{rows}</div>;
};
