import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { AttachMoney, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { fullDate, interviewTime } from '../../common/timestamp';
import { InvoicePayment } from '../../graphql/queries/notifications';
import { useModal } from '../../hooks/use-modal';
import { useSession } from '../../hooks/use-session';
import { KeyValueInfoList } from '../key-value-info-list';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }
`;

export const InvoicePaymentNotification: React.FC<{
    invoicePayment: InvoicePayment;
    userId: string;
    createdAt: number;
    onComplete: () => void;
}> = ({ invoicePayment, userId, createdAt, onComplete }) => {
    const theme = useTheme();
    const { user } = useSession();
    const { setAlert } = useModal();

    const handleToggleDetails = () => {
        const memo = [invoicePayment.transaction.externalMemo, invoicePayment.transaction.bankInternalMemo]
            .filter(Boolean)
            .join('\n');
        const infoList = [
            { key: 'Customer', value: invoicePayment.invoice.customer.name },
            { key: 'Invoice', value: invoicePayment.invoice.invoiceNumber },
            { key: 'Amount Recorded', value: `${invoicePayment.amount.toLocaleString()}` },
            { key: 'Total Amount', value: `${invoicePayment.invoice.totalAmount.toLocaleString()}` },
            { key: 'Bank Account', value: invoicePayment.transaction.account.name },
            { key: 'Posted At', value: fullDate(invoicePayment.transaction.postedAt) },
            { key: 'Memo', value: memo }
        ];
        setAlert('Invoice Payment Details', <KeyValueInfoList data={infoList} />);
    };

    const handleMarkComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onComplete();
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = <Typography variant="h6">Invoice Payment Recorded</Typography>;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            ${invoicePayment.amount.toLocaleString()}
            <br />
            {invoicePayment.invoice.customer.name}
            <br />
            {invoicePayment.invoice.invoiceNumber}
            <br />
            {fullDate(invoicePayment.transaction.postedAt)}
        </Typography>
    );

    return (
        <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
            <ListItemAvatar>
                <Avatar>
                    <AttachMoney />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(createdAt)}</span>
                </Typography>
            </div>
            {actions}
        </ListItem>
    );
};
