import React from 'react';
import DropzoneLib, { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';

interface DropZoneProps {
    onDrop: (files: File[]) => void;
    renderElement: JSX.Element;
    /**
     * Maximum number of files that can be dropped at once
     * @default Infinity
     */
    maxFiles?: number;
    /**
     * File types that can be accepted
     * @example { 'image/*': ['.png', '.jpg', '.jpeg'] }
     * @example { 'application/pdf': ['.pdf'] }
     */
    accept?: Record<string, string[]>;
}

export const DropZone: React.FC<DropZoneProps> = ({ onDrop, renderElement, maxFiles = Infinity, accept }) => {
    const getDropzoneSection = (
        getRootProps: <T extends DropzoneRootProps>(props?: T) => T,
        getInputProps: <T extends DropzoneInputProps>(props?: T) => T
    ) => {
        return (
            <section>
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {renderElement}
                </div>
            </section>
        );
    };

    return (
        <DropzoneLib onDrop={onDrop} maxFiles={maxFiles} accept={accept} multiple={maxFiles !== 1}>
            {({ getRootProps, getInputProps }) => getDropzoneSection(getRootProps, getInputProps)}
        </DropzoneLib>
    );
};
