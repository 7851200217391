import { useQuery } from '@apollo/client';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { hasRole } from 'shared/models/user';
import { uploadBankTransactions } from '../api';
import { DropZone } from '../core-ui/drop-zone';

import { SelectField } from '../core-ui/form-fields';
import { BANK_ACCOUNTS } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { readFile } from '../lib/read-file-payload';

export const UploadBankTransactionsButton = () => {
    const { userPermissions } = useSession();
    const canUpload = hasRole(userPermissions, 'bank_accountant');
    const { data } = useQuery<{ bankAccounts: Array<{ id: string; name: string }> }>(BANK_ACCOUNTS, {
        skip: !canUpload
    });
    const [open, setOpen] = useState(false);
    const [selectedBankAccount, setSelectedBankAccount] = useState<string | null>(null);
    const { showLoading, hideLoading, setAlert } = useModal();
    const { setSnackbar } = useSnackbar();

    useEffect(() => {
        if (!selectedBankAccount && data?.bankAccounts.length) {
            setSelectedBankAccount(data.bankAccounts[0].id);
        }
    }, [data, selectedBankAccount]);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const handleBankAccountChange = (id: string) => {
        setSelectedBankAccount(id);
    };

    const handleDrop = async (files: File[]) => {
        setOpen(false);
        showLoading();
        try {
            const content = await readFile(files[0]);
            const result = await uploadBankTransactions(selectedBankAccount, content);
            if (!result.success) {
                throw new Error('Failed to upload file');
            }
            setSnackbar('File uploaded');
        } catch (error) {
            setAlert('Error', 'Failed to process file');
        } finally {
            hideLoading();
        }
    };

    if (!canUpload) {
        return null;
    }

    let dialog;
    if (open) {
        const options = data?.bankAccounts?.map(({ id, name }) => ({
            label: name,
            value: id
        }));

        const acceptFileTypes = {
            'text/csv': ['.csv']
        };

        const dropZone = (
            <DropZone
                onDrop={handleDrop}
                maxFiles={1}
                accept={acceptFileTypes}
                renderElement={<Button>Upload CSV</Button>}
            />
        );

        dialog = (
            <Dialog open={true} onClose={handleClose} fullWidth={true}>
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Upload Bank Transactions
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <SelectField
                        label="Bank Account"
                        options={options}
                        value={selectedBankAccount}
                        onChange={handleBankAccountChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    {dropZone}
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            <Button onClick={handleClick} disabled={!data}>
                Upload Bank Transactions
            </Button>
            {dialog}
        </>
    );
};
