import produce from 'immer';
import { isEqual, orderBy, uniqBy } from 'lodash';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
    BASELINE_SEARCH_SORT_RANK,
    JobSearchesSourcingStats,
    SearchConfig,
    SearchProject,
    SearchResultsViewType
} from 'shared/models/search';

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { ModelParameters } from 'shared/common/llm';
import {
    createSearch,
    deleteSearch,
    fetchCampaignSearchStats,
    fetchJobSearchStats,
    fetchSearchResults,
    fetchSearchStats,
    receiveNewSearch,
    removeUnsavedLocalSearches,
    updateSearch
} from '../actions';
import { getPreviewCount, getSourcer } from '../api';
import { Loading } from '../core-ui/loading';
import {
    CREATE_SEARCH_PROFILE_SCORE,
    JOB_SEARCH_PROFILE_SCORES,
    JobSearchScoring,
    SEARCH_PROFILE_SCORE_VALUES,
    SEARCH_PROFILE_SCORES,
    SEARCH_RESULTS_TO_SCORE_COUNT,
    SearchScore
} from '../graphql/queries/profile-score';
import { JOB_DATA, JobData } from '../graphql/queries/search';
import useConstants from '../hooks/use-constants';
import { useModal } from '../hooks/use-modal';
import { useReduxDispatch, useReduxState } from '../hooks/use-redux';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { Client, Search, SearchResult, SearchResultsState, SearchStatus, SourcerData, State } from '../state';
import { searchDefaults } from './search-defaults';
import { SearchPresetsProvider } from './use-search-presets';
import { SearchSectionsProvider } from './use-search-sections';

interface SearchConfigProps {
    jobId: string;
    searchId?: string;
    project: SearchProject;
    baseline?: boolean;
    resultsType?: SearchResultsViewType;
}

interface SearchContextType {
    resultsType: SearchResultsViewType;
    data: Partial<Search>;
    searchStats: JobSearchesSourcingStats;
    savedData: Partial<Search>;
    readonly: boolean;
    job: JobData;
    sourcer: SourcerData;
    client: Client;
    fetchingResults: boolean;
    creating: boolean;
    updating: boolean;
    estimatedCount: number | null;
    isPreviewOutdated: boolean;
    project: SearchProject;
    preview: { data: Partial<Search>; fetched: boolean };
    searchInitialized: boolean;
    searchResults: SearchResultsState;
    jobSearchScorings: JobSearchScoring[];
    profileScores: SearchScore[];
    resultScores: Map<string, number>;
    getResultsToScoreCount: () => Promise<number>;
    maxSearchResultsToScoreUsingLLM: number;
    getSortedResults: () => SearchResult[];
    onChange: (value: Partial<Search>) => void;
    onEstimateCount: () => void;
    onPreview: (limit: number) => void;
    onFieldChange: <T extends keyof Search>(field: T) => (value: Search[T]) => void;
    onConfigFieldChange: <T extends keyof SearchConfig>(field: T) => (value: SearchConfig[T]) => void;
    onCreateProfileScore: (scoreData: {
        searchId: string;
        systemPrompt?: string;
        userPromptTemplate?: string;
        modelParameters?: ModelParameters;
        companyInformation?: string;
        jobDescription?: string;
        additionalContext?: string;
    }) => Promise<void>;
    onCancel: () => void;
    onSave: () => void;
}

const SearchContext = createContext<SearchContextType | undefined>(undefined);

export const previewEstimateTooManyResultsError = -1;
export const previewEstimateUnknownError = -2;

export const SearchProvider: React.FC<SearchConfigProps> = ({
    children,
    jobId,
    searchId: initialSearchId,
    project,
    baseline,
    resultsType
}) => {
    const isMounted = useRef(true);
    const [searchId, setSearchId] = useState<string>(initialSearchId);
    const [data, setData] = useState<Partial<Search>>(undefined);
    const [savedSearch, setSavedSearch] = useState<Search>(undefined);
    const [defaultSearch, setDefaultSearch] = useState<Partial<Search>>(undefined); // default search data for the job
    const [sourcer, setSourcer] = useState<SourcerData>(null);
    const [redirect, setRedirect] = useState<string | null>(null);
    const [estimatingCount, setEstimatingCount] = useState(false);
    const [estimatedCount, setEstimatedCount] = useState<number | null>(null);
    const [scoringIncompleteDialogShown, setScoringIncompleteDialogShown] = useState<string>(null);
    const [previewState, setPreviewState] = useState<{ data: Partial<Search>; fetched: boolean }>(null);
    const { getConstant } = useConstants();
    const [fetchResultsToScore] = useLazyQuery<{
        resultsCount: Array<{ result: number }>;
    }>(SEARCH_RESULTS_TO_SCORE_COUNT, { variables: { searchId } });
    const defaultSearchProfileScoringsPollIntervalMs = 120000;
    const [searchProfileScoringsPollIntervalMs, setSearchProfileScoringsPollIntervalMs] = useState(0);
    const { data: profileScoresData, refetch: refetchProfileScores } = useQuery<{ scores: SearchScore[] }>(
        SEARCH_PROFILE_SCORES,
        {
            pollInterval: searchProfileScoringsPollIntervalMs,
            skip: !searchId,
            variables: { searchId }
        }
    );
    const { data: jobSearchScoringsData, refetch: refetchJobSearchScorings } = useQuery<{
        jobSearchScorings: JobSearchScoring[];
    }>(JOB_SEARCH_PROFILE_SCORES, { skip: !jobId, variables: { jobId } });
    const { data: resultScoresData } = useQuery<{ scores: Array<{ personId: string; score: number }> }>(
        SEARCH_PROFILE_SCORE_VALUES,
        { skip: !data?.searchProfilesScoreId, variables: { scoreId: data?.searchProfilesScoreId } }
    );
    const { data: jobData, loading: loadingJobData } = useQuery<
        {
            job: JobData;
        },
        { jobId: string }
    >(JOB_DATA, {
        skip: project === SearchProject.HireflowV2,
        variables: { jobId }
    });
    const [createSearchProfileScore] = useMutation<
        { score: { id: string } },
        {
            object: {
                searchId: string;
                systemPrompt: string;
                userPromptTemplate: string;
                modelParameters: ModelParameters;
                variables: Array<{
                    name: string;
                    value: string;
                    uiDefaultExpanded?: boolean;
                }>;
            };
        }
    >(CREATE_SEARCH_PROFILE_SCORE);

    const { user } = useSession();
    const { getConfirmation, setAlert } = useModal();
    const { setSnackbar } = useSnackbar();

    const dispatch = useReduxDispatch();
    const searches = useReduxState((state: State) => state.searches);
    const clients = useReduxState((state: State) => state.clients);
    const pendingRequests = useReduxState((state: State) => state.pendingRequests);
    const searchResults = useReduxState((state: State) => state.searchResults?.get(data?.id));
    const searchStats = useReduxState((state: State) =>
        state.searches?.get(jobId)?.searchStats?.find((s) => s.id === searchId)
    );

    const job = jobData?.job;
    const clientId = job?.clientId;
    const client = clients.list.get(clientId);
    const searchInitialized = data?.id && data?.status !== SearchStatus.Initial;
    const fetchingResults = pendingRequests.has('fetching-search-results');
    const creating = pendingRequests.has(`search-create`);
    const updating = pendingRequests.has(`search-updates-${data?.id}`);
    const isPreviewOutdated = !isEqual(data?.config, previewState?.data?.config);
    const resultScores = new Map(resultScoresData?.scores?.map((s) => [s.personId, s.score]));

    const handleSetRedirect = () =>
        setRedirect(`/${project === SearchProject.Titan ? 'job' : 'sourcer'}/${jobId}/searches`);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        if (searchInitialized && !searchResults) {
            dispatch(fetchSearchResults(data, resType, project === SearchProject.HireflowV2));
        }
    }, [searchInitialized, searchResults]);

    useEffect(() => {
        if (searchId && searches && !savedSearch) {
            setSavedSearch(searches.get(jobId).searches.find((s) => s.id === searchId));
        }
    }, [searchId, searches]);

    useEffect(() => {
        if (project === SearchProject.Titan && job && !job.discipline && data?.status === SearchStatus.Initial) {
            setAlert(
                'Error - unable to create a search',
                'Job discipline is not set in the job settings. Please set the discipline and try again.'
            );
            handleSetRedirect();
        }
    });

    useEffect(() => {
        if (!sourcer && project === SearchProject.HireflowV2) {
            getSourcer(jobId)
                .then((result) => {
                    if (result.success) {
                        if (!isMounted) return;
                        setSourcer(result.data.sourcer);
                    }
                })
                .catch(() => {
                    setAlert('Error', 'Error fetching sourcer config');
                });
        }
        if (!searches || !searches.has(jobId)) {
            if (project === SearchProject.HireflowV2) {
                dispatch(fetchCampaignSearchStats(jobId));
            } else {
                if (searchId) {
                    dispatch(fetchSearchStats(searchId));
                } else {
                    dispatch(fetchJobSearchStats(jobId, user.id));
                }
            }
        }
    }, [searchId]);

    useEffect(() => {
        if (searchId && searches && !data) {
            setData(searches.get(jobId).searches.find((s) => s.id === searchId));
        }
    }, [searches, searchId]);

    useEffect(() => {
        if (
            !searchId &&
            !data &&
            searches &&
            !redirect &&
            ((project === SearchProject.Titan && job && client) || (project === SearchProject.HireflowV2 && sourcer))
        ) {
            const baselineSearchData = baseline ? { name: 'Baseline Search', sortRank: BASELINE_SEARCH_SORT_RANK } : {};
            const defaultData = Object.assign(
                {},
                { jobId, userId: user.id },
                searchDefaults(user, job, client, project),
                baselineSearchData
            );
            const searchesInProgress = searches
                .get(jobId)
                .searches.find((s) => s.status === SearchStatus.Initial && s.createdBy === user.id && !s.deleted);
            setDefaultSearch(defaultData);
            setData(searchesInProgress ?? defaultData);
        }
    }, [searchId, job, sourcer, client, searches, data, redirect]);

    useEffect(() => {
        if (previewState && !previewState?.fetched && !fetchingResults && !isPreviewOutdated) {
            setPreviewState({ ...previewState, fetched: true });
        }
    }, [fetchingResults]);

    useEffect(() => {
        if (profileScoresData?.scores?.find((s) => s.completedAt === null)) {
            setSearchProfileScoringsPollIntervalMs(defaultSearchProfileScoringsPollIntervalMs);
        } else {
            setSearchProfileScoringsPollIntervalMs(0);
        }
    }, [profileScoresData]);

    useEffect(() => {
        if (scoringIncompleteDialogShown !== searchId) {
            if (data?.status === SearchStatus.ScoringProfiles) {
                setAlert(
                    'AI Profile Scoring In Progress',
                    'AI profile scoring is in progress. Please wait for it to complete before sending outreach.'
                );
                setScoringIncompleteDialogShown(searchId);
            } else if (data?.status === SearchStatus.ScoringFailed) {
                setAlert('AI Profile Scoring Failed', 'AI profile scoring failed. Please create a new search.');
                setScoringIncompleteDialogShown(searchId);
            }
        }
    }, [profileScoresData, searchId, data, scoringIncompleteDialogShown]);

    useEffect(() => {
        setScoringIncompleteDialogShown(null);
    }, [searchId]);

    const handleChange = (value: Partial<Search>) => {
        // check allowed changes
        const {
            status: initialStatus,
            maxProfileAgeInDays: initialMaxProfileAgeInDays,
            name: initialName,
            resultsSort: initialResultsSort,
            searchProfilesScoreId: initialSearchProfilesScoreId,
            minSearchProfileScore: initialMinSearchProfileScore,
            ...initialData
        } = data;
        const {
            status: newStatus,
            name: newName,
            maxProfileAgeInDays: newMaxProfileAgeInDays,
            resultsSort: newResultsSort,
            searchProfilesScoreId: newSearchProfilesScoreId,
            minSearchProfileScore: newMinSearchProfileScore,
            ...newData
        } = value;
        if (!isEqual(initialData, newData)) {
            if (searchInitialized) {
                setAlert('Error', 'Search data cannot be changed after it has been initialized');
                return;
            } else {
                setEstimatedCount(null);
            }
        }
        setData(value);
    };

    const handleFieldChange =
        <T extends keyof Search>(field: T) =>
        (value: Search[T]) => {
            if (field === 'name' && baseline) {
                return; // baseline search name is not editable
            }
            handleChange(
                produce(data, (draft) => {
                    draft[field] = value;
                })
            );
        };

    const handleConfigFieldChange =
        <T extends keyof SearchConfig>(field: T) =>
        (value: SearchConfig[T]) => {
            handleFieldChange('config')(
                produce(data.config, (draft) => {
                    draft[field] = value;
                })
            );
        };

    const handleCancel = () => {
        const compareTo = searchInitialized ? savedSearch : defaultSearch;
        const handleCancelConfirmed = () => {
            if (data.id && data.status === SearchStatus.Initial) {
                dispatch(deleteSearch(data));
            } else if (!data.id) {
                dispatch(removeUnsavedLocalSearches(jobId));
            }
            handleSetRedirect();
        };
        if (!isEqual(data, compareTo)) {
            getConfirmation(
                handleCancelConfirmed,
                'Are you sure you wish to close this search? All unsaved changes will be lost',
                'Close Search'
            );
        } else {
            handleCancelConfirmed();
        }
    };

    const handleUpdate = () => {
        const handleUpdateConfirmed = () => {
            dispatch(updateSearch(data.id, data, true));
            setSnackbar('Search updated');
            handleSetRedirect();
        };
        const description =
            savedSearch.status !== data.status ? (
                data.status === SearchStatus.Active ? (
                    <span>
                        Search will become <b>ACTIVE</b> and results from this search will <b>ALLOWED</b> to be added to
                        the Job as candidates
                    </span>
                ) : (
                    <span>
                        Search will be <b>PAUSED</b>, and results will <b>NOT</b> be used for adding candidates to the
                        job
                    </span>
                )
            ) : savedSearch.resultsSort !== data.resultsSort ||
              savedSearch.searchProfilesScoreId !== data.searchProfilesScoreId ? (
                <span>
                    Search results sort order will be changed and new sort order will be used when adding candidates to
                    the job
                </span>
            ) : null;
        const title = savedSearch.status !== data.status ? 'Confirm Search Status Change' : 'Confirm Sort Change';
        if (description) {
            getConfirmation(handleUpdateConfirmed, description, title);
        } else {
            handleUpdateConfirmed();
        }
    };

    const handleSave = () => {
        if (!searchInitialized) {
            dispatch(createSearch(data));
            setSnackbar('Search created');
            handleSetRedirect();
        } else {
            handleUpdate();
        }
    };

    const handleEstimateCount = () => {
        setEstimatingCount(true);
        getPreviewCount(data).then((result) => {
            if (result.data.data.error) {
                setSnackbar(result.data.data.error, undefined, null);
            }
            dispatch(removeUnsavedLocalSearches(jobId));
            if (isMounted.current) {
                dispatch(receiveNewSearch(result.data.search));
                const countPreview = result.success
                    ? result.data.data.results
                    : result.data.data.error?.match(/More than .* Results, Please modify your search/)
                      ? previewEstimateTooManyResultsError
                      : previewEstimateUnknownError;
                setEstimatedCount(countPreview);
                setData({ ...result.data.search, ...data, id: result.data.search.id });
                setSearchId(result.data.search.id);
                setEstimatingCount(false);
            } else {
                // if component is unmounted, delete the search
                dispatch(deleteSearch(result.data.search));
            }
        });
    };

    const handlePreview = (limit: number) => {
        setPreviewState({ data, fetched: false });
        dispatch(fetchSearchResults(data, 'preview', project === SearchProject.HireflowV2, limit));
    };

    const handleCreateProfileScore = async (scoreData: {
        searchId: string;
        systemPrompt: string;
        userPromptTemplate: string;
        modelParameters: ModelParameters;
        variables: Array<{
            name: string;
            value: string;
            uiDefaultExpanded?: boolean;
        }>;
        title: string;
    }) => {
        setSnackbar('Queuing profile scores generation');
        const updatedScoring = await createSearchProfileScore({ variables: { object: scoreData } });
        // update search scoring
        const updatedData = { ...data, searchProfilesScoreId: updatedScoring.data.score.id };
        setData(updatedData);
        setSavedSearch({ ...savedSearch, searchProfilesScoreId: updatedScoring.data.score.id });
        dispatch(updateSearch(data.id, updatedData, true));
        refetchProfileScores();
        refetchJobSearchScorings();
        setSnackbar('Profile scores generation started');
    };

    const getSortedResults = () => {
        const resultsSort = data?.resultsSort || 'similarityScore';
        const results = searchResults?.results ?? [];
        if (data?.searchProfilesScoreId) {
            return orderBy<SearchResult>(
                results,
                [
                    (r) => resultScores?.get(r.personId) ?? 0,
                    (r) => r.scores.similarityScore,
                    (r) => r.scores.skillScore
                ],
                ['desc', 'desc', 'desc']
            );
        } else {
            switch (resultsSort) {
                case 'skillScore':
                    return orderBy<SearchResult>(
                        results,
                        [(r) => r.scores.skillScore, (r) => r.scores.similarityScore],
                        ['desc', 'desc']
                    );
                case 'similarityScore':
                    return orderBy<SearchResult>(
                        results,
                        [(r) => r.scores.similarityScore, (r) => r.scores.skillScore],
                        ['desc', 'desc']
                    );
                case 'totalScore':
                    return orderBy<SearchResult>(
                        results,
                        [(r) => r.scores.totalScore, (r) => r.scores.similarityScore, (r) => r.scores.skillScore],
                        ['desc', 'desc', 'desc']
                    );
                case 'random':
                    return orderBy<SearchResult>(results, [(r) => r.scores.randomScore || 0], ['desc']);
                case 'profileAge':
                    return orderBy<SearchResult>(results, [(r) => r.scores.profileAge || 0], ['desc']);
            }
        }
    };

    const getResultsToScoreCount = async () => {
        const res = await fetchResultsToScore();
        return res.data.resultsCount[0].result;
    };

    const profileScores = profileScoresData?.scores ?? [];

    const loading = (project === SearchProject.Titan ? loadingJobData || !client : !sourcer) || !data;
    const resType: SearchResultsViewType = resultsType ?? 'preview';
    const maxSearchResultsToScoreUsingLLM = getConstant('maxSearchResultsToScoreUsingLLM');

    const jobSearchScorings = uniqBy(
        orderBy(
            jobSearchScoringsData?.jobSearchScorings ?? [],
            ['createdAt', (s) => s.user.id === user.id],
            ['desc', 'desc']
        ),
        (s) =>
            JSON.stringify({
                additionalContext: s.additionalContext,
                companyInformation: s.companyInformation,
                jobDescription: s.jobDescription,
                modelParameters: s.modelParameters,
                systemPrompt: s.systemPrompt,
                title: s.title,
                user: s.user.id,
                userPromptTemplate: s.userPromptTemplate
            })
    );

    const context = {
        client,
        creating,
        data,
        estimatedCount,
        fetchingResults: fetchingResults || estimatingCount,
        getResultsToScoreCount,
        getSortedResults,
        isPreviewOutdated,
        job,
        jobSearchScorings,
        maxSearchResultsToScoreUsingLLM,
        onCancel: handleCancel,
        onChange: handleChange,
        onConfigFieldChange: handleConfigFieldChange,
        onCreateProfileScore: handleCreateProfileScore,
        onEstimateCount: handleEstimateCount,
        onFieldChange: handleFieldChange,
        onPreview: handlePreview,
        onSave: handleSave,
        preview: previewState,
        profileScores,
        project,
        readonly: searchInitialized,
        resultScores,
        resultsType: resType,
        savedData: savedSearch ?? data,
        searchInitialized,
        searchResults,
        searchStats,
        sourcer,
        updating
    };

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <SearchContext.Provider value={context}>
            <SearchSectionsProvider>
                <SearchPresetsProvider>{children}</SearchPresetsProvider>
            </SearchSectionsProvider>
        </SearchContext.Provider>
    );
};

export const useSearch = () => {
    const context = useContext(SearchContext);
    if (!context) {
        throw new Error('useSearch must be used within a SearchProvider');
    }
    return context;
};
