import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { BankTransactions } from '../components/accounting/bank-transactions';
import { Header } from '../components/header';

export const AccountingBankTransactionsPage: React.FC = () => (
    <DocumentTitle title="Bank Transactions">
        <div id="container">
            <Header title="Bank Transactions" />
            <div id="content" className="flex-fill">
                <BankTransactions />
            </div>
        </div>
    </DocumentTitle>
);
