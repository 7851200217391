import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';

import { hasRole } from 'shared/models/user';

import { Autocomplete, DateTextField, SelectField, TextField } from '../core-ui/form-fields';
import { client as apolloClient } from '../graphql/apollo-client';
import { ClientFeeData, CREATE_CLIENT_FEE_DATA, CREATE_FEE } from '../graphql/queries/billing';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';
import { useUsers } from '../hooks/use-users';
import { useFormContextData } from './form-context';

interface AddFeeFormData {
    clientId: string;
    type: 'monthly-rpo';
    amount: number;
    incurredAt: number;
    dueAt: number;
    description: string;
    recruiterId: string;
    accountManagerId: string;
}

const addFeeDialogStyles = css`
    .form-row {
        margin-bottom: 24px;
    }
`;

const AddFeeForm: React.FC<{ clientData: ClientFeeData; open: boolean; onClose: () => void; refetch: () => void }> = ({
    clientData,
    open,
    onClose,
    refetch
}) => {
    const { users } = useUsers([clientData.accountManager.id]);
    const { setAlert, showLoading, hideLoading } = useModal();
    const [createFee] = useMutation(CREATE_FEE, { client: apolloClient('account_manager') });
    const { setSnackbar } = useSnackbar();

    const { data, onFieldChange, getError, setError, validateFieldExists } = useFormContextData<AddFeeFormData>({
        accountManagerId: clientData.accountManager.id,
        amount: 0,
        clientId: clientData.id,
        description: '',
        dueAt: Date.now(),
        incurredAt: Date.now(),
        recruiterId: '',
        type: 'monthly-rpo'
    });

    const feeTypes = [{ value: 'monthly-rpo', label: 'Monthly RPO Fee' }];
    const userOptions =
        users?.map((user) => ({
            label: user.name,
            value: user.id
        })) ?? [];

    const validateAmount = (value: number) => {
        if (value <= 0) {
            setError('amount', 'Amount must be greater than 0');
            return false;
        }
        setError('amount', undefined);
        return true;
    };

    const validateData = () => {
        let valid = true;
        valid = validateFieldExists('accountManagerId')(data.accountManagerId) && valid;
        valid = validateFieldExists('recruiterId')(data.recruiterId) && valid;
        valid = validateFieldExists('incurredAt')(data.incurredAt) && valid;
        valid = validateFieldExists('dueAt')(data.dueAt) && valid;
        valid = validateAmount(data.amount) && valid;
        return valid;
    };

    const handleSave = async () => {
        if (validateData()) {
            showLoading();
            try {
                await createFee({ variables: { data } });
                setSnackbar('Fee saved');
                refetch();
                onClose();
            } catch {
                setAlert('Error', 'Failed to save fee');
            } finally {
                hideLoading();
            }
        } else {
            setAlert('Error', 'Please fill out all required fields');
        }
    };

    return (
        <Dialog open={open} maxWidth="sm" fullWidth={true} css={addFeeDialogStyles}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Add Fee
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div className="form-row">
                    <SelectField label="Type" value={data.type} onChange={onFieldChange('type')} options={feeTypes} />
                </div>
                <div className="form-row">
                    <TextField
                        label="Amount"
                        value={data.amount}
                        onChange={onFieldChange('amount')}
                        validate={validateAmount}
                        errorText={getError('amount')}
                    />
                </div>
                <div className="form-row">
                    <DateTextField
                        label="Incurred At"
                        value={data.incurredAt}
                        onChange={onFieldChange('incurredAt')}
                        validate={validateFieldExists('incurredAt')}
                        errorText={getError('incurredAt')}
                    />
                </div>
                <div className="form-row">
                    <DateTextField
                        label="Due At"
                        value={data.dueAt}
                        onChange={onFieldChange('dueAt')}
                        validate={validateFieldExists('dueAt')}
                        errorText={getError('dueAt')}
                    />
                </div>
                <div className="form-row">
                    <TextField
                        label="Description"
                        value={data.description}
                        onChange={onFieldChange('description')}
                        errorText={getError('description')}
                    />
                </div>
                <div className="form-row">
                    <Autocomplete
                        label="Account Manager"
                        options={userOptions}
                        value={data.accountManagerId}
                        onChange={onFieldChange('accountManagerId')}
                        validate={validateFieldExists('accountManagerId')}
                        errorText={getError('accountManagerId')}
                    />
                </div>
                <div className="form-row">
                    <Autocomplete
                        label="Recruiter"
                        options={userOptions}
                        value={data.recruiterId}
                        onChange={onFieldChange('recruiterId')}
                        validate={validateFieldExists('recruiterId')}
                        errorText={getError('recruiterId')}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave}>Save</Button>
            </DialogActions>
        </Dialog>
    );
};

export const AddClientFee: React.FC<{ clientId: string; refetch: () => void }> = ({ clientId, refetch }) => {
    const { userPermissions } = useSession();
    const canEdit = hasRole(userPermissions, 'billing_admin') || hasRole(userPermissions, 'account_manager');
    const { data: clientData } = useQuery<{ client: ClientFeeData }, { clientId: string }>(CREATE_CLIENT_FEE_DATA, {
        skip: !canEdit,
        variables: { clientId }
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const { getConfirmation } = useModal();

    const handleAddFeeClick = () => {
        getConfirmation(
            () => {
                setDialogOpen(true);
            },
            <div>
                Only create fees here that do not have a Titan Job or Candidate associated with them.
                <ul>
                    <li>For placement fees - create from Candidate card</li>
                    <li>For Job fees, create from job settings page</li>
                </ul>
            </div>,
            'Create Client Fee'
        );
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    if (!canEdit || !clientData) {
        return null;
    }

    return (
        <>
            <Button onClick={handleAddFeeClick}>Add Fee</Button>
            <AddFeeForm
                clientData={clientData?.client}
                open={dialogOpen}
                onClose={handleCloseDialog}
                refetch={refetch}
            />
        </>
    );
};
