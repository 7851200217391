import * as React from 'react';
import DocumentTitle from 'react-document-title';

import { Commissions } from '../components/accounting/commissions';
import { Header } from '../components/header';

export const AccountingCommissionsPage: React.FC = () => (
    <DocumentTitle title="Accounting - Commissions">
        <div id="container">
            <Header title="Accounting - Commissions" />
            <div id="content" className="flex-fill">
                <Commissions accounting={true} />
            </div>
        </div>
    </DocumentTitle>
);
