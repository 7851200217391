import { useMutation, useQuery } from '@apollo/client';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';

import { GET_CANDIDATE_AUTOGENERATE_FEE_SETTING, UPDATE_CANDIDATE } from '../graphql/queries/candidates';

export const CandidateMonthlyFeeAutoGenerateSetting: React.FC<{
    disabled: boolean;
    personId: string;
    jobId: string;
}> = ({ disabled, personId, jobId }) => {
    const { data, refetch } = useQuery<
        { candidate: { autoGenerateFeeDisabled: boolean } },
        { personId: string; jobId: string }
    >(GET_CANDIDATE_AUTOGENERATE_FEE_SETTING, {
        variables: { personId, jobId }
    });
    const [updateCandidate, { loading }] = useMutation<
        {},
        { personId: string; jobId: string; updates: { autoGenerateFeeDisabled: boolean } }
    >(UPDATE_CANDIDATE);

    const handleChange = async () => {
        const autoGenerateFeeDisabled = !data?.candidate?.autoGenerateFeeDisabled;
        await updateCandidate({
            variables: { personId, jobId, updates: { autoGenerateFeeDisabled } }
        });
        await refetch();
    };

    const checkBox = !data ? null : (
        <Checkbox
            checked={!data.candidate.autoGenerateFeeDisabled}
            onChange={handleChange}
            name="autoGenerateFeeDisabled"
            color="primary"
            disabled={disabled || loading}
        />
    );

    return !data ? null : <FormControlLabel control={checkBox} label="Auto generate fees each month" />;
};
