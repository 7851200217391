import { gql } from '@apollo/client';

import { ClientData } from 'shared/models/client';
import { InvoiceStatus } from 'shared/models/invoice';
import { JDUpdateContext } from 'shared/models/jd-llm-updates';
import { JobData } from 'shared/models/job';
import { JobDescriptionData } from 'shared/models/job-description';
import { Fee, feeFragment, invoiceFragment } from './billing';
import { Commission, CommissionFragment } from './commissions';

export interface JDLLMUpdate {
    input: string;
    resultsFile: string;
    episodeId: string;
    inferenceId: string;
    updatedAt: number;
    context: JDUpdateContext;
    updates: {
        client?: { old: ClientData; new: ClientData };
        job?: { old: JobData; new: JobData };
        jobDescription?: { old: JobDescriptionData; new: JobDescriptionData };
    };
    job: {
        id: string;
        title: string;
        client: { id: string; name: string };
    };
}

export interface InvoicePayment {
    id: string;
    amount: number;
    invoice: {
        id: string;
        invoiceNumber: string;
        totalAmount: number;
        invoiceDate: number;
        customer: {
            id: string;
            name: string;
        };
    };
    transaction: {
        id: string;
        amount: number;
        externalMemo: string;
        bankInternalMemo: string;
        postedAt: number;
        account: {
            id: string;
            name: string;
        };
    };
}

export interface BankTransaction {
    id: string;
    amount: number;
    externalMemo: string;
    bankInternalMemo: string;
    postedAt: number;
    account: {
        id: string;
        name: string;
    };
    invoices: Array<{
        id: string;
        amount: number;
        invoice: {
            id: string;
            invoiceNumber: string;
            totalAmount: number;
            invoiceDate: number;
            customer: {
                id: string;
                name: string;
            };
        };
    }>;
}

export interface Invoice {
    id: string;
    invoiceNumber: string;
    totalAmount: number;
    invoiceDate: number;
    status: InvoiceStatus;
    pdf: string;
    customer: {
        id: string;
        name: string;
    };
}

type TypedNotification =
    | {
          type: 'JDLLMUpdate';
          jdLLMUpdate: JDLLMUpdate[];
      }
    | {
          type: 'FeeCreated';
          fee: Fee[];
      }
    | {
          type: 'InvoicePaymentRecorded';
          invoicePayment: InvoicePayment[];
      }
    | {
          type: 'BankTransactionNotification';
          bankTransaction: BankTransaction[];
      }
    | {
          type: 'InvoiceStatusChange';
          data: {
              invoiceId: string;
              oldStatus: InvoiceStatus;
              newStatus: InvoiceStatus;
          };
          invoice: Invoice[];
      }
    | {
          type: 'CommissionProcessed';
          data: {
              processedAt: number;
          };
          commissions: Commission[];
      }
    | {
          type: never;
      };

export type Notification = { id: string; userId: string; createdAt: number } & TypedNotification;

export const USER_NOTIFICATIONS = gql`
    ${feeFragment}
    ${invoiceFragment}
    ${CommissionFragment}
    query UserNotifications($userId: String!) {
        notifications(
            where: { userId: { _eq: $userId }, completedAt: { _is_null: true } }
            order_by: { createdAt: desc }
        ) {
            id
            type
            userId
            createdAt
            data
            jdLLMUpdate {
                input
                resultsFile
                episodeId
                inferenceId
                updatedAt
                updates
                context
                job {
                    id
                    title
                    client {
                        id
                        name
                    }
                }
            }
            fee {
                ...FeeFragment
                invoices {
                    ...InvoiceFragment
                }
            }
            invoicePayment {
                id
                amount
                invoice {
                    id
                    invoiceNumber
                    totalAmount
                    invoiceDate
                    customer {
                        id
                        name
                    }
                }
                transaction {
                    id
                    amount
                    externalMemo
                    bankInternalMemo
                    postedAt
                    account {
                        id
                        name
                    }
                }
            }
            bankTransaction {
                id
                amount
                externalMemo
                bankInternalMemo
                postedAt
                account {
                    id
                    name
                }
                invoices {
                    id
                    amount
                    invoice {
                        id
                        invoiceNumber
                        totalAmount
                        invoiceDate
                        customer {
                            id
                            name
                        }
                    }
                }
            }
            invoice {
                id
                totalAmount
                status
                invoiceDate
                invoiceNumber
                pdf
                customer {
                    id
                    name
                }
            }
            commissions {
                ...CommissionFragment
            }
        }
    }
`;

export const UPDATE_NOTIFICATION = gql`
    mutation UpdateNotification($id: String!, $updates: notifications_set_input!) {
        update_notifications_by_pk(pk_columns: { id: $id }, _set: $updates) {
            id
        }
    }
`;
