import { useMutation, useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { Add, AttachMoney } from '@material-ui/icons';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { feeStatus } from '../common/billing';
import { client as apolloClient } from '../graphql/apollo-client';
import {
    APPLY_PREPAYMENT_FEE,
    BaseFee,
    CLIENT_FEES,
    CLIENT_INVOICE_DATA,
    ClientInvoiceData,
    Fee,
    Invoice
} from '../graphql/queries/billing';
import { FeesProvider, useFees } from '../hooks/use-fees';
import { useModal } from '../hooks/use-modal';
import { useSession } from '../hooks/use-session';
import { CreateInvoiceForm } from './create-invoice-form';
import { FeesList } from './fees-list';

const prepaidDialogStyles = css`
    .MuiDialogContent-root {
        padding: 0;
    }

    .MuiListItem-root {
        padding-left: 24px;
    }
`;

export const CreateInvoiceButton: React.FC<{
    fee?: BaseFee & { invoices?: Invoice[] };
    clientId: string;
}> = ({ fee, clientId }) => {
    const { userPermissions } = useSession();
    const [open, setOpen] = React.useState(false);
    const [applyPrepaidFeesOpen, setApplyPrepaidFeesOpen] = React.useState(false);
    const {
        data: feesData,
        loading: feesLoading,
        refetch
    } = useQuery<{ fees: Fee[] }, { clientId: string }>(CLIENT_FEES, {
        variables: { clientId }
    });
    const { data: clientData, loading: clientLoading } = useQuery<{ client: ClientInvoiceData }, { id: string }>(
        CLIENT_INVOICE_DATA,
        {
            variables: { id: clientId }
        }
    );
    const { refreshFees } = useFees();
    const [appliedFeeIds, setAppliedFeeIds] = React.useState<string[]>([]);
    const { getConfirmation, showLoading, hideLoading, setAlert } = useModal();
    const [applyPrepaymentFee] = useMutation<{}, { finalFeeId: string; prepaymentFeeId: string }>(
        APPLY_PREPAYMENT_FEE,
        { client: apolloClient('billing_admin') }
    );

    const loading = feesLoading || clientLoading;

    const availablePrepaidFees = feesData?.fees.filter((f) => {
        const status = feeStatus(f);
        return (
            status === 'paid_in_full' &&
            f.appliedToFeeId === null &&
            f.type === 'prepayment' &&
            !appliedFeeIds.includes(f.id)
        );
    });

    React.useEffect(() => {
        if (availablePrepaidFees?.length === 0) {
            setApplyPrepaidFeesOpen(false);
        }
    }, [availablePrepaidFees, applyPrepaidFeesOpen]);

    if (!hasRole(userPermissions, 'billing_admin')) {
        return null;
    }

    const handleCreateInvoice = () => {
        setOpen(true);
    };

    const handleClose = (created: boolean) => {
        setOpen(false);
        if (created) {
            refetch();
            refreshFees();
        }
    };

    const handleApplyPrepaidFees = () => {
        setApplyPrepaidFeesOpen(true);
    };

    const handleCloseApplyPrepaidFees = () => {
        setApplyPrepaidFeesOpen(false);
    };

    const handleSelectPrepaidFee = (id: string) => {
        getConfirmation(
            async () => {
                showLoading();
                try {
                    await applyPrepaymentFee({ variables: { finalFeeId: fee.id, prepaymentFeeId: id } });
                    setAppliedFeeIds([...appliedFeeIds, id]);
                    refetch();
                    refreshFees();
                } catch {
                    setAppliedFeeIds(appliedFeeIds.filter((i) => i !== id));
                    setAlert('Error', 'Error applying prepayment to fee');
                } finally {
                    hideLoading();
                }
            },
            'Are you sure you want to apply this prepayment towards this fee?',
            'Apply Prepaid Fee'
        );
    };

    const button =
        fee && loading ? null : (
            <Button variant="outlined" startIcon={<Add />} onClick={handleCreateInvoice} disabled={loading}>
                Create Invoice
            </Button>
        );

    const pendingFees = feesData?.fees
        .filter((f) => {
            const status = feeStatus(f);
            return (
                status === 'invoice_not_found' || status === 'invoice_under_amount' || status === 'invoice_not_due_yet'
            );
        })
        .sort((f1, f2) => f1.incurredAt - f2.incurredAt);

    const dialog = open ? (
        <CreateInvoiceForm open={open} onClose={handleClose} client={clientData?.client} fees={pendingFees} />
    ) : null;

    const selectedFeeStatus = fee ? feeStatus(fee) : null;
    if (
        !fee?.id ||
        (selectedFeeStatus &&
            selectedFeeStatus !== 'invoice_not_found' &&
            selectedFeeStatus !== 'invoice_under_amount' &&
            selectedFeeStatus !== 'invoice_not_due_yet')
    ) {
        return null;
    }

    let applyPrepaidButton = null;
    if (fee?.type === 'placement' && availablePrepaidFees && availablePrepaidFees.length > 0) {
        applyPrepaidButton = (
            <Button variant="outlined" startIcon={<AttachMoney />} onClick={handleApplyPrepaidFees}>
                Apply Prepaid Fees
            </Button>
        );
    }

    let applyPrepaidDialog = null;
    if (applyPrepaidFeesOpen) {
        applyPrepaidDialog = (
            <Dialog
                css={prepaidDialogStyles}
                open={true}
                onClose={handleCloseApplyPrepaidFees}
                maxWidth="md"
                fullWidth={true}
            >
                <DialogTitle>
                    <Typography variant="h4" component="div">
                        Apply Prepaid Fees
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <FeesProvider data={availablePrepaidFees} disabled={true} onSelectFee={handleSelectPrepaidFee}>
                        <FeesList />
                    </FeesProvider>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseApplyPrepaidFees}>Close</Button>
                </DialogActions>
            </Dialog>
        );
    }

    return (
        <>
            {button}
            {dialog}
            {applyPrepaidButton}
            {applyPrepaidDialog}
        </>
    );
};
