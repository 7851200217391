import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { Theme, useTheme } from '@material-ui/core';
import React from 'react';
import DocumentTitle from 'react-document-title';
import { Link } from 'react-router-dom';
import { hasRole } from 'shared/models/user';

import { CLIENT_FEES, CLIENT_INVOICES, Fee, Invoice } from '../graphql/queries/billing';
import { BillingDataProvider } from '../hooks/use-billing-data';
import { FeesProvider } from '../hooks/use-fees';
import { useSession } from '../hooks/use-session';
import { AddClientFee } from './add-client-fee';
import { ClientPageHeader } from './client-page-header';
import { FeesList } from './fees-list';
import { InvoicesList } from './invoices-list';

const styles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 auto;
    overflow-y: auto;
    padding: 20px 0 36px;

    .notes-tabs {
        width: 960px;
        justify-content: flex-end;

        a {
            color: ${theme.palette.text.secondary};
            font-weight: 500;
        }

        .active {
            a {
                color: ${theme.palette.text.primary};
                font-weight: 600;
            }
        }
    }

    .list-container {
        width: 960px;
        border-radius: ${theme.shape.borderRadius}px;
        border: thin solid ${theme.palette.divider};
        background: white;
    }

    .add-fee-button {
        margin-top: 20px;
        display: flex;
        justify-content: flex-end;
        width: 960px;
    }
`;

export const ClientInvoicesFees: React.FC<{ clientId: string; activeTab: 'invoices' | 'fees' }> = ({
    clientId,
    activeTab
}) => {
    const theme = useTheme();
    const { userPermissions } = useSession();
    const { data: feesData, refetch: refetchFees } = useQuery<{ fees: Fee[] }, { clientId: string }>(CLIENT_FEES, {
        skip: activeTab !== 'fees',
        variables: { clientId }
    });
    const { data: invoicesData, refetch: refetchInvoices } = useQuery<{ invoices: Invoice[] }, { clientId: string }>(
        CLIENT_INVOICES,
        {
            skip: activeTab !== 'invoices',
            variables: { clientId }
        }
    );

    const list =
        activeTab === 'invoices' ? (
            <InvoicesList invoices={invoicesData?.invoices} />
        ) : (
            <FeesProvider
                data={feesData?.fees}
                refetch={refetchFees}
                disabled={!hasRole(userPermissions, 'billing_admin')}
            >
                <FeesList />
            </FeesProvider>
        );

    const tabs = (
        <div className="notes-tabs">
            <div className={`notes-tab ${activeTab === 'invoices' ? 'active' : ''}`}>
                <Link to={`/client/${clientId}/invoices`}>Invoices</Link>
            </div>
            <div className={`notes-tab ${activeTab === 'fees' ? 'active' : ''}`}>
                <Link to={`/client/${clientId}/fees`}>Fees</Link>
            </div>
        </div>
    );

    const refetch = activeTab === 'invoices' ? refetchInvoices : refetchFees;
    const addFee =
        activeTab === 'fees' ? (
            <div className="add-fee-button">
                <AddClientFee clientId={clientId} refetch={refetchFees} />
            </div>
        ) : null;

    return (
        <DocumentTitle title={activeTab === 'invoices' ? 'Invoices' : 'Fees'}>
            <div id="container">
                <ClientPageHeader clientId={clientId} actions={[]} activeTab="Invoices" />
                <div id="content" className="flex-fill">
                    <div css={styles(theme)}>
                        {tabs}
                        <BillingDataProvider refetch={refetch}>
                            <div className="list-container">{list}</div>
                        </BillingDataProvider>
                        {addFee}
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );
};
