import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import React from 'react';

import { Editor } from 'react-ce';
import { SampleOutreachData } from 'shared/models/sequence';

import { hasRole } from 'shared/models/user';
import { DurationProgress } from '../common/duration-progress';
import { usePromptInfo } from '../hooks/use-prompt-info';
import { useSession } from '../hooks/use-session';
import { PersonProfile } from '../sfc/person-profile';

const styles = (theme: Theme) => css`
    .MuiDialogContent-root {
        overflow-y: hidden;
        display: flex;
        flex-direction: column;
    }

    .MuiDialogActions-root {
        display: flex;
        justify-content: space-between;
        padding: 8px 18px;
    }

    .preview-outreach-content {
        flex: 1 1 auto;
        display: flex;
        min-width: 1024px;
        overflow-y: hidden;
    }

    div::-webkit-scrollbar {
        border-radius: 2px;
        width: 6px;
    }

    .sample-profile {
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        min-width: 580px;
        max-width: 640px;

        padding-right: 20px;
        border-right: thin solid ${theme.palette.divider};
        margin-right: 20px;

        .sample-profile-name {
            padding: 15px 20px;
            border: thin solid ${theme.palette.divider};
            border-radius: 2px;
            margin-bottom: 20px;
        }
    }

    .sample-outreach {
        max-width: 720px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding-right: 15px;
    }

    .stage-error {
        color: ${theme.palette.error.main};
        text-align: center;
        min-width: 480px;
        padding: 20px;
    }

    .stage {
        margin-bottom: 40px;
        padding-bottom: 40px;
        border-bottom: thin solid ${theme.palette.divider};

        &:last-child {
            border-bottom: none;
        }

        .text-editor-container .text-editor-content.disabled {
            cursor: default;
            opacity: 1;
        }

        .stage-subject {
            .MuiInputBase-input {
                font-weight: 600;
            }
        }
    }
`;

const estimatedCompletionTime = 25;

export const PreviewLLMOutreachDialog: React.FC<{
    data: SampleOutreachData;
    onClose: () => void;
    onTryAnother?: () => void;
    open: boolean;
}> = ({ data, open, onClose, onTryAnother }) => {
    const theme = useTheme();
    const { showPromptInfo } = usePromptInfo();
    const { userPermissions } = useSession();

    const handleShowPrompts = () => {
        const { requestParameters, systemPrompt, userPrompt } = data;
        showPromptInfo({
            promptInfo: {
                requestParameters,
                systemPrompt,
                userPrompt
            }
        });
    };

    let content;
    if (!data) {
        content = (
            <div style={{ minWidth: '730px' }}>
                <DurationProgress duration={estimatedCompletionTime} />
            </div>
        );
    } else {
        const { profile, stages } = data;
        if (profile && stages) {
            const stagesContent = stages.map((stage, i) => (
                <div key={i} className="stage">
                    <TextField
                        value={stage.subject}
                        fullWidth={true}
                        className="stage-subject"
                        InputProps={{ readOnly: true, disableUnderline: true }}
                    />
                    <Editor value={stage.body} className="sequence-stage-body" disabled={true} />
                </div>
            ));
            content = (
                <div className="preview-outreach-content">
                    <div className="sample-profile">
                        <div className="sample-profile-name">
                            <Typography variant="h4" component="div">
                                {profile.fullName}
                            </Typography>
                        </div>
                        <PersonProfile personId={profile?.personId} profile={profile} />
                    </div>
                    <div className="sample-outreach">{stagesContent}</div>
                </div>
            );
        } else {
            content = <div className="stage-error">Error creating AI generated emails</div>;
        }
    }

    const viewPromptsButton =
        data && hasRole(userPermissions, 'prompts_editor') ? (
            <Button onClick={handleShowPrompts}>View Prompts</Button>
        ) : null;
    const tryAnotherButton = onTryAnother && data?.stages ? <Button onClick={onTryAnother}>Try Another</Button> : null;

    return (
        <Dialog open={open} disableEnforceFocus={true} maxWidth="xl" css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Previewing AI Generated Outreach
                </Typography>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                <div>
                    {viewPromptsButton}
                    {tryAnotherButton}
                </div>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};
