import { css } from '@emotion/core';
import { Button, Dialog, IconButton, Menu, MenuItem, Theme, useTheme } from '@material-ui/core';
import { Archive, Edit, Unarchive, Visibility } from '@material-ui/icons';
import { groupBy } from 'lodash';
import moment from 'moment';
import React from 'react';

import {
    defaultOutreachSequence,
    defaultPhonescreenSequence,
    isRevivalSequence,
    SequenceType,
    SequenceView
} from 'shared/models/sequence';

import { JobData } from '../graphql/queries/search';
import { Sequence } from '../graphql/queries/sequences';
import { useModal } from '../hooks/use-modal';
import { SequenceForm } from './sequence-form';

// tslint:disable: no-magic-numbers
const styles = (theme: Theme) => css`
    margin-bottom: 32px;

    .sequence-form-content {
        flex: 1 1 auto;
    }

    .sequence-actions {
        display: flex;
        justify-content: flex-end;
        margin-top: 25px;
    }

    .sequences-panel {
        .sequence-groups {
            flex: 1 1 auto;
        }

        .job-sequence-group {
            border-bottom: thin solid ${theme.palette.divider};
            margin-bottom: 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .job-sequence-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            line-height: 1.5rem;

            .sequence-title-container {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;

                .sequence-title {
                    margin-right: 3px;
                }

                .sequence-creation-time {
                    margin-right: 3px;
                }

                &.archived {
                    opacity: 0.6;
                }
            }

            .sequence-action-buttons {
                display: flex;
            }

            .sequence-action-button {
                opacity: 0;
                transition: all 0.2s ease;
            }

            &:hover {
                background: ${theme.palette.grey[100]};
                .sequence-action-button {
                    opacity: 1;
                }
            }
        }

        label {
            line-height: 20px;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.3);
        }
    }
`;
// tslint:enable: no-magic-numbers

interface JobSequencesFormProps {
    job: JobData;
    role: string;
    sequences: Sequence[];
    isEditable: boolean;
    onUpdate: (sequence: Sequence) => void;
    onCreate: (sequence: Sequence) => void;
    onArchiveUpdate: (sequenceId: string, archive: boolean) => void;
    onGenerateOutreachTemplate: () => Promise<void>;
}

const sequenceTypeLabel = {
    [SequenceType.Outreach]: 'Initial Outreach',
    [SequenceType.Phonescreen]: 'Phonescreen'
};

interface ListItemProps<T extends { id: string; createdAt: number }> {
    itemName: string;
    onArchiveUpdate: (id: string, archive: boolean) => void;
    onEdit: (item: T) => void;
    item: T;
    isEditable: boolean;
    getTitle: (item: T) => string;
    getArchivedStatus: (item: T) => boolean;
}

const ListItem = <T extends { id: string; createdAt: number }>({
    itemName,
    onArchiveUpdate,
    onEdit,
    item,
    isEditable,
    getTitle,
    getArchivedStatus
}: ListItemProps<T>) => {
    const { getConfirmation } = useModal();

    const handleArchiveUpdate = (id: string, archive: boolean) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        const confirmationText = archive
            ? `This will archive the ${itemName}, and it will no longer be available.`
            : `This will unarchive the ${itemName}, and it will be available again.`;
        getConfirmation(
            () => {
                onArchiveUpdate(id, archive);
            },
            confirmationText,
            'Please confirm'
        );
    };

    const handleEdit = (updated: T) => () => {
        onEdit(updated);
    };

    const archived = getArchivedStatus(item);
    const archiveButton = archived ? (
        <div className="sequence-action-button">
            <IconButton size="small" onClick={handleArchiveUpdate(item.id, false)}>
                <Unarchive fontSize="small" />
            </IconButton>
        </div>
    ) : (
        <div className="sequence-action-button">
            <IconButton size="small" onClick={handleArchiveUpdate(item.id, true)}>
                <Archive fontSize="small" />
            </IconButton>
        </div>
    );

    const editViewIcon = archived || !isEditable ? <Visibility fontSize="small" /> : <Edit fontSize="small" />;
    const sideIcon = archived ? <Archive fontSize="inherit" /> : null;
    const creationDateString = archived ? (
        <span className="sequence-creation-time">{moment(item.createdAt).format('(MM/DD/YYYY)')}</span>
    ) : null;

    return (
        <div className="job-sequence-item" key={item.id} onClick={handleEdit(item)}>
            <div className={`${archived ? 'archived' : ''} sequence-title-container`}>
                <span className="sequence-title">{getTitle(item)}</span>
                {creationDateString}
                {sideIcon}
            </div>
            <div className="sequence-action-buttons">
                <div className="sequence-action-button">
                    <IconButton size="small">{editViewIcon}</IconButton>
                </div>
                {isEditable ? archiveButton : null}
            </div>
        </div>
    );
};

export const JobSequencesForm: React.FC<JobSequencesFormProps> = ({
    role,
    sequences,
    isEditable,
    onUpdate,
    onCreate,
    onArchiveUpdate,
    onGenerateOutreachTemplate
}) => {
    const theme = useTheme();
    const [editingSequence, setEditingSequence] = React.useState<SequenceView>(null);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    const sequenceWithRole = (seq: SequenceView) => {
        const stages = seq.stages.map((s) => ({
            ...s,
            body: s.body.replace(/{{ROLE}}/gi, role),
            subject: s.subject.replace(/{{ROLE}}/gi, role)
        }));
        return { ...seq, stages };
    };

    const handleAddSequenceSelect = (type: SequenceType) => () => {
        if (type === SequenceType.Outreach) {
            setEditingSequence(sequenceWithRole(defaultOutreachSequence));
        } else if (type === SequenceType.Phonescreen) {
            setEditingSequence(sequenceWithRole(defaultPhonescreenSequence));
        }
        setAnchorEl(null);
    };

    const handleCancelAddSequence = () => {
        setEditingSequence(null);
    };

    const handleSave = (newSequence: SequenceView) => {
        if (newSequence.id) {
            onUpdate(newSequence);
        } else {
            onCreate(newSequence);
        }
        setEditingSequence(null);
    };

    const handleEdit = (sequence: SequenceView) => {
        setEditingSequence(sequence);
    };

    const handleGenerateOutreachSequence = async () => {
        setAnchorEl(null);
        onGenerateOutreachTemplate();
    };

    const getSequenceTitle = (sequence: Sequence) => sequence.title;
    const getSequenceArchivedStatus = (sequence: Sequence) => sequence.archived;

    const sequenceGroups = groupBy(sequences, 'type');
    const list: JSX.Element[] = [];
    for (const t of [SequenceType.Outreach, SequenceType.Phonescreen]) {
        if (sequenceGroups[t]) {
            const sortedSequences = [...sequenceGroups[t]].sort((a, b) => Number(a.archived) - Number(b.archived));
            const jobSequences = sortedSequences.map((sequence) => (
                <ListItem
                    key={sequence.id}
                    itemName="Sequence"
                    item={sequence}
                    isEditable={isEditable}
                    onArchiveUpdate={onArchiveUpdate}
                    onEdit={handleEdit}
                    getTitle={getSequenceTitle}
                    getArchivedStatus={getSequenceArchivedStatus}
                />
            ));
            list.push(
                <div className="job-sequence-group" key={t}>
                    <label>{sequenceTypeLabel[t]}</label>
                    {jobSequences}
                </div>
            );
        }
    }

    const sequenceForm = editingSequence ? (
        <Dialog open={true} maxWidth="md" fullWidth={true} disableEnforceFocus={true}>
            <SequenceForm
                title={editingSequence.id ? 'Edit Sequence' : 'Create Sequence'}
                sequence={editingSequence}
                disabled={!isEditable}
                onCancel={handleCancelAddSequence}
                onSave={handleSave}
            />
        </Dialog>
    ) : null;

    const outreachCreateMenuItem = sequences.some(
        (s) => !s.archived && s.type === SequenceType.Outreach && !isRevivalSequence(s)
    ) ? (
        <MenuItem onClick={handleAddSequenceSelect(SequenceType.Outreach)}>Add Initial Outreach</MenuItem>
    ) : (
        <MenuItem onClick={handleGenerateOutreachSequence}>Generate Initial Outreach</MenuItem>
    );

    const sequenceActions = isEditable ? (
        <div className="sequence-actions">
            <Button onClick={handleOpenMenu}>Add Sequence</Button>
            <Menu anchorEl={anchorEl} keepMounted={true} open={!!anchorEl} onClose={handleCloseMenu}>
                {outreachCreateMenuItem}
                <MenuItem onClick={handleAddSequenceSelect(SequenceType.Phonescreen)}>Add Phone call</MenuItem>
            </Menu>
        </div>
    ) : null;

    return (
        <div css={styles(theme)}>
            <div className="sequences-panel">
                <div className="sequence-form-content">
                    <div className="sequence-groups">{list}</div>
                    {sequenceActions}
                </div>
            </div>
            {sequenceForm}
        </div>
    );
};
