import { css } from '@emotion/core';
import { Dialog } from '@material-ui/core';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { NoteKind } from 'shared/models/note';

import { fetchPersonDetails as fetchPersonDetailsAction } from '../actions';
import { replaceHistory } from '../common/history-replace';
import { ProfileNavButtons } from '../components/profile-nav-buttons';
import { CandidateDetails } from '../containers/candidate-details';
import { PersonProfile } from '../containers/person';
import { List, PersonDetails, State } from '../state';

const fadeInTimeout = 500;

const styles = css`
    .profile-dialog-enter {
        opacity: 0.5;
    }

    .profile-dialog-enter-active {
        opacity: 1;
        transition: opacity ${fadeInTimeout}ms;
    }
`;

interface CandidateKey {
    personId: string;
    jobId?: string;
    selectedTab?: string;
    jobNotesOnly?: boolean;
    notesKind?: NoteKind;
}

interface CandidatesSlidesProps<T extends { personId: string } & Partial<CandidateKey>> {
    candidates: T[];
    initialActive: T;
    onClose: () => void;
    onNavigate?: (index: number) => void;
}

interface ConnectedProps {
    persons: List<PersonDetails>;
}
interface ConnectedDispatch {
    fetchPersonDetails: (id: string, jobId?: string) => void;
}

function CandidatesSlidesComponent<T extends { personId: string } & Partial<CandidateKey>>({
    candidates,
    initialActive,
    onClose,
    onNavigate,
    fetchPersonDetails,
    persons
}: CandidatesSlidesProps<T> & ConnectedDispatch & ConnectedProps) {
    const findListIndex = (list: CandidateKey[], candidate: CandidateKey) =>
        list.findIndex((c) => c.personId === candidate.personId && c.jobId === candidate.jobId);
    const [currentIndex, setCurrentIndex] = useState(findListIndex(candidates, initialActive));
    const active = candidates[currentIndex] ?? initialActive;
    const [fadeIn, setFadeIn] = useState(false);
    const searchUrlRegex = /\/job\/([^/]+)\/search\/([^/]+)\/([a-zA-Z_]+)(?:\/([^/]+))?/;
    const getInitialUrl = () => {
        const currentUrl = window.location.pathname;
        let savedInitialUrl = currentUrl;
        if (currentUrl.match('/job/.*/board') && active.jobId) {
            savedInitialUrl = `/job/${active.jobId}/board`;
        } else {
            const match = currentUrl.match(searchUrlRegex);
            if (match) {
                const [, searchJobId, searchId, kind] = match;
                savedInitialUrl = `/job/${searchJobId}/search/${searchId}/${kind}`;
            }
        }
        return savedInitialUrl;
    };
    const [initialUrl] = useState(getInitialUrl);

    useEffect(() => {
        if (candidates?.length > 0) {
            const nextId = candidates[currentIndex + 1];
            const prevId = candidates[currentIndex - 1];
            if (nextId && !persons?.list?.get(nextId?.personId)) {
                fetchPersonDetails(nextId.personId, nextId.jobId);
            }
            if (prevId && !persons?.list?.get(prevId.personId)) {
                fetchPersonDetails(prevId.personId, prevId.jobId);
            }
        }
    }, [currentIndex]);

    useEffect(() => {
        const currentUrl = window.location.pathname;
        let newUrl = currentUrl;
        if (active.jobId && active.personId) {
            newUrl = `/job/${active.jobId}/board/candidate/${active.personId}`;
        } else {
            const match = currentUrl.match(searchUrlRegex);
            if (match) {
                const [, searchJobId, searchId] = match;
                newUrl = `/job/${searchJobId}/search/${searchId}/total/${active.personId}`;
            } else {
                newUrl = `/person/${active.personId}`;
            }
        }
        if (currentUrl !== newUrl) {
            replaceHistory({}, '', newUrl);
        }
        const current = fadeIn;
        setFadeIn(!current);
        setTimeout(() => setFadeIn(current), fadeInTimeout);
    }, [active]);

    useEffect(() => {
        setCurrentIndex(findListIndex(candidates, initialActive));
    }, [candidates]);

    const handleClose = () => {
        replaceHistory({}, '', initialUrl);
        onClose();
    };

    const handleNavigate = (index: number) => {
        setCurrentIndex(index);
        onNavigate?.(index);
    };

    if (!active) {
        return null;
    }

    const { personId, jobId, selectedTab, jobNotesOnly, notesKind } = active;

    const detail = jobId ? (
        <CandidateDetails
            id={personId}
            jobId={jobId}
            tab={selectedTab}
            jobNotesOnly={jobNotesOnly}
            notesKind={notesKind}
        />
    ) : (
        <PersonProfile id={personId} tab={selectedTab} />
    );

    return (
        <Dialog
            open={true}
            onClose={handleClose}
            disableEnforceFocus={true}
            maxWidth="xl"
            className="profile-dialog-v4"
            css={styles}
        >
            <CSSTransition in={fadeIn} timeout={fadeInTimeout} classNames="profile-dialog">
                {detail}
            </CSSTransition>
            <ProfileNavButtons count={candidates.length} currentIndex={currentIndex} onNavigate={handleNavigate} />
        </Dialog>
    );
}

const mapStateToProps = (state: State): ConnectedProps => ({
    persons: state.personsDetails
});

const mapDispatchToProps: { [action in keyof ConnectedDispatch]: ConnectedDispatch[action] } = {
    fetchPersonDetails: fetchPersonDetailsAction
};

const CandidatesSlides = connect<ConnectedProps, ConnectedDispatch, CandidatesSlidesProps<any>>(
    mapStateToProps,
    mapDispatchToProps
)(CandidatesSlidesComponent);

const SlidesContext = createContext<{
    setList: <T extends { personId: string } & Partial<CandidateKey>>(
        list: T[],
        active: T,
        onNavigate?: (index: number) => void
    ) => void;
    getList: <T extends { personId: string } & Partial<CandidateKey>>() => { list: T[]; active: T };
}>({
    getList: undefined,
    setList: undefined
});

function SlidesProvider<T extends { personId: string } & Partial<CandidateKey>>({
    children
}: {
    children: React.ReactNode;
}) {
    const [list, setList] = useState<T[]>(null);
    const [active, setActive] = useState<T>(null);
    const [onNavigateProp, setOnNavigateProp] = useState<(index: number) => void>(null);

    const setListAndSelected = (l: T[], s: T, onNavigate?: (index: number) => void) => {
        setList(l);
        setActive(s);
        setOnNavigateProp(() => onNavigate);
    };

    const getList = () => ({ list, active });

    const handleClose = () => {
        setList(null);
        setActive(null);
    };

    const slides =
        active && list ? (
            <CandidatesSlides
                candidates={list}
                initialActive={active}
                onClose={handleClose}
                onNavigate={onNavigateProp}
            />
        ) : null;

    return (
        <SlidesContext.Provider value={{ getList, setList: setListAndSelected }}>
            {children}
            {slides}
        </SlidesContext.Provider>
    );
}

const useSlides = () => useContext(SlidesContext);

export { useSlides, SlidesProvider };
