import { gql } from '@apollo/client';
import { FeeType } from 'shared/models/fee';

export interface Commission {
    _id: string;
    feeId: string;
    user: {
        id: string;
        name: string;
    };
    fee: {
        id: string;
        amount: number;
        type: FeeType;
        client: {
            id: string;
            name: string;
        };
        job: {
            id: string;
            title: string;
        };
        person: {
            id: string;
            name: string;
        };
    };
    amount: number;
    incurredAt: number;
    dueAt: number;
    processedAt: number;
    status: string;
    commissionType: string;
}

export interface CreateCommissionsInput {
    objects: Array<{
        userId: string;
        commissionType: string;
        feeId: string;
        amount: number;
        processedAt: number;
    }>;
}

export interface FeeCommission {
    _id: string;
    user: {
        id: string;
        name: string;
    };
    commissionType: string;
    amount: number;
    processedAt: number;
    incurredAt: number;
    dueAt: number;
    status: string;
}

export const CommissionFragment = gql`
    fragment CommissionFragment on commissions {
        _id
        feeId
        user {
            id
            name(path: "full")
        }
        fee {
            id
            amount
            type
            client {
                id
                name
            }
            job {
                id
                title
            }
            person {
                id
                name(path: "full")
            }
        }
        amount
        incurredAt
        processedAt
        dueAt
        status
        commissionType
    }
`;

export const GET_COMMISSIONS = gql`
    ${CommissionFragment}
    query GetCommissions($startTime: bigint!, $endTime: bigint!) {
        commissions(
            where: {
                _or: [
                    { incurredAt: { _gte: $startTime, _lte: $endTime } }
                    { dueAt: { _gte: $startTime, _lte: $endTime } }
                    { processedAt: { _gte: $startTime, _lte: $endTime } }
                ]
            }
            order_by: [{ incurredAt: desc }, { feeId: asc }, { commissionType: desc }]
        ) {
            ...CommissionFragment
        }
    }
`;

export const CREATE_COMMISSIONS = gql`
    mutation CreateCommissions($objects: [commission_payments_insert_input!]!) {
        insert_commission_payments(objects: $objects) {
            affected_rows
        }
    }
`;

export const GET_FEE_COMMISSIONS = gql`
    query GetFeeCommissions($feeId: String!) {
        commissions: commissions(where: { feeId: { _eq: $feeId } }) {
            _id
            user {
                id
                name(path: "full")
            }
            commissionType
            amount
            processedAt
            incurredAt
            dueAt
            status
        }
    }
`;
