import { useMutation } from '@apollo/client';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import { AssignmentTurnedIn, Close, RemoveCircle } from '@material-ui/icons';
import React from 'react';

import { hasRole } from 'shared/models/user';

import { FileDownloadLink } from '../../core-ui/file-download-link';
import { client as apolloClient } from '../../graphql/apollo-client';
import {
    ASSIGN_INVOICES_TO_TRANSACTION,
    BankTransactionWithInvoices,
    DELETE_BANK_TRANSACTION_INVOICES,
    SET_TRANSACTION_IGNORED
} from '../../graphql/queries/accounting';
import { useModal } from '../../hooks/use-modal';
import { useSession } from '../../hooks/use-session';
import { useSnackbar } from '../../hooks/use-snackbar';
import { TransactionInvoiceSearchDialog } from './transaction-invoice-search-dialog';

export const TransactionInvoices: React.FC<{
    transaction: BankTransactionWithInvoices;
    refetch: () => void;
}> = ({ transaction, refetch }) => {
    const [assignDialogOpen, setAssignDialogOpen] = React.useState(false);
    const { getConfirmation, setAlert, showLoading, hideLoading } = useModal();
    const { setSnackbar } = useSnackbar();
    const { userPermissions } = useSession();

    const [ignoreTransaction] = useMutation<{}, { transactionId: string; ignored: boolean }>(SET_TRANSACTION_IGNORED, {
        client: apolloClient('accountant')
    });
    const [assignInvoicesToTransaction] = useMutation<
        {},
        { records: Array<{ amount: number; invoiceId: string; bankTransactionId: string }> }
    >(ASSIGN_INVOICES_TO_TRANSACTION, { client: apolloClient('accountant') });
    const [deleteInvoiceBankTransaction] = useMutation<{}, { invoiceIds: string[]; bankTransactionId: string }>(
        DELETE_BANK_TRANSACTION_INVOICES,
        { client: apolloClient('accountant') }
    );

    const canEdit = hasRole(userPermissions, 'accountant');
    const amountAssigned = transaction.invoices.reduce((acc, invoice) => acc + invoice.amount, 0);

    const handleOpenInvoiceSelectDialog = () => {
        setAssignDialogOpen(true);
    };

    const handleAssignInvoiceDialogClose = () => {
        setAssignDialogOpen(false);
    };

    const getUndoButton = (handleUndo: () => void) => (
        <Button onClick={handleUndo} style={{ color: 'white' }}>
            Undo
        </Button>
    );

    const handleIgnoreTransaction = () => {
        const handleUndo = async () => {
            await ignoreTransaction({ variables: { transactionId: transaction.id, ignored: false } });
            refetch();
            setSnackbar('Transaction unignored');
        };
        getConfirmation(
            async () => {
                try {
                    await ignoreTransaction({ variables: { transactionId: transaction.id, ignored: true } });
                    refetch();
                    setSnackbar('Transaction ignored', getUndoButton(handleUndo));
                } catch (error) {
                    setAlert('Error', 'Error ignoring transaction');
                }
            },
            'Are you sure you want to ignore this transaction?',
            'Ignore Transaction'
        );
    };

    const handleAssignInvoices = async (selectedInvoices: Array<{ invoiceId: string; amount: number }>) => {
        const handleUndo = async () => {
            await deleteInvoiceBankTransaction({
                variables: {
                    bankTransactionId: transaction.id,
                    invoiceIds: selectedInvoices.map(({ invoiceId }) => invoiceId)
                }
            });
            setSnackbar('Invoice removed from transaction');
            refetch();
        };
        const records = selectedInvoices.map(({ invoiceId, amount }) => ({
            amount,
            bankTransactionId: transaction.id,
            invoiceId
        }));
        showLoading();
        try {
            await assignInvoicesToTransaction({ variables: { records } });
            refetch();
            setSnackbar('Invoice assigned to transaction', getUndoButton(handleUndo));
            if (amountAssigned + records.reduce((acc, { amount }) => acc + amount, 0) === transaction.amount) {
                setAssignDialogOpen(false);
            }
        } catch (error) {
            setAlert('Error', 'Error assigning invoice to transaction');
        } finally {
            hideLoading();
        }
    };

    const handleDeleteInvoiceBankTransaction = (invoiceId: string) => () => {
        const amount = transaction.invoices.find((invoice) => invoice.invoice.id === invoiceId).amount;
        const handleUndo = async () => {
            await assignInvoicesToTransaction({
                variables: { records: [{ amount, invoiceId, bankTransactionId: transaction.id }] }
            });
            setSnackbar('Invoice re-assigned to transaction');
            refetch();
        };
        getConfirmation(
            async () => {
                try {
                    await deleteInvoiceBankTransaction({
                        variables: { invoiceIds: [invoiceId], bankTransactionId: transaction.id }
                    });
                    setSnackbar('Invoice removed from transaction', getUndoButton(handleUndo));
                    refetch();
                } catch (error) {
                    setAlert('Error', 'Error deleting invoice from transaction');
                }
            },
            'Are you sure you want to delete this invoice from this transaction?',
            'Delete Invoice from Transaction'
        );
    };

    const list = transaction.invoices.map((invoice, index) => {
        const fileName = invoice.invoice.pdf?.split('/')?.pop();
        const val = invoice.invoice.pdf ? (
            <FileDownloadLink path={invoice.invoice.pdf} filename={fileName}>
                <div className="clickable-cell">{invoice.invoice.invoiceNumber}</div>
            </FileDownloadLink>
        ) : (
            <div>{invoice.invoice.invoiceNumber}</div>
        );
        const removeButton = canEdit ? (
            <div className="invoice-remove-button">
                <Tooltip title="Remove Invoice from Transaction">
                    <IconButton size="small" onClick={handleDeleteInvoiceBankTransaction(invoice.invoice.id)}>
                        <Close fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;
        const separator = index < transaction.invoices.length - 1 ? <div className="invoice-separator" /> : null;
        return (
            <React.Fragment key={invoice.invoice.id}>
                {val}
                {removeButton}
                {separator}
            </React.Fragment>
        );
    });

    const assignInvoiceDialog = assignDialogOpen ? (
        <TransactionInvoiceSearchDialog
            open={assignDialogOpen}
            onClose={handleAssignInvoiceDialogClose}
            onSelect={handleAssignInvoices}
            transaction={transaction}
        />
    ) : null;

    const ignoreButton =
        canEdit && transaction.invoices.length === 0 ? (
            <Tooltip title="Ignore Transaction">
                <IconButton onClick={handleIgnoreTransaction}>
                    <RemoveCircle />
                </IconButton>
            </Tooltip>
        ) : null;

    const assignButton =
        canEdit && amountAssigned < transaction.amount ? (
            <Tooltip title="Assign Invoice">
                <IconButton onClick={handleOpenInvoiceSelectDialog}>
                    <AssignmentTurnedIn />
                </IconButton>
            </Tooltip>
        ) : null;

    const actionButtons =
        ignoreButton || assignButton ? (
            <div className="transaction-action-buttons">
                {assignButton}
                {ignoreButton}
            </div>
        ) : null;

    return (
        <div className="invoices-list">
            {list}
            {actionButtons}
            {assignInvoiceDialog}
        </div>
    );
};
