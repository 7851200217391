import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { sortBy } from 'lodash';
import React, { useMemo, useState } from 'react';
import { fullDate } from '../../common/timestamp';

import { Commission } from '../../graphql/queries/commissions';
import { dataPanelDialogStyles } from '../data-panel';
import { Column, CommissionsDataPanel } from './commissions-data-panel';

const styles = (theme: Theme) => css`
    .MuiDialogActions-root {
        justify-content: space-between;
        align-items: center;
        justify-content: space-between;
        padding: 12px 24px;
        font-size: 14px;

        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 8px;
        }
    }

    .MuiList-root {
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;
    }

    .MuiListItem-root {
        padding: 8px 16px;
        border-bottom: thin solid ${theme.palette.divider};

        &:last-child {
            border-bottom: none;
        }

        .commission-item {
            display: flex;
            justify-content: space-between;
        }
    }
`;

interface UserCommissions {
    [userId: string]: {
        name: string;
        id: string;
        commissions: Commission[];
    };
}

const columns: Column[] = ['amount', 'incurredAt', 'dueAt', 'commissionType', 'client', 'job', 'candidate'];

interface CommissionBatchDialogProps {
    commissions: Commission[];
    open: boolean;
    onClose: () => void;
    processedAt: number;
    onCreate: (commissions: Commission[]) => void;
}

export const CommissionsBatchDialog: React.FC<CommissionBatchDialogProps> = ({
    commissions,
    open,
    onClose,
    processedAt,
    onCreate
}) => {
    const theme = useTheme();
    const userCommissionsList = useMemo(() => {
        const commissionsByUser: UserCommissions = {};

        commissions.forEach((commission) => {
            const user = commission.user;
            if (!user) return;

            commissionsByUser[user.id] = commissionsByUser[user.id] || {
                commissions: [],
                id: user.id,
                name: user.name
            };
            commissionsByUser[user.id].commissions.push(commission);
        });

        return sortBy(
            Object.values(commissionsByUser).map((userCommission) => ({
                ...userCommission,
                totalAmount: userCommission.commissions.reduce((s, commission) => s + commission.amount, 0)
            })),
            'name'
        );
    }, [commissions]);
    const [selectedUser, setSelectedUser] = useState<string | null>(null);

    const handleClick = (userId: string) => () => {
        setSelectedUser(userId);
    };

    const handleClose = () => {
        setSelectedUser(null);
    };

    const handleCreate = () => {
        onCreate(commissions);
    };

    const commissionItems = userCommissionsList.map((commission) => {
        const content = (
            <div className="commission-item">
                <div>{commission.name}</div>
                <div>${commission.totalAmount.toLocaleString()}</div>
            </div>
        );

        return (
            <ListItem key={commission.name} button={true} onClick={handleClick(commission.id)}>
                <ListItemText primary={content} />
            </ListItem>
        );
    });

    const sum = userCommissionsList.reduce((s, commission) => s + commission.totalAmount, 0);
    const title = onCreate ? 'Create Commissions' : `Commissions - ${fullDate(processedAt)}`;

    const selectedUserCommissions = selectedUser ? userCommissionsList.find((user) => user.id === selectedUser) : null;
    const selectedUserCommissionsDialog = selectedUserCommissions ? (
        <Dialog open={true} onClose={handleClose} fullWidth={true} maxWidth="lg" css={dataPanelDialogStyles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Commissions - {selectedUserCommissions.name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <CommissionsDataPanel columns={columns} commissions={selectedUserCommissions.commissions} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="md" css={styles(theme)}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {title}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <List disablePadding={true} dense={true}>
                    {commissionItems}
                </List>
            </DialogContent>
            <DialogActions>
                <div className="summary">Total Amount: ${sum.toLocaleString()}</div>
                <div className="actions">
                    <Button onClick={onClose}>Close</Button>
                    {onCreate && <Button onClick={handleCreate}>Create</Button>}
                </div>
            </DialogActions>
            {selectedUserCommissionsDialog}
        </Dialog>
    );
};
