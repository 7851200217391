import { css } from '@emotion/core';
import moment from 'moment';
import React from 'react';

import { BankTransaction } from '../graphql/queries/accounting';
import { KeyValueInfoList } from './key-value-info-list';

const styles = css`
    .section {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
`;

export const InvoiceBankTransactionDetails: React.FC<{
    data: Array<{ id: string; amount: number; transaction: BankTransaction }>;
}> = ({ data }) => {
    const sections = data.map((txn) => {
        const memo = [txn.transaction.externalMemo, txn.transaction.bankInternalMemo].filter(Boolean).join('\n');
        const infoList: Array<{ key: string; value: string | React.ReactNode }> = [
            { key: 'Account', value: txn.transaction.account?.name },
            { key: 'Total Amount', value: `${txn.transaction.amount.toLocaleString()}` },
            { key: 'Amount Applied', value: `${txn.amount.toLocaleString()}` },
            { key: 'Posted At', value: moment(txn.transaction.postedAt).format('MMM DD, YYYY') },
            { key: 'Memo', value: memo }
        ];
        if (txn.transaction?.permaLink) {
            infoList.push({
                key: 'Link',
                value: (
                    <a href={txn.transaction.permaLink} target="_blank" rel="noopener noreferrer">
                        View
                    </a>
                )
            });
        }
        return (
            <div className="section" key={txn.id}>
                <KeyValueInfoList data={infoList} />
            </div>
        );
    });
    return <div css={styles}>{sections}</div>;
};
