import { useLazyQuery } from '@apollo/client';
import { escapeRegExp, startCase } from 'lodash';
import moment from 'moment';
import React from 'react';

import { PageDialogLink } from '../../common/page-dialog-link';
import { JobEdit } from '../../containers/job-edit';
import { Fee, FEE_DETAILS } from '../../graphql/queries/billing';
import { Commission } from '../../graphql/queries/commissions';
import { useSlides } from '../../hooks/use-candidate-slides';
import { useModal } from '../../hooks/use-modal';
import { ClientInvoicesFees } from '../client-invoices-fees';
import { CellValue, DataPanel, DataPanelColumn } from '../data-panel';
import { JobFeeForm } from '../job-fee-form';
import { PlacementFeeForm } from '../placement-fee-form';

export type Column =
    | 'user'
    | 'amount'
    | 'incurredAt'
    | 'dueAt'
    | 'status'
    | 'commissionType'
    | 'processedAt'
    | 'client'
    | 'job'
    | 'candidate'
    | 'feeAmount'
    | 'feeType';

const allColumns: Array<DataPanelColumn<Column>> = [
    { id: 'user', label: 'Name' },
    { id: 'amount', label: 'Amount' },
    { id: 'commissionType', label: 'Type' },
    { id: 'client', label: 'Client' },
    { id: 'job', label: 'Job' },
    { id: 'candidate', label: 'Candidate' },
    { id: 'incurredAt', label: 'Earned At' },
    { id: 'dueAt', label: 'Due At' },
    { id: 'processedAt', label: 'Paid' },
    { id: 'status', label: 'Status' }
    // { id: 'feeAmount', label: 'Fee Amount' },
    // { id: 'feeType', label: 'Fee Type' }
];

const FeeDetails: React.FC<{ incurredAt: number; feeId: string }> = ({ incurredAt, feeId }) => {
    const [fetchFeeData, { data: feeData }] = useLazyQuery<{ fee: Fee }, { id: string }>(FEE_DETAILS);
    const { showLoading, hideLoading } = useModal();
    const [open, setOpen] = React.useState(false);

    const handleClick = async (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        showLoading();
        await fetchFeeData({ variables: { id: feeId } });
        hideLoading();
        setOpen(true);
    };

    const handleClose = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        setOpen(false);
    };

    let feeDialog;
    if (open) {
        feeDialog =
            feeData?.fee?.type === 'placement' || feeData?.fee?.type === 'monthly-contractor' ? (
                <PlacementFeeForm data={feeData?.fee} onClose={handleClose} disabled={true} />
            ) : (
                <JobFeeForm data={feeData?.fee} disabled={true} onClose={handleClose} />
            );
    }

    return (
        <span className="clickable-cell" onClick={handleClick}>
            {moment(incurredAt).format('MMM DD, YYYY')}
            {feeDialog}
        </span>
    );
};

export const CommissionsDataPanel: React.FC<{
    commissions: Commission[];
    columns: Column[];
    searchText?: string;
    onProcessedAtClick?: (processedAt: number) => void;
}> = ({ commissions, searchText, onProcessedAtClick, columns }) => {
    const { setList } = useSlides();

    const handleCandidateClick = (commission: Commission) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        const candidate = { personId: commission.fee?.person?.id, jobId: commission.fee?.job?.id };
        setList([candidate], candidate);
    };

    const handleProcessedAtClick = (commission: Commission) => (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        onProcessedAtClick?.(commission.processedAt);
    };

    const getCellValue = (column: Column) => (commission: Commission) => {
        switch (column) {
            case 'user':
                return commission.user.name;
            case 'amount':
                return commission.amount;
            case 'incurredAt':
                return commission.incurredAt;
            case 'dueAt':
                return commission.dueAt;
            case 'status':
                return startCase(commission.status);
            case 'commissionType':
                return startCase(commission.commissionType);
            case 'processedAt':
                return commission.processedAt;
            case 'feeAmount':
                return commission.fee?.amount;
            case 'feeType':
                return commission.fee?.type;
            case 'client':
                return commission.fee?.client?.name ?? '';
            case 'job':
                return commission.fee?.job?.title ?? '';
            case 'candidate':
                return commission.fee?.person?.name ?? '';
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue) => {
            const amount = value as number;
            return <span>${amount.toLocaleString()}</span>;
        },
        candidate: (value: CellValue, commission: Commission) =>
            value ? (
                <span className="clickable-cell" onClick={handleCandidateClick(commission)}>
                    {value}
                </span>
            ) : null,
        client: (value: CellValue, commission: Commission) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: commission.fee?.client?.id, activeTab: 'fees' }}
                url={`/client/${commission.fee?.client?.id}/fees`}
            >
                <a href={`/client/${commission.fee?.client?.id}/fees`}>{value}</a>
            </PageDialogLink>
        ),
        dueAt: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        incurredAt: (_1: CellValue, commission: Commission) => (
            <FeeDetails incurredAt={commission.incurredAt} feeId={commission.feeId} />
        ),
        job: (value: CellValue, commission: Commission) => (
            <PageDialogLink
                Component={JobEdit}
                componentProps={{ id: commission.fee?.job?.id }}
                url={`/job/${commission.fee?.job?.id}/edit`}
            >
                <a href={`/job/${commission.fee?.job?.id}/edit`}>{value}</a>
            </PageDialogLink>
        ),
        processedAt: (value: CellValue, commission: Commission) =>
            value ? (
                <span
                    className={onProcessedAtClick ? 'clickable-cell' : ''}
                    onClick={handleProcessedAtClick(commission)}
                >
                    {moment(value as number).format('MMM DD, YYYY')}
                </span>
            ) : (
                ''
            )
    };

    const getCellFilterValue = {
        dueAt: (value: CellValue) => moment(value as number).format('MMM YYYY'),
        incurredAt: (value: CellValue) => moment(value as number).format('MMM YYYY'),
        processedAt: (value: CellValue) => (value ? moment(value as number).format('MMM DD, YYYY') : 'Unpaid')
    };

    const renderSummary = React.useCallback(
        (comms: Commission[]) => {
            const total = comms.reduce((acc, transaction) => acc + transaction.amount, 0);
            return commissions ? (
                <div>
                    Total: ${total.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </div>
            ) : null;
        },
        [commissions]
    );

    const rowFilter = React.useCallback(
        (commission: Commission) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                getCellValue('user')(commission)?.toString().match(searchRegex) ||
                getCellValue('amount')(commission)?.toString().match(searchRegex) ||
                getCellValue('status')(commission)?.toString().match(searchRegex) ||
                getCellValue('commissionType')(commission)?.toString().match(searchRegex) ||
                getCellValue('client')(commission)?.toString().match(searchRegex) ||
                getCellValue('job')(commission)?.toString().match(searchRegex) ||
                getCellValue('candidate')(commission)?.toString().match(searchRegex)
            );
        },
        [searchText, getCellValue]
    );

    const sortableColumns = [
        'user',
        'amount',
        'incurredAt',
        'dueAt',
        'status',
        'commissionType',
        'processedAt',
        'client',
        'candidate'
    ];

    const filterableColumns = ['user', 'incurredAt', 'dueAt', 'status', 'commissionType', 'client', 'processedAt'];
    const selectedColumns = allColumns.filter((column) => columns.includes(column.id));

    return (
        <div className="data-panel">
            <DataPanel
                columns={selectedColumns}
                data={commissions}
                getCellValue={getCellValue}
                getCellRenderer={getCellRenderer}
                getCellFilterValue={getCellFilterValue}
                sortableColumns={sortableColumns}
                filterableColumns={filterableColumns}
                initialSortColumn="incurredAt"
                initialSortDirection="desc"
                rowFilter={rowFilter}
                renderSummary={renderSummary}
            />
        </div>
    );
};
