import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import moment from 'moment';
import React from 'react';

import { MAX_DAYS_AFTER_TRANSACTION, MAX_DAYS_BEFORE_TRANSACTION } from 'shared/models/bank-transaction';

import { SearchTextField } from '../../common/search-text-field';
import { BankTransactionWithInvoices, Invoice } from '../../graphql/queries/accounting';
import { GET_UNPAID_INVOICES } from '../../graphql/queries/accounting';
import { Column, InvoicesDataPanel } from './invoices-data-panel';

const styles = (theme: Theme) => css`
    .MuiDialogContent-root {
        padding: 0;
        overflow: hidden;
        display: flex;
        border-top: thin solid ${theme.palette.divider};
        border-bottom: thin solid ${theme.palette.divider};
    }

    .MuiTableCell-root:first-of-type {
        padding-left: 28px;
    }

    .MuiTableCell-root:last-of-type {
        padding-right: 28px;
    }

    .dialog-content {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        flex: 1 1 auto;

        .content-header {
            padding: 16px 28px;
            display: flex;
            align-items: center;
            gap: 60px;
            border-bottom: thin solid ${theme.palette.divider};

            .header-cell {
                display: flex;
                align-items: center;
                gap: 4px;

                .header-cell-label {
                    font-weight: 600;
                }

                .memo-text {
                    text-wrap: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 640px;
                }
            }
        }

        .data-panel {
            overflow-y: auto;

            .MuiCheckbox-root {
                margin: -9px;
            }
        }
    }

    .dialog-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`;

export const TransactionInvoiceSearchDialog: React.FC<{
    open: boolean;
    transaction: BankTransactionWithInvoices;
    onClose: () => void;
    onSelect: (records: Array<{ invoiceId: string; amount: number }>) => void;
}> = ({ open, transaction, onClose, onSelect }) => {
    const theme = useTheme();
    const [searchText, setSearchText] = React.useState('');
    const [selectedInvoices, setSelectedInvoices] = React.useState<string[]>([]);
    const amountAssigned = transaction.invoices.reduce((acc, invoice) => acc + invoice.amount, 0);
    const { data } = useQuery<{ invoices: Invoice[] }, { startTime: number; endTime: number }>(GET_UNPAID_INVOICES, {
        fetchPolicy: 'network-only',
        variables: {
            endTime: moment(transaction.postedAt).add(MAX_DAYS_AFTER_TRANSACTION, 'days').valueOf(),
            startTime: moment(transaction.postedAt).subtract(MAX_DAYS_BEFORE_TRANSACTION, 'days').valueOf()
        }
    });

    const handleSelectInvoice = (invoiceId: string) => {
        if (selectedInvoices.includes(invoiceId)) {
            setSelectedInvoices(selectedInvoices.filter((id) => id !== invoiceId));
        } else {
            setSelectedInvoices([...selectedInvoices, invoiceId]);
        }
    };

    const handleSubmit = () => {
        const records = selectedInvoices.map((invoiceId) => ({
            amount: Math.min(
                data?.invoices.find((i) => i.id === invoiceId)?.unpaidAmount,
                transaction.amount - amountAssigned
            ),
            invoiceId
        }));
        onSelect(records);
    };

    const memoText = [transaction.externalMemo, transaction.bankInternalMemo].filter(Boolean).join('\n');
    const selectedTotal = selectedInvoices.reduce((acc, invoiceId) => {
        const invoice = data?.invoices.find((i) => i.id === invoiceId);
        const matchingPayment = invoice?.payments?.find((p) => p.amount === transaction.amount);
        return acc + (matchingPayment ? matchingPayment.amount : (invoice?.unpaidAmount ?? 0));
    }, 0);
    const canSave = selectedTotal > 0 && selectedTotal + amountAssigned <= transaction.amount;

    const columns: Column[] = [
        'select',
        'customer',
        'invoiceNumber',
        'unpaidAmount',
        'dueDate',
        'invoiceDate',
        'payments'
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth={true} css={styles(theme)}>
            <DialogTitle>
                <div className="dialog-title">
                    <Typography variant="h4" component="div">
                        Select Invoice for Transaction
                    </Typography>
                    <div className="dialog-title-secondary">
                        <SearchTextField value={searchText} onValueChange={setSearchText} variant="default" />
                    </div>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className="dialog-content">
                    <div className="content-header">
                        <div className="header-cell">
                            <div className="header-cell-label">Unapplied Amount: </div>
                            <div className="header-cell-value">
                                ${(transaction.amount - amountAssigned - selectedTotal).toLocaleString()}
                            </div>
                        </div>
                        <div className="header-cell">
                            <div className="header-cell-label">Posting Date: </div>
                            <div className="header-cell-value">{moment(transaction.postedAt).format('MM/DD/YYYY')}</div>
                        </div>
                        <div className="header-cell">
                            <div className="header-cell-label">Memo: </div>
                            <div className="header-cell-value memo">
                                <Tooltip title={memoText}>
                                    <div className="memo-text">{memoText}</div>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    <InvoicesDataPanel
                        invoices={data?.invoices}
                        searchText={searchText}
                        columns={columns}
                        onSelect={handleSelectInvoice}
                        selected={selectedInvoices}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSubmit} disabled={!canSave}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
