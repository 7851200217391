import { useQuery } from '@apollo/client';
import { css } from '@emotion/core';
import { IconButton, Tooltip, useTheme } from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import React from 'react';
import { currencyFormat } from 'shared/common/string-format-utils';
import { SearchTextField } from '../../common/search-text-field';

import { OpenInNew } from '@material-ui/icons';
import { createDuration, defaultPeriods, Duration } from '../../common/duration';
import {
    BankTransactionWithInvoices as BankTransaction,
    GET_BANK_TRANSACTIONS
} from '../../graphql/queries/accounting';
import { CellValue, DataPanel, DataPanelColumn, dataPanelContainerStyles, selectorStyles } from '../data-panel';
import { DurationSelector } from '../duration-selector';
import { UploadBankTransactionsButton } from '../upload-bank-transactions-button';
import { TransactionInvoices } from './transaction-invoices';

type Column = 'month' | 'account' | 'postingDate' | 'status' | 'amount' | 'memo' | 'invoice';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'month', label: 'Month' },
    { id: 'account', label: 'Account' },
    { id: 'postingDate', label: 'Posting Date' },
    { id: 'amount', label: 'Amount' },
    { id: 'memo', label: 'Memo' },
    { id: 'invoice', label: 'Invoice' }
];

const styles = css`
    .memo {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 240px;
    }

    .invoices-list {
        display: flex;
        flex-wrap: wrap;
        gap: 4px;
        align-items: center;

        .invoice-separator {
            margin-left: 4px;
            margin-right: 4px;
            width: 1px;
            height: 14px;
            background-color: black;
        }

        .transaction-action-buttons {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            margin-top: -11px;
            margin-bottom: -11px;
        }

        .invoice-remove-button {
            width: 0;
            overflow: hidden;
            opacity: 0;
            transition: all 0.2s ease-in-out;
        }
    }

    .MuiTableRow-root:hover {
        .transaction-action-buttons {
            opacity: 1;
        }

        .invoice-remove-button {
            width: 26px;
            opacity: 1;
        }
    }

    .amount {
        display: flex;
        align-items: center;
        gap: 4px;

        .external-link {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }

        &:hover .external-link {
            opacity: 1;
        }
    }
`;

export const BankTransactions: React.FC = () => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'year', offset: 0 }));
    const [searchText, setSearchText] = React.useState('');
    const { data, refetch } = useQuery<{ bankTransactions: BankTransaction[] }, { startTime: number; endTime: number }>(
        GET_BANK_TRANSACTIONS,
        {
            variables: {
                endTime: duration.end,
                startTime: duration.start
            }
        }
    );

    const getCellValue = (column: Column) => (transaction: BankTransaction) => {
        switch (column) {
            case 'month':
                return transaction.postedAt;
            case 'account':
                return transaction.account?.name || '-';
            case 'postingDate':
                return transaction.postedAt;
            case 'amount':
                return transaction.amount;
            case 'memo':
                return [transaction.externalMemo, transaction.bankInternalMemo].filter(Boolean).join('\n');
            case 'invoice':
                return transaction.invoices.map((invoice) => invoice.invoice.invoiceNumber).join(', ');
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue, transaction: BankTransaction) => {
            const amount = value as number;
            const link = transaction.permaLink ? (
                <Tooltip title="View in bank">
                    <span className="external-link">
                        <IconButton size="small" href={transaction.permaLink} target="_blank" rel="noopener noreferrer">
                            <OpenInNew fontSize="small" />
                        </IconButton>
                    </span>
                </Tooltip>
            ) : null;
            return (
                <span className="amount">
                    <span className="amount-value">${amount.toLocaleString()}</span>
                    {link}
                </span>
            );
        },
        invoice: (_1: CellValue, transaction: BankTransaction) => (
            <TransactionInvoices transaction={transaction} refetch={refetch} />
        ),
        memo: (value: CellValue) => (
            <Tooltip title={value}>
                <div className="memo">{value}</div>
            </Tooltip>
        ),
        month: (value: CellValue) => moment(value as number).format('MMM YYYY'),
        postingDate: (_1: CellValue, transaction: BankTransaction) =>
            moment(transaction.postedAt).format('MMM DD, YYYY')
    };

    const getCellFilterValue = {
        invoice: (_1: CellValue, transaction: BankTransaction) =>
            transaction.invoices.length === 0
                ? 'Missing'
                : transaction.ignoreExcessAmount ||
                    Math.abs(
                        transaction.invoices.reduce((acc, invoice) => acc + invoice.amount, 0) - transaction.amount
                    ) < 1
                  ? 'Matched'
                  : 'Partial',
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const renderSummary = React.useCallback(
        (transactions: BankTransaction[]) => {
            const total = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
            return data?.bankTransactions ? <div>Total: {currencyFormat(total)}</div> : null;
        },
        [data?.bankTransactions]
    );

    const rowFilter = React.useCallback(
        (transaction: BankTransaction) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                getCellValue('account')(transaction)?.toString().match(searchRegex) ||
                getCellValue('memo')(transaction)?.toString().match(searchRegex) ||
                transaction.invoices.some((invoice) => invoice.invoice.invoiceNumber.match(searchRegex)) ||
                transaction.invoices.some((invoice) =>
                    invoice.invoice.lineItems?.some((lineItem) => lineItem.description?.match(searchRegex))
                ) ||
                transaction.amount.toString().match(searchRegex)
            );
        },
        [searchText, getCellValue]
    );

    const sortableColumns: Column[] = ['month', 'account', 'postingDate', 'amount', 'invoice'];

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <UploadBankTransactionsButton />
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                </div>
            </div>
            <div className="data-panel" css={styles}>
                <DataPanel
                    columns={columns}
                    data={data?.bankTransactions}
                    getCellValue={getCellValue}
                    getCellRenderer={getCellRenderer}
                    getCellFilterValue={getCellFilterValue}
                    sortableColumns={sortableColumns}
                    filterableColumns={['month', 'account', 'invoice']}
                    initialSortColumn="month"
                    initialSortDirection="desc"
                    renderSummary={renderSummary}
                    rowFilter={rowFilter}
                />
            </div>
        </div>
    );
};
