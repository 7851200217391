import { useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import { SequenceStage, SequenceType, SequenceView } from 'shared/models/sequence';

import { JobSequencesForm as JobSequencesFormComponent } from '../components/job-sequences-form';
import { JOB_DATA, JobData } from '../graphql/queries/search';
import {
    CREATE_SEQUENCE,
    GENERATE_OUTREACH_TEMPLATE,
    Sequence,
    SEQUENCES,
    UPDATE_SEQUENCE,
    UPDATE_SEQUENCE_STATUS
} from '../graphql/queries/sequences';
import { useModal } from '../hooks/use-modal';
import { useSnackbar } from '../hooks/use-snackbar';

export const JobSequencesForm: React.FC<{ role: string; jobId: string; isEditable: boolean }> = ({
    role,
    jobId,
    isEditable
}) => {
    const { setSnackbar } = useSnackbar();

    const { data, loading, refetch } = useQuery<{ sequences: Sequence[] }, { jobId: string }>(SEQUENCES, {
        variables: { jobId }
    });
    const [generateOutreachTemplate] = useMutation<{}, { jobId: string }>(GENERATE_OUTREACH_TEMPLATE);
    const { data: jobData } = useQuery<{ job: JobData }, { jobId: string }>(JOB_DATA, { variables: { jobId } });
    const { showLoading, hideLoading } = useModal();

    const [updateSequence] = useMutation<{
        id: string;
        sequence: {
            title: string;
            stages: SequenceStage[];
            jobId: string;
            type: SequenceType;
            archived: boolean;
        };
    }>(UPDATE_SEQUENCE);
    const [updateSequenceStatus] = useMutation<{
        id: string;
    }>(UPDATE_SEQUENCE_STATUS);

    const [createSequence] = useMutation<{
        sequence: {
            title: string;
            stages: SequenceStage[];
            jobId: string;
            type: SequenceType;
        };
    }>(CREATE_SEQUENCE);

    const handleCreate = async (sequence: SequenceView) => {
        setSnackbar('Creating sequence');
        try {
            await createSequence({
                variables: {
                    sequence: {
                        jobId,
                        stages: sequence.stages,
                        title: sequence.title,
                        type: sequence.type
                    }
                }
            });
            setSnackbar('Sequence created');
        } catch (error) {
            if (error.message.includes('sequences_jobId_contentHash_key')) {
                setSnackbar('Failed to create sequence: sequence content same as existing sequence');
            } else {
                setSnackbar('Failed to create sequence');
            }
        }
        refetch();
    };

    const handleUpdate = async (sequence: SequenceView) => {
        setSnackbar('Updating sequence');
        try {
            await updateSequence({
                variables: {
                    id: sequence.id,
                    sequence: {
                        archived: false,
                        jobId,
                        stages: sequence.stages,
                        title: sequence.title,
                        type: sequence.type
                    }
                }
            });
            setSnackbar('Sequence updated');
        } catch (error) {
            setSnackbar('Failed to update sequence');
        }
        refetch();
    };

    const handleArchiveUpdate = async (sequenceId: string, archive: boolean) => {
        if (archive) {
            setSnackbar('Archiving sequence');
        } else {
            setSnackbar('Unarchiving sequence');
        }
        try {
            await updateSequenceStatus({
                variables: {
                    archive,
                    id: sequenceId
                }
            });
            if (archive) {
                setSnackbar('Archived sequence');
            } else {
                setSnackbar('Sequence unarchived');
            }
        } catch (error) {
            if (archive) {
                setSnackbar('Failed to archive sequence');
            } else {
                setSnackbar('Failed to unarchive sequence');
            }
        }
        refetch();
    };

    const handleGenerateOutreachTemplate = async () => {
        setSnackbar('Generating outreach sequence');
        try {
            showLoading();
            await generateOutreachTemplate({ variables: { jobId } });
            await refetch();
            setSnackbar('Outreach sequence generated');
        } catch (error) {
            setSnackbar('Failed to generate outreach sequence');
        } finally {
            hideLoading();
        }
    };

    if (!loading) {
        return (
            <JobSequencesFormComponent
                job={jobData?.job}
                role={role}
                sequences={data.sequences}
                onCreate={handleCreate}
                onUpdate={handleUpdate}
                onArchiveUpdate={handleArchiveUpdate}
                onGenerateOutreachTemplate={handleGenerateOutreachTemplate}
                isEditable={isEditable}
            />
        );
    } else {
        return null;
    }
};
