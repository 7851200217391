import { css } from '@emotion/core';
import { Button, IconButton, TextField, Theme, useTheme } from '@material-ui/core';
import { Close, RemoveCircleOutline } from '@material-ui/icons';
import React from 'react';

import { DurationProgress } from '../common/duration-progress';
import { useModal } from '../hooks/use-modal';

interface GPTChatProps {
    messages: Array<{ content: string; role: 'system' | 'user' | 'assistant' }>;
    onChange: (messages: Array<{ content: string; role: 'system' | 'user' | 'assistant' }>) => void;
    onGenerate: (messages: Array<{ content: string; role: 'system' | 'user' | 'assistant' }>) => Promise<{
        result: string;
        error?: string;
    }>;
    error?: string;
}

const durationProgressSeconds = 60;

const styles = (theme: Theme) => css`
    display: flex;
    flex-direction: column;

    .error-info {
        border-radius: ${theme.shape.borderRadius}px;
        margin-bottom: 15px;
        background: ${theme.palette.error.light};
        color: ${theme.palette.error.contrastText};
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .prompt-messages {
        display: flex;
        align-items: stretch;
        max-height: 100%;
        overflow-y: hidden;
    }

    .MuiButtonBase-root.MuiButton-contained {
        padding: 4px 10px;
        font-size: 0.75rem;
    }

    .MuiInputBase-input {
        font-size: 15px;
    }

    .system-message {
        flex: 1 0 auto;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        .MuiButtonBase-root {
            margin-bottom: 10px;
        }

        .scroll-container {
            padding: 20px;
            border: thin solid ${theme.palette.divider};
            border-radius: ${theme.shape.borderRadius}px;
        }

        .system-prompt-footer {
            .MuiButtonBase-root {
                margin-top: 10px;
                margin-bottom: 0;
            }
        }
    }

    .non-system-messages {
        flex: 2 1 auto;
        margin-left: 20px;
        max-height: 100%;
        display: flex;
        flex-direction: column;

        .non-system-message {
            border-bottom: thin solid ${theme.palette.divider};
            padding: 20px;

            &:hover {
                background: ${theme.palette.action.hover};
            }

            .message-actions {
                opacity: 0;
                transition: opacity 0.2s ease-in-out;
            }

            &:hover {
                .message-actions {
                    opacity: 1;
                }
            }

            &.new-message {
                .MuiInputBase-root {
                    background: white;
                }
            }
        }
    }

    .non-system-messages-submit {
        .non-system-message:last-child {
            border-bottom: none;
            padding-bottom: 1px;
            padding-left: 1px;

            &:hover {
                background: none;
            }
        }

        .progress-spacer {
            min-height: 5px;
        }
    }

    .message-role-content {
        display: flex;

        .message-role {
            flex: 0 0 auto;
            width: 120px;
        }

        .message-content {
            flex: 1 1 auto;
            margin-left: 20px;
        }

        .message-actions {
            flex: 0 0 auto;
            width: 24px;
            margin-left: 20px;

            .MuiIconButton-root {
                display: block;
                margin-bottom: 15px;
            }

            i.far {
                font-size: 16px;
            }
        }
    }

    .scroll-container {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: ${theme.shape.borderRadius}px;
        }
    }
`;

export const GPTChat: React.FC<GPTChatProps> = ({ messages, onChange, error, onGenerate }) => {
    const theme = useTheme();
    const [inProgress, setInProgress] = React.useState(false);
    const [requestError, setRequestError] = React.useState<string | undefined>(error);
    const [newMessage, setNewMessage] = React.useState('');
    const scrollToRef = React.useRef<HTMLDivElement>();
    const { getConfirmation } = useModal();

    const scrollToTextField = () => {
        if (scrollToRef.current) {
            // hack to get the initial scroll to work
            setTimeout(
                () => scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' }),
                100
            );
        }
    };

    React.useEffect(() => {
        scrollToTextField();
    }, []);

    const handleToggleRole = (index: number) => () => {
        const message = messages[index];
        onChange(
            messages
                .slice(0, index)
                .concat(
                    [{ ...message, role: message.role === 'assistant' ? 'user' : 'assistant' }],
                    messages.slice(index + 1)
                )
        );
    };

    const handleTextChange = (index: number) => (event: React.ChangeEvent<{ value: string }>) => {
        onChange(
            messages
                .slice(0, index)
                .concat([{ ...messages[index], content: event.target.value }], messages.slice(index + 1))
        );
    };

    const handleNewMessageKeyPress = (e: React.KeyboardEvent) => {
        if (!e.shiftKey && e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            onChange(messages.concat([{ role: 'user', content: newMessage }]));
            setNewMessage('');
            scrollToTextField();
        }
    };

    const handleNewMessageChange = (event: React.ChangeEvent<{ value: string }>) => {
        setNewMessage(event.target.value);
    };

    const handleRemove = (index: number) => () => {
        getConfirmation(
            () => onChange(messages.slice(0, index).concat(messages.slice(index + 1))),
            'Are you sure you want to remove this message?',
            'Remove message'
        );
    };

    const handleSubmit = async () => {
        let messagesToSend = messages;
        if (newMessage?.trim().length > 0) {
            messagesToSend = messagesToSend.concat([{ role: 'user', content: newMessage }]);
            onChange(messages.concat([{ role: 'user', content: newMessage }]));
            scrollToTextField();
        }
        setNewMessage('');
        setInProgress(true);
        setRequestError(undefined);
        const { result, error: err } = await onGenerate(messagesToSend);
        if (result) {
            onChange(messagesToSend.concat([{ role: 'assistant', content: result }]));
        }
        if (err) {
            setRequestError(err);
        }
        scrollToTextField();
        setInProgress(false);
    };

    const handleRemoveErrorMessage = () => setRequestError(undefined);

    const progressSpacer = inProgress ? <DurationProgress duration={durationProgressSeconds} /> : null;

    const nonSystemMessages = messages?.slice(1).map((m, i) => (
        <div className="non-system-message" key={i}>
            <div className="message-role-content">
                <div className="message-role">
                    <Button
                        variant="contained"
                        disableElevation={true}
                        onClick={handleToggleRole(i + 1)}
                        disabled={inProgress}
                    >
                        {m.role}
                    </Button>
                </div>
                <div className="message-content">
                    <TextField
                        value={m.content}
                        InputProps={{ disableUnderline: true }}
                        multiline={true}
                        fullWidth={true}
                        onChange={handleTextChange(i + 1)}
                        placeholder={`Enter ${m.role === 'assistant' ? 'an' : 'a'} ${m.role} message here.`}
                        disabled={inProgress}
                    />
                </div>
                <div className="message-actions">
                    <IconButton onClick={handleRemove(i + 1)} disabled={inProgress} size="small">
                        <RemoveCircleOutline fontSize="small" />
                    </IconButton>
                </div>
            </div>
        </div>
    ));

    const errorInfo = requestError ? (
        <div className="error-info">
            <div>{requestError}</div>
            <div>
                <IconButton onClick={handleRemoveErrorMessage} size="small">
                    <Close fontSize="small" htmlColor={theme.palette.common.white} />
                </IconButton>
            </div>
        </div>
    ) : null;

    return (
        <div className="gpt-completions-container" css={styles(theme)}>
            {errorInfo}
            <div className="prompt-messages">
                <div className="system-message">
                    <div className="scroll-container">
                        <Button variant="contained" disableElevation={true} disabled={inProgress}>
                            {messages?.[0].role}
                        </Button>
                        <TextField
                            value={messages?.[0].content}
                            InputProps={{ disableUnderline: true }}
                            multiline={true}
                            fullWidth={true}
                            onChange={handleTextChange(0)}
                            disabled={inProgress}
                        />
                    </div>
                </div>
                <div className="non-system-messages">
                    <div className="non-system-messages-messages scroll-container">
                        {nonSystemMessages}
                        <div className="non-system-message new-message scroll-to-target" ref={scrollToRef}>
                            <TextField
                                value={newMessage}
                                variant="outlined"
                                onChange={handleNewMessageChange}
                                onKeyPress={handleNewMessageKeyPress}
                                placeholder="Enter a new message here."
                                fullWidth={true}
                                multiline={true}
                                disabled={inProgress}
                            />
                        </div>
                    </div>
                    <div className="non-system-messages-submit">
                        <div className="progress-spacer">{progressSpacer}</div>
                        <div className="non-system-message">
                            <Button
                                variant="contained"
                                color="primary"
                                disableElevation={true}
                                onClick={handleSubmit}
                                disabled={inProgress}
                            >
                                Generate
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
