import { css } from '@emotion/core';
import {
    Dialog,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MUISelect,
    SelectProps as MUISelectProps,
    Switch,
    TextField as MUITextField,
    TextFieldProps as MUITextFieldProps,
    Theme,
    useTheme
} from '@material-ui/core';
import { Autocomplete as MUIAutocomplete, RenderInputParams } from '@material-ui/lab';
import { isEqual } from 'lodash';
import React from 'react';

import { Editor } from 'react-ce';

import { fullDate } from '../common/timestamp';
import { DayPicker } from './react-day-picker';

type BaseFieldProps<T> = Omit<MUITextFieldProps, 'variant' | 'onChange'> & {
    label: string;
    value: T;
    onChange?: (value: T) => void;
    validate?: (value: T) => void;
    errorText?: string;
};

type TextFieldProps = BaseFieldProps<string | number>;

export function TextField(props: TextFieldProps) {
    const { value, onChange, validate, errorText, ...rest } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!onChange) {
            return;
        }
        const val = rest.type === 'number' ? Number(e.target.value) : e.target.value;
        onChange(val);
        if (validate) {
            validate(val);
        }
    };

    return (
        <MUITextField
            {...rest}
            value={value ?? ''}
            onChange={handleChange}
            error={!!errorText}
            helperText={errorText ?? rest.helperText}
            fullWidth={true}
        />
    );
}

type AutocompleteProps<T> = Omit<BaseFieldProps<T>, 'multiple'> & {
    options: Array<{ value: T; label: string }>;
};

export function Autocomplete<T>(props: AutocompleteProps<T>) {
    // does not work with freeSolo the onChange handler works differently
    const { options, value, onChange, disabled, validate, errorText, ...rest } = props;
    const getOptionLabel = (option: { value: T; label: string }) => option.label;

    const autoCompleteValue = options.find((o) => isEqual(o.value, value)) ?? null;

    const handleChange = (_: React.ChangeEvent<{}>, val: { value: T; label: string } | null) => {
        onChange(val?.value);
        if (validate) {
            validate(val?.value);
        }
    };

    const renderInput = (params: RenderInputParams) => (
        <MUITextField {...rest} {...params} error={!!errorText} helperText={errorText} />
    );

    return (
        <MUIAutocomplete
            fullWidth={true}
            options={options}
            getOptionLabel={getOptionLabel}
            value={autoCompleteValue}
            onChange={handleChange}
            disableClearable={true}
            renderInput={renderInput}
            disabled={disabled}
            className={rest.className}
        />
    );
}

type MultiAutoCompleteProps<T> = Omit<MUITextFieldProps, 'variant' | 'onChange'> & {
    label: string;
    value: T[];
    onChange: (value: T[]) => void;
    validate?: (value: T[]) => void;
    options: Array<{ value: T; label: string }>;
    errorText?: string;
    freeSolo?: boolean;
};

export function MultiAutocomplete(props: MultiAutoCompleteProps<string>) {
    const { options, value, onChange, disabled, validate, errorText, freeSolo, ...rest } = props;
    const getOptionLabel = (option: { value: string; label: string }) => option.label ?? option.value;

    const autoCompleteValue = freeSolo
        ? (value?.map((v) => ({ value: v, label: v })) ?? [])
        : options.filter((option) => (value ?? []).includes(option.value));

    const handleChange = (_: React.ChangeEvent<{}>, val: Array<{ value: string; label: string } | string> | null) => {
        const updated = val?.map((v) => (typeof v === 'string' ? v?.trim() : v.value)) ?? [];
        onChange(updated);
        if (validate) {
            validate(updated);
        }
    };

    const renderInput = (params: RenderInputParams) => (
        <MUITextField {...rest} {...params} error={!!errorText} helperText={errorText} />
    );

    return (
        <MUIAutocomplete
            fullWidth={true}
            multiple={true}
            options={options}
            getOptionLabel={getOptionLabel}
            value={autoCompleteValue}
            disableClearable={true}
            renderInput={renderInput}
            disabled={disabled}
            className={rest.className}
            onChange={handleChange}
            freeSolo={freeSolo}
        />
    );
}

export const TimestampTextField = React.forwardRef<HTMLDivElement, MUITextFieldProps>(({ value, ...rest }, ref) => {
    const [dateDialogOpen, setDateDialogOpen] = React.useState(false);
    const formattedDate = value ? fullDate(value as number) : '';

    const handleDateClicked = () => {
        if (rest.disabled || !rest.onChange) {
            return;
        }
        setDateDialogOpen(true);
    };

    const handleDayChange = (date: Date) => {
        if (rest.onChange) {
            const syntheticEvent = {
                target: {
                    value: date.valueOf()
                }
            } as unknown as React.ChangeEvent<HTMLInputElement>;
            rest.onChange(syntheticEvent);
        }
        setDateDialogOpen(false);
    };

    const handleCloseDayDialog = () => setDateDialogOpen(false);

    const dayDialog = dateDialogOpen ? (
        <Dialog open={true} onClose={handleCloseDayDialog} maxWidth="md">
            <DayPicker defaultMonth={value ? new Date(value as number) : new Date()} onDayClick={handleDayChange} />
        </Dialog>
    ) : null;

    return (
        <>
            <MUITextField
                {...rest}
                ref={ref}
                value={formattedDate}
                onChange={undefined}
                onClick={handleDateClicked}
                type="text"
            />
            {dayDialog}
        </>
    );
});

type DateTextFieldProps = Omit<BaseFieldProps<number>, 'type'>;

export function DateTextField(props: DateTextFieldProps) {
    const { value, onChange, validate, errorText, ...rest } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(Number(e.target.value));
        if (validate) {
            validate(Number(e.target.value));
        }
    };

    return (
        <TimestampTextField
            {...rest}
            value={value ?? ''}
            onChange={handleChange}
            fullWidth={true}
            error={!!errorText}
            helperText={errorText}
        />
    );
}

type SelectFieldProps<T> = Omit<BaseFieldProps<T>, 'multiple'> & {
    options: Array<{ value: T; label: string }>;
};

export function SelectField<T extends string | number | string[]>(props: SelectFieldProps<T>) {
    const { options, value, onChange, validate, errorText, ...rest } = props;

    const arrayValuesInUse = Array.isArray(value);
    const selectedValue = arrayValuesInUse ? options.findIndex((o) => isEqual(o.value, value)) : value;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = arrayValuesInUse ? options[Number(e.target.value)].value : (e.target.value as T);
        onChange(val);
        if (validate) {
            validate(val);
        }
    };

    const menuItems = options.map((option, index) => (
        <MenuItem key={index} value={arrayValuesInUse ? index : option.value}>
            {option.label}
        </MenuItem>
    ));

    return (
        <MUITextField
            {...rest}
            fullWidth={true}
            value={selectedValue ?? ''}
            onChange={handleChange}
            error={!!errorText}
            helperText={errorText}
            select={true}
        >
            {menuItems}
        </MUITextField>
    );
}

type MultiSelectFieldProps<T> = Omit<MUISelectProps, 'multiple' | 'value' | 'onChange'> & {
    options: Array<{ value: T; label: string }>;
    value: T[];
    label: string;
    onChange: (value: T[]) => void;
    validate?: (value: T[]) => void;
    errorText?: string;
};

// tslint:disable-next-line: no-magic-numbers
export const generateUniqueId = (prefix: string) => `${prefix}-${Math.random().toString(10).slice(2, 10)}`;

export function MultiSelectField<T extends string | number>(props: MultiSelectFieldProps<T>) {
    const [selectLabelId] = React.useState(generateUniqueId('select-form-field'));
    const { options, value, onChange, validate, errorText, label, ...rest } = props;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value as any as T[];
        onChange(val);
        if (validate) {
            validate(val);
        }
    };

    const menuItems = options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
            {option.label}
        </MenuItem>
    ));

    const helperText = errorText ? <FormHelperText error={true}>{errorText}</FormHelperText> : null;

    return (
        <FormControl fullWidth={true}>
            <InputLabel id={selectLabelId}>{label}</InputLabel>
            <MUISelect
                {...rest}
                labelId={selectLabelId}
                fullWidth={true}
                value={value ?? []}
                onChange={handleChange}
                multiple={true}
            >
                {menuItems}
            </MUISelect>
            {helperText}
        </FormControl>
    );
}

const switchStyles = (theme: Theme) => css`
    .MuiInputLabel-root {
        color: ${theme.palette.text.secondary};
    }

    .MuiSwitch-root {
        margin-top: 15px;
        margin-left: -10px;
    }
`;

export const SwitchWithLabel: React.FC<{ label: string; checked: boolean; onChange: (value: boolean) => void }> = ({
    label,
    checked,
    onChange
}) => {
    const theme = useTheme();

    const handleChange = (_1: React.ChangeEvent<HTMLInputElement>, val: boolean) => {
        onChange(val);
    };

    return (
        <FormControl fullWidth={true} css={switchStyles(theme)}>
            <InputLabel
                shrink={true}
                htmlFor="email-notifications-switch"
                style={{ color: theme.palette.text.secondary }}
            >
                {label}
            </InputLabel>
            <Switch checked={checked} onChange={handleChange} color="primary" />
        </FormControl>
    );
};

interface RichTextFieldProps {
    onChange?: (content: string) => void;
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    label?: string;
    errorText?: string;
    toolbar?: 'full' | 'medium' | 'small' | 'none';
    value: string;
}

// tslint:disable: no-magic-numbers
const richTextFieldStyles = (theme: Theme, error: boolean, disabled: boolean, focused: boolean) => css`
    position: relative;
    margin-top: 5px;
    margin-bottom: ${theme.spacing(1)}px;
    flex: 1 1 auto;

    .rich-text-field {
        border: none;
        padding: 22px 0 0;
        border-bottom: 1px solid ${error ? theme.palette.error.main : theme.palette.text.disabled};
        position: relative;
        transition: all 200ms ease-out;
        opacity: ${disabled ? 0.8 : 1.0};

        &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: ${disabled ? 0 : 2}px;
            background-color: ${error ? theme.palette.error.main : theme.palette.primary.main};
            transform: scaleX(0);
            transition: transform 200ms ease-out;
            transform-origin: center;
        }

        &.focus::after {
            transform: scaleX(1);
        }

        &:hover::after {
            transform: scaleX(1);
        }

        &.no-toolbar {
            .text-editor-toolbar {
                display: none;
            }
        }

        &.text-editor-container {
            padding-bottom: ${focused ? 2 : 10}px;

            div::-webkit-scrollbar {
                width: 6px;
            }

            .text-editor-content {
                min-height: 18.75px;
                padding-bottom: 0;
                padding-right: 5px;
                max-height: 450px;
                overflow-y: auto;

                &.disabled {
                    cursor: default;
                    opacity: 1;
                }
            }

            .text-editor-toolbar {
                display: flex;
                box-shadow: none;
                border-top: ${focused ? 'thin' : '0'} solid ${theme.palette.divider};
                height: ${focused ? 40 : 0}px;
                overflow: hidden;
                margin: 0;
                padding: 0;
                margin-top: ${focused ? 10 : 0}px;
                opacity: ${focused ? 1 : 0};
                transition:
                    height 0.2s,
                    margin-top 0.2s,
                    opacity 0.2s;
            }
        }
    }

    .label {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1rem;
        transform: translate(0, 1.5rem) scale(1);
        transform-origin: top left;
        transition: all 200ms ease-out;
        color: ${error ? theme.palette.error.main : theme.palette.text.secondary};
    }

    .label-focused {
        transform: translate(0, 0) scale(0.75);
        color: ${error ? theme.palette.error.main : theme.palette.primary.main};
    }

    .label-not-empty {
        transform: translate(0, 0) scale(0.75);
        color: ${error ? theme.palette.error.main : theme.palette.text.secondary};
    }

    .helper-text {
        font-size: 0.75rem;
        color: ${error ? theme.palette.error.main : theme.palette.text.secondary};
        margin-top: ${theme.spacing(0.5)}px;
    }
`;
// tslint:enable: no-magic-numbers

export const RichTextField: React.FC<RichTextFieldProps> = ({
    value,
    onChange,
    disabled,
    className,
    placeholder,
    errorText,
    label,
    toolbar = 'full'
}) => {
    const theme = useTheme();
    const [focused, setFocused] = React.useState(false);

    const handleSetFocused = (focus: boolean) => () => {
        setFocused(focus);
    };

    const handleChange = (content: string) => {
        if (onChange) {
            const trimmed = content?.trim();
            if (trimmed === '<br>' || trimmed === '<div><br></div>') {
                onChange(null);
            } else {
                onChange(content);
            }
        }
    };

    const helperText = errorText ? <FormHelperText error={true}>{errorText}</FormHelperText> : null;
    const editorClass = `rich-text-field ${focused ? 'focus' : ''} ${toolbar === 'none' ? 'no-toolbar' : ''} ${
        value ? 'not-empty' : ''
    }`;
    const labelClass = `label ${focused ? 'label-focused' : value ? 'label-not-empty' : ''}`;

    return (
        <div css={richTextFieldStyles(theme, !!errorText, disabled, focused)} className={className}>
            <label className={labelClass}>{label}</label>
            <Editor
                value={value}
                onChange={handleChange}
                disabled={disabled}
                placeholder={placeholder}
                onBlur={handleSetFocused(false)}
                onFocus={handleSetFocused(true)}
                className={editorClass}
                toolbar={toolbar === 'none' ? undefined : toolbar}
            />
            {helperText && <div className="helper-text">{helperText}</div>}
        </div>
    );
};
