import { useQuery } from '@apollo/client';
import { Tooltip, useTheme } from '@material-ui/core';
import { escapeRegExp, startCase } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import { currencyFormat } from 'shared/common/string-format-utils';

import { getInvoiceStatusLabel, invoiceStatus } from '../common/billing';
import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { SearchTextField } from '../common/search-text-field';
import { FileDownloadLink } from '../core-ui/file-download-link';
import { Invoice, INVOICES } from '../graphql/queries/billing';
import { BillingDataProvider } from '../hooks/use-billing-data';
import { useLocalStorage } from '../hooks/use-local-storage';
import { billingPageRoutes } from './billing-page-header';
import { ClientInvoicesFees } from './client-invoices-fees';
import { dataPanelContainerStyles, selectorStyles } from './data-panel';
import { CellValue, DataPanel, DataPanelColumn } from './data-panel';
import { DurationSelector } from './duration-selector';
import { InvoiceBankTransactionsInfo } from './invoice-bank-transactions-info';
import { InvoiceDetailsDialog } from './invoice-details-dialog';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { NavigationSelect } from './navigation-select';

type Column = 'month' | 'client' | 'invoiceNumber' | 'amount' | 'paymentStatus' | 'dueDate' | 'status' | 'bankPayment';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'month', label: 'Month' },
    { id: 'client', label: 'Client' },
    { id: 'invoiceNumber', label: 'Invoice #' },
    { id: 'amount', label: 'Amount' },
    { id: 'status', label: 'Status' },
    { id: 'dueDate', label: 'Due Date' },
    { id: 'bankPayment', label: 'Bank Payment' }
];

const InvoiceCell: React.FC<{ value: CellValue; invoice: Invoice }> = ({ value, invoice }) => {
    const [showDetails, setShowDetails] = React.useState(false);

    const handleToggleDetails = () => {
        setShowDetails(!showDetails);
    };

    const dialog = showDetails ? (
        <InvoiceDetailsDialog open={showDetails} onClose={handleToggleDetails} invoice={invoice} />
    ) : null;

    return (
        <>
            <span className="clickable-cell" onClick={handleToggleDetails}>
                ${(value as number).toLocaleString()}
            </span>
            {dialog}
        </>
    );
};

export const Invoices = (): JSX.Element => {
    const theme = useTheme();
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'year', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('invoices-job-types', jobTypesForFilter.get('all'));
    const [searchText, setSearchText] = React.useState('');

    const { data, refetch } = useQuery<
        { invoices: Invoice[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(INVOICES, {
        variables: {
            endTime: duration.end,
            excludeClientIds: internalClientIds,
            jobType: jobTypes,
            startTime: duration.start
        }
    });

    const getCellValue = (column: Column) => (invoice: Invoice) => {
        switch (column) {
            case 'month':
                return invoice.invoiceDate;
            case 'client':
                return invoice.customer?.client?.name || '-';
            case 'invoiceNumber':
                return invoice.invoiceNumber;
            case 'amount':
                return invoice.totalAmount;
            case 'dueDate':
                return invoice.dueDate;
            case 'bankPayment':
                return invoice.bankTransactions?.[0]?.transaction?.postedAt ?? 0;
            case 'status':
                return getInvoiceStatusLabel(invoiceStatus(invoice));
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue, invoice: Invoice) => <InvoiceCell value={value} invoice={invoice} />,
        bankPayment: (_1: CellValue, invoice: Invoice) => <InvoiceBankTransactionsInfo invoice={invoice} />,
        client: (value: CellValue, invoice: Invoice) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: invoice.customer?.client?.id, activeTab: 'invoices' }}
                url={`/client/${invoice.customer?.client?.id}/invoices`}
            >
                <a href={`/client/${invoice.customer?.client?.id}/invoices`}>{value}</a>
            </PageDialogLink>
        ),
        dueDate: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        invoiceNumber: (value: CellValue, invoice: Invoice) => {
            const fileName = invoice.pdf?.split('/')?.pop();
            return invoice.pdf ? (
                <FileDownloadLink path={invoice.pdf} filename={fileName}>
                    <span className="clickable-cell">{value}</span>
                </FileDownloadLink>
            ) : (
                <span>{value}</span>
            );
        },
        month: (value: CellValue) => moment(value as number).format('MMM YYYY'),
        status: (value: CellValue, invoice: Invoice) => (
            <Tooltip title={startCase(invoice.status.toLocaleLowerCase())}>
                <span>{value}</span>
            </Tooltip>
        )
    };

    const getCellFilterValue = {
        bankPayment: (_1: CellValue, invoice: Invoice) =>
            invoice.status === 'OPEN'
                ? 'Not Paid'
                : invoice.status === 'PARTIAL_PAYMENT'
                  ? 'Partially Paid'
                  : invoice.bankTransactions?.length === 0
                    ? 'Missing'
                    : 'Matched',
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const renderSummary = React.useCallback(
        (invoices: Invoice[]) => {
            const total = invoices.reduce((acc, invoice) => acc + invoice.totalAmount, 0);
            return data?.invoices ? <div>Total: {currencyFormat(total)}</div> : null;
        },
        [data?.invoices]
    );

    const rowFilter = React.useCallback(
        (invoice: Invoice) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                getCellValue('client')(invoice)?.toString().match(searchRegex) ||
                getCellValue('invoiceNumber')(invoice)?.toString().match(searchRegex) ||
                getCellValue('status')(invoice)?.toString().match(searchRegex) ||
                invoice.lineItems?.some((lineItem) => lineItem.description?.match(searchRegex))
            );
        },
        [searchText, getCellValue]
    );

    const sortableColumns = [
        'month',
        'client',
        'amount',
        'paymentStatus',
        'dueDate',
        'paidDate',
        'status',
        'bankPayment'
    ];

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <NavigationSelect routes={billingPageRoutes} variant="outlined" />
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                    <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                </div>
            </div>
            <div className="data-panel">
                <BillingDataProvider refetch={refetch}>
                    <DataPanel
                        columns={columns}
                        data={data?.invoices}
                        getCellValue={getCellValue}
                        getCellRenderer={getCellRenderer}
                        getCellFilterValue={getCellFilterValue}
                        sortableColumns={sortableColumns}
                        filterableColumns={['month', 'paymentStatus', 'client', 'status', 'bankPayment']}
                        initialSortColumn="month"
                        initialSortDirection="desc"
                        renderSummary={renderSummary}
                        rowFilter={rowFilter}
                    />
                </BillingDataProvider>
            </div>
        </div>
    );
};
