import { css } from '@emotion/core';
import { IconButton, Theme, Tooltip, useTheme } from '@material-ui/core';
import { OpenInNew, PieChart } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';

import { Invoice } from '../graphql/queries/accounting';
import { useModal } from '../hooks/use-modal';
import { InvoiceBankTransactionDetails } from './invoice-bank-transaction-details';

const styles = (theme: Theme) => css`
    display: flex;
    align-items: center;
    gap: 10px;

    .link {
        cursor: pointer;
        color: ${theme.palette.primary.main};
    }

    .external-link {
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover .external-link {
        opacity: 1;
    }

    .MuiSvgIcon-fontSizeSmall {
        font-size: 1rem;
        color: ${theme.palette.primary.main};
    }
`;

export const InvoiceBankTransactionsInfo: React.FC<{
    invoice: Invoice;
}> = ({ invoice }) => {
    const { setAlert } = useModal();
    const theme = useTheme();

    const handleClick = () => {
        setAlert('Bank Transaction Details', <InvoiceBankTransactionDetails data={invoice.bankTransactions} />);
    };

    if (!invoice.bankTransactions || invoice.bankTransactions.length === 0) return null;

    const firstTransaction = invoice.bankTransactions[0];
    const externalLink = firstTransaction.transaction.permaLink ? (
        <span className="external-link">
            <IconButton
                size="small"
                href={firstTransaction.transaction.permaLink}
                target="_blank"
                rel="noopener noreferrer"
            >
                <OpenInNew fontSize="small" />
            </IconButton>
        </span>
    ) : null;

    const partial = invoice.totalAmount > invoice.bankTransactions.reduce((acc, curr) => acc + curr.amount, 0);
    const partialIcon = partial ? (
        <Tooltip title="Partial Payment">
            <PieChart fontSize="small" />
        </Tooltip>
    ) : null;

    return (
        <span css={styles(theme)}>
            <span className="link" onClick={handleClick}>
                {moment(firstTransaction.transaction.postedAt).format('MMM DD, YYYY')}
            </span>
            {partialIcon}
            {externalLink}
        </span>
    );
};
