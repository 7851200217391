import { gql } from '@apollo/client';

export interface FunnelStats {
    funnel: Array<{
        user: {
            id: string;
            name: string;
            username: string;
        };
        outreachesSent: number;
        outreachResponseRatio: number;
        acceptOutreachRatio: number;
        phoneScreens: number;
        submits: number;
        clientAccepts: number;
        finalRounds: number;
        offers: number;
        hired: number;
        billing: number;
    }>;
}

export interface MetricsVariables {
    excludeJobTypes: string[];
    startTime: number;
    endTime: number;
    excludeClientIds: string[];
}

export interface BillingVariables extends MetricsVariables {
    assignee: string;
}

export interface Billing {
    billings: { aggregate: { sum: { fees: number } } };
}

export interface LeaderboardBilling {
    userId: string;
    name: string;
    recruiterBillings: number;
    amBillings: number;
    totalBillings: number;
}

export interface CandidateStageChange {
    jobId: string;
    personId: string;
    date: string;
    person: {
        id: string;
        name: string;
    };
    job: {
        id: string;
        title: string;
        client: {
            id: string;
            name: string;
        };
    };
}

export const FUNNEL_STATS = gql`
    query FunnelMetrics($excludeJobTypes: jsonb!, $startTime: bigint!, $endTime: bigint!, $excludeClientIds: jsonb!) {
        funnel: recruiter_funnel_metrics_by_period(
            args: {
                end_time: $endTime
                exclude_client_ids: $excludeClientIds
                exclude_job_types: $excludeJobTypes
                start_time: $startTime
            }
        ) {
            user {
                id
                name(path: "full")
                username
            }
            outreachesSent
            outreachResponseRatio
            phoneScreens
            submits
            clientAccepts
            finalRounds
            offers
            hired
            billing
        }
    }
`;

export const HOMEPAGE_FUNNEL_STATS_SUB = gql`
    subscription HomePageFunnelStats {
        funnel: recruiter_current_summary_metrics {
            user {
                id
                name(path: "full")
            }
            acceptOutreachRatio
            outreachesSent
            outreachResponseRatio
            phoneScreens
            submits
            clientAccepts
            finalRounds
            offers
            hired
            billing
        }
    }
`;

export const HOMEPAGE_ACCEPTS_TARGET = gql`
    query HomePageAcceptsTarget($username: String!) {
        target: recruiter_14d_funnel_target(
            where: { username: { _eq: $username }, stage: { _eq: "client_first_round" } }
        ) {
            goal
        }
    }
`;

export const HOMEPAGE_FUNNEL_BASELINE_STATS_SUB = gql`
    subscription HomePageFunnelBaselineStats {
        funnel: recruiter_previous_summary_metrics {
            user {
                id
                name(path: "full")
            }
            acceptOutreachRatio
            outreachesSent
            outreachResponseRatio
            phoneScreens
            submits
            clientAccepts
            finalRounds
            offers
            hired
            billing
        }
    }
`;

export const BILLINGS = gql`
    query Billings(
        $assignee: String!
        $startTime: bigint!
        $endTime: bigint!
        $excludeJobTypes: [String!]
        $excludeClientIds: [String!]
    ) {
        billings: fees_aggregate(
            where: {
                incurredAt: { _gte: $startTime, _lte: $endTime }
                recruiterId: { _eq: $assignee }
                clientId: { _nin: $excludeClientIds }
                _or: [{ job: { jobType: { _nin: $excludeJobTypes } } }, { jobId: { _is_null: true } }]
            }
        ) {
            aggregate {
                sum {
                    fees: amount
                }
            }
        }
    }
`;

export const BILLINGS_LEADERBOARD = gql`
    query BillingsLeaderboard($jobType: jsonb!, $startTime: bigint!, $endTime: bigint!, $excludeClientIds: jsonb!) {
        leaderboard: billings_leaderboard(
            args: {
                end_time: $endTime
                exclude_client_ids: $excludeClientIds
                job_types: $jobType
                start_time: $startTime
            }
            order_by: [{ totalBillings: desc }, { name: asc }]
        ) {
            userId
            name
            recruiterBillings
            amBillings
            totalBillings
        }
    }
`;

export const STAGE_CHANGE_CANDIDATES = gql`
    query GetCandidateStageChanges($userId: String!, $stage: String!, $startDate: date!, $endDate: date!) {
        changes: candidate_stage_changes_valid(
            where: { userId: { _eq: $userId }, toStage: { _eq: $stage }, date: { _gte: $startDate, _lte: $endDate } }
            order_by: { date: desc_nulls_last }
        ) {
            jobId
            personId
            date
            person {
                id
                name(path: "full")
            }
            job {
                id
                title
                client {
                    id
                    name
                }
            }
        }
    }
`;
