import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { AttachMoney, CheckCircleOutline } from '@material-ui/icons';
import React from 'react';

import { fullDate, interviewTime } from '../../common/timestamp';
import { BankTransaction } from '../../graphql/queries/notifications';
import { useModal } from '../../hooks/use-modal';
import { useSession } from '../../hooks/use-session';
import { KeyValueInfoList } from '../key-value-info-list';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }
`;

export const BankTransactionNotification: React.FC<{
    bankTransaction: BankTransaction;
    userId: string;
    createdAt: number;
    onComplete: () => void;
}> = ({ bankTransaction, userId, createdAt, onComplete }) => {
    const theme = useTheme();
    const { user } = useSession();
    const { setAlert } = useModal();

    const matchedAmount = bankTransaction.invoices.reduce((acc, invoice) => acc + invoice.amount, 0) ?? 0;

    const handleToggleDetails = () => {
        const memo = [bankTransaction.externalMemo, bankTransaction.bankInternalMemo].filter(Boolean).join('\n');
        const infoList = [
            { key: 'Bank Account', value: bankTransaction.account.name },
            { key: 'Amount', value: `${bankTransaction.amount.toLocaleString()}` },
            {
                key: 'Invoices',
                value: bankTransaction.invoices.map((invoice) => invoice.invoice.invoiceNumber).join(', ')
            },
            {
                key: 'Customers',
                value: bankTransaction.invoices.map((invoice) => invoice.invoice.customer.name).join(', ')
            },
            { key: 'Amount Matched', value: `${matchedAmount.toLocaleString()}` },
            { key: 'Posted At', value: fullDate(bankTransaction.postedAt) },
            { key: 'Memo', value: memo }
        ];

        setAlert('Bank Transaction Details', <KeyValueInfoList data={infoList} />);
    };

    const handleMarkComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onComplete();
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = <Typography variant="h6">Bank Deposit Received</Typography>;
    const matchDescription =
        bankTransaction.amount === matchedAmount
            ? 'Matched - '
            : bankTransaction.invoices.length
              ? 'Partial Match - '
              : 'No Matching Invoices Found';
    const matchCustomer = bankTransaction.invoices.length ? bankTransaction.invoices[0].invoice.customer.name : '';
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            ${bankTransaction.amount.toLocaleString()}
            <br />
            {bankTransaction.account.name}
            <br />
            {matchDescription} {matchCustomer}
            <br />
            {fullDate(bankTransaction.postedAt)}
        </Typography>
    );

    return (
        <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
            <ListItemAvatar>
                <Avatar>
                    <AttachMoney />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(createdAt)}</span>
                </Typography>
            </div>
            {actions}
        </ListItem>
    );
};
