import * as React from 'react';

import { getDisqualReasonLabel } from 'shared/models/job-stages';
import { personVisaOptionLabels } from 'shared/models/person';

import { timeFrom } from '../common/timestamp';
import { config } from '../config';
import { JobStageInfo } from '../containers/job-stage-info';
import { JobTitle } from '../containers/job-title';
import { UserAction as UserActionData } from '../state';
import { User } from './user';
import { UserAvatar } from './user-avatar';

const avatarSize = 24;

const getReasonLabel = (action: UserActionData) => {
    if (action.kind === 'movedJobStage') {
        if (action.context.reason === 'interview-created') {
            return <div className="action-reason">Interview was created</div>;
        } else if (action.context.reason === 'interview-canceled') {
            return <div className="action-reason">Interview was canceled</div>;
        }
    } else if (action.kind === 'removeCandidate') {
        if (action.context.reason === 'search-purged') {
            return <div className="action-reason">Search was Purged</div>;
        } else if (action.context.reason === 'outreach-not-sent') {
            return <div className="action-reason">Outreach did not get sent</div>;
        } else if (action.context.reason) {
            return <div className="action-reason">{action.context.reason}</div>;
        }
    }
    return null;
};

const getReassignReasonLabel = (action: UserActionData) => {
    if (action.kind === 'reassignedCandidate') {
        if (action.context.reason === 'calendar_event_booked') {
            return <div className="action-reason">Interview was created</div>;
        }
    }
    return null;
};

const getActionContent = (action: UserActionData) => {
    switch (action.kind) {
        case 'reassignedCandidate': {
            return (
                <div className="action-description">
                    changed assignee from{' '}
                    <em>
                        <User id={action.context.oldAssigneeId} />
                    </em>{' '}
                    to <br />
                    <em>
                        <User id={action.context.newAssigneeId} />
                    </em>{' '}
                    for <br />
                    <em>
                        <JobTitle id={action.context.jobId} />
                    </em>
                    {getReassignReasonLabel(action)}
                </div>
            );
        }
        case 'addCandidate': {
            const jobId = action.context.jobId;
            const searchId = action.context.searchId;
            const personId = action.target.replace(/persons-/, '');
            const searchLink = searchId ? (
                <div className="action-description">
                    by an{' '}
                    <em>
                        <a href={`${config.AppUrl}/job/${jobId}/search/${searchId}/total/${personId}`} target="_blank">
                            AI search
                        </a>
                    </em>
                </div>
            ) : null;

            return (
                <div className="action-description">
                    added as a candidate for <br />
                    <em>
                        <JobTitle id={jobId} />
                    </em>
                    {searchLink}
                </div>
            );
        }
        case 'addContact':
            return (
                <div className="action-description">
                    added new contact <em>{action.context.value}</em>
                </div>
            );
        case 'deleteContact':
            return (
                <div className="action-description">
                    deleted contact <em>{action.context.value}</em>
                </div>
            );
        case 'addTag':
            return (
                <div className="action-description">
                    added tag <em>{action.context.tag}</em>
                </div>
            );
        case 'removeTag':
            return (
                <div className="action-description">
                    removed tag <em>{action.context.tag}</em>
                </div>
            );
        case 'removeCandidate':
            return (
                <div className="action-description">
                    removed as a candidate for <br />
                    <em>
                        <JobTitle id={action.context.jobId} />
                    </em>
                    {getReasonLabel(action)}
                </div>
            );
        case 'changeJobQualification':
            return (
                <div className="action-description">
                    {action.context.qualify ? 're-qualified' : 'disqualified'} for <br />
                    <em>
                        <JobTitle id={action.context.jobId} />
                    </em>
                    <div className="action-reason">
                        <em>{action.context.qualify ? '' : getDisqualReasonLabel(action.context.reason)}</em>
                    </div>
                </div>
            );
        case 'movedJobStage': {
            const { jobId, initialStage, nextStage } = action.context;
            return (
                <div className="action-description">
                    moved from{' '}
                    <em>
                        <JobStageInfo id={jobId} stage={initialStage} />
                    </em>{' '}
                    to <br />
                    <em>
                        <JobStageInfo id={jobId} stage={nextStage} />
                    </em>{' '}
                    for <br />
                    <em>
                        <JobTitle id={action.context.jobId} />
                    </em>
                    {getReasonLabel(action)}
                </div>
            );
        }
        case 'markInvalidSearchResult': {
            const { jobId, searchId } = action.context;
            if (!searchId) return null;
            return (
                <div className="action-description">
                    marked as <em>no longer a valid search result</em>
                    <br />
                    for this{' '}
                    <a href={`https://titan.getrocket.com/job/${jobId}/search/${searchId}/total`} target="_blank">
                        AI search
                    </a>
                    <em>
                        <JobTitle id={jobId} />
                    </em>
                </div>
            );
        }
        case 'exitSequence': {
            const { jobId } = action.context;
            return (
                <div className="action-description">
                    removed from an email sequence for <br />
                    <em>
                        <JobTitle id={jobId} />
                    </em>
                </div>
            );
        }
        case 'updateContact':
            let context: string;
            if (action.context.attribute === 'contactType') {
                context = ` ${action.context.attrVal} contact type`;
            } else {
                context = `${action.context.attrVal === 'enabled' ? ' ' : ' not'} ${action.context.attribute}
                    ${action.context.channel}`;
            }
            return (
                <div className="action-description">
                    marked <em>{action.context.value}</em> as
                    {context}
                </div>
            );
        case 'updatePerson': {
            if ('visaStatus' in action.context) {
                return action.context.visaStatus ? (
                    <div className="action-description">
                        visa status marked as{' '}
                        <em>{personVisaOptionLabels.get(action.context.visaStatus) || action.context.visaStatus}</em>
                    </div>
                ) : (
                    <div className="action-description">visa status removed</div>
                );
            } else {
                return null;
            }
        }
        case 'sendInmail':
            return (
                <div className="action-description">
                    sent an inmail for <br />
                    <em>
                        <JobTitle id={action.context.jobId} />
                    </em>
                </div>
            );
        default:
            return null;
    }
};

export const UserAction: React.SFC<{ action: UserActionData }> = ({ action }) => {
    const content = getActionContent(action);
    return content ? (
        <div className="action flex-columns-container">
            <div className="action-avatar">
                <UserAvatar id={action.createdBy} size={avatarSize} />
            </div>
            <div className="flex-columns-container flex-fill">
                <div className="flex-fill">
                    <div className="action-header text600">
                        <User id={action.createdBy} />
                    </div>
                    {content}
                </div>
                <div className="action-timestamp secondary">{timeFrom(action.createdAt, true)}</div>
            </div>
        </div>
    ) : null;
};
