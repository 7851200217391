import { useLazyQuery } from '@apollo/client';
import { MenuItem, Select, useTheme } from '@material-ui/core';
import { escapeRegExp, startCase } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import DocumentTitle from 'react-document-title';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import {
    clientFinalRoundStage,
    clientFirstRoundStage,
    clientMiddleRoundStage,
    getDisqualReasonLabel,
    offerStage
} from 'shared/models/job-stages';

import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { SearchTextField } from '../common/search-text-field';
import { JobEdit } from '../containers/job-edit';
import { Candidate, DISQUALIFIED_CANDIDATES, STAGE_CANDIDATES } from '../graphql/queries/candidates';
import { useSlides } from '../hooks/use-candidate-slides';
import { useLocalStorage } from '../hooks/use-local-storage';
import { ClientInvoicesFees } from './client-invoices-fees';
import { dataPanelContainerStyles, selectorStyles } from './data-panel';
import { CellValue, DataPanel, DataPanelColumn } from './data-panel';
import { DurationSelector } from './duration-selector';
import { Header } from './header';
import { HeaderNavigationSelect } from './header-navigation-select';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { NavigationSelect } from './navigation-select';

type View = 'offers' | 'final-rounds' | 'second-rounds' | 'first-rounds';
const views: View[] = ['offers', 'final-rounds', 'second-rounds', 'first-rounds'];
const viewToStageMap: Map<View, string> = new Map([
    ['offers', offerStage],
    ['final-rounds', clientFinalRoundStage],
    ['second-rounds', clientMiddleRoundStage],
    ['first-rounds', clientFirstRoundStage]
]);
const routes = views.map((v) => [`${startCase(v)}`, `/candidates/${v}`] as [string, string]);

type Column =
    | 'client'
    | 'job'
    | 'candidate'
    | 'rating'
    | 'assignee'
    | 'accountManager'
    | 'lastStageChange'
    | 'disqualifiedDate'
    | 'reason';

export const StageCandidates: React.FC<{ stage: string }> = ({ stage }): JSX.Element => {
    const theme = useTheme();
    const { setList } = useSlides();
    const [searchText, setSearchText] = React.useState('');
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'month', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>(`${stage}-job-types`, jobTypesForFilter.get('all'));
    const [dqView, setDqView] = React.useState<0 | 1>(0);

    const [fetchCandidates, { data: stageCandidatesData }] = useLazyQuery<
        { candidates: Candidate[] },
        { jobType: string[]; excludeClientIds: string[]; stages: string[] }
    >(STAGE_CANDIDATES);

    const [fetchDQCandidates, { data: stageDQCandidatesData }] = useLazyQuery<
        { candidates: Candidate[] },
        { jobType: string[]; excludeClientIds: string[]; stages: string[]; startTime: number; endTime: number }
    >(DISQUALIFIED_CANDIDATES);

    React.useEffect(() => {
        if (dqView === 1) {
            fetchDQCandidates({
                variables: {
                    endTime: duration.end,
                    excludeClientIds: internalClientIds,
                    jobType: jobTypes,
                    stages: [stage],
                    startTime: duration.start
                }
            });
        } else {
            fetchCandidates({
                variables: {
                    excludeClientIds: internalClientIds,
                    jobType: jobTypes,
                    stages: [stage]
                }
            });
        }
    }, [stage, duration.start, duration.end, jobTypes, dqView]);

    const handleSelectPerson = (personId: string, jobId: string) => () => {
        const records = dqView === 1 ? stageDQCandidatesData?.candidates : stageCandidatesData?.candidates;
        const list = records?.map((c) => ({
            jobId: c.jobId,
            personId: c.personId
        }));
        const selected = { jobId, personId };
        setList(list, selected);
    };

    const columns: Array<DataPanelColumn<Column>> = React.useMemo(
        () =>
            dqView === 1
                ? [
                      { id: 'client', label: 'Client' },
                      { id: 'job', label: 'Job' },
                      { id: 'candidate', label: 'Candidate' },
                      { id: 'rating', label: 'Rating' },
                      { id: 'assignee', label: 'Assignee' },
                      { id: 'accountManager', label: 'Account Manager' },
                      { id: 'disqualifiedDate', label: 'Disqualified Date' },
                      { id: 'reason', label: 'Reason' }
                  ]
                : [
                      { id: 'client', label: 'Client' },
                      { id: 'job', label: 'Job' },
                      { id: 'candidate', label: 'Candidate' },
                      { id: 'rating', label: 'Rating' },
                      { id: 'assignee', label: 'Assignee' },
                      { id: 'accountManager', label: 'Account Manager' },
                      { id: 'lastStageChange', label: 'Last Stage Change' }
                  ],
        [dqView]
    );

    const getCellValue = (column: Column) => (record: Candidate) => {
        switch (column) {
            case 'client':
                return record.job.client.name;
            case 'job':
                return record.job.title;
            case 'candidate':
                return record.person?.name;
            case 'rating':
                return record.scores?.filter((s) => !s.searchProfilesScoreId)?.[0]?.score;
            case 'assignee':
                return record.assignee.name;
            case 'accountManager':
                return record.accountManager?.name;
            case 'lastStageChange':
                return record.lastStageChangedAt;
            case 'disqualifiedDate':
                return record.disqualifiedAt;
            case 'reason':
                return getDisqualReasonLabel(record.disqualReason);
        }
    };

    const getCellRenderer = {
        candidate: (value: CellValue, record: Candidate) => (
            <span className="clickable-cell" onClick={handleSelectPerson(record.personId, record.jobId)}>
                {value}
            </span>
        ),
        client: (value: CellValue, record: Candidate) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: record.job.client.id, activeTab: 'fees' }}
                url={`/client/${record.job.client.id}/fees`}
            >
                <a href={`/client/${record.job.client.id}/fees`}>{value}</a>
            </PageDialogLink>
        ),
        disqualifiedDate: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        job: (value: CellValue, record: Candidate) => (
            <PageDialogLink Component={JobEdit} componentProps={{ id: record.jobId }} url={`/job/${record.jobId}/edit`}>
                <a href={`/job/${record.jobId}/edit`}>{value}</a>
            </PageDialogLink>
        ),
        lastStageChange: (value: CellValue) => moment(value as number).format('MMM DD, YYYY'),
        rating: (value: CellValue) => (value ? (value as number).toFixed(1) : '-')
    };

    const rowFilter = React.useCallback(
        (record: Candidate) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                record.assignee.name.match(searchRegex) ||
                record.job.client.name.match(searchRegex) ||
                record.job.title.match(searchRegex) ||
                record.person?.name?.match(searchRegex) ||
                record.accountManager?.name?.match(searchRegex)
            );
        },
        [searchText]
    );

    const handleDQViewChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setDqView(event.target.value as 0 | 1);
    };

    const durationSelector =
        dqView === 1 ? <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} /> : null;

    const navigationSelect = <NavigationSelect routes={routes} variant="outlined" />;

    const sortableColumns = ['lastStageChange', 'disqualifiedDate', 'rating', 'assignee', 'accountManager', 'client'];

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    {navigationSelect}
                    <Select value={dqView} onChange={handleDQViewChange} variant="outlined">
                        <MenuItem value={0}>Active</MenuItem>
                        <MenuItem value={1}>Lost</MenuItem>
                    </Select>
                    {durationSelector}
                    <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                </div>
            </div>
            <div className="data-panel">
                <DataPanel
                    columns={columns}
                    data={dqView === 1 ? stageDQCandidatesData?.candidates : stageCandidatesData?.candidates}
                    getCellValue={getCellValue}
                    getCellRenderer={getCellRenderer}
                    sortableColumns={sortableColumns}
                    filterableColumns={['client', 'assignee', 'accountManager']}
                    rowFilter={rowFilter}
                    initialSortColumn={dqView === 1 ? 'disqualifiedDate' : 'lastStageChange'}
                    initialSortDirection="desc"
                />
            </div>
        </div>
    );
};

const StageCandidatesWithRouter: React.FC<RouteComponentProps> = ({ location }) => {
    const pathname = location.pathname.replace(/^\/candidates\//, '');
    const view: View = views.find((v) => pathname === v) ?? 'offers';
    const title = <HeaderNavigationSelect routes={routes} />;
    return (
        <DocumentTitle title={startCase(view)}>
            <div id="container">
                <Header title={title} />
                <div id="content" className="flex-fill">
                    <StageCandidates stage={viewToStageMap.get(view)} />
                </div>
            </div>
        </DocumentTitle>
    );
};

export const Candidates = withRouter(StageCandidatesWithRouter);
