import { gql } from '@apollo/client';
import { InvoiceStatus } from 'shared/models/invoice';

export interface BankTransaction {
    id: string;
    permaLink: string;
    externalMemo: string;
    bankInternalMemo: string;
    amount: number;
    postedAt: number;
    ignoreExcessAmount: boolean;
    account: {
        id: string;
        name: string;
    };
}

export interface BankTransactionWithInvoices extends BankTransaction {
    invoices: Array<{
        id: string;
        amount: number;
        invoice: {
            id: string;
            invoiceNumber: string;
            pdf: string;
            lineItems: InvoiceLineItem[];
        };
    }>;
}

export interface InvoiceLineItem {
    id: string;
    description: string;
    amount: number;
    price: number;
    quantity: number;
    feeId: string;
    refunded: boolean;
}

export interface Invoice {
    id: string;
    invoiceNumber: string;
    invoiceDate: number;
    dueDate: number;
    status: InvoiceStatus;
    pdf: string;
    totalAmount: number;
    unpaidAmount?: number;
    bankTransactions: Array<{
        id: string;
        amount: number;
        transaction: BankTransaction;
    }>;
    customer: {
        id: string;
        name: string;
        contact: { firstName: string; lastName: string };
        email: string;
    };
    lineItems: InvoiceLineItem[];
    payments?: Array<{
        id: string;
        amount: number;
        date: number;
    }>;
}

const accountingInvoiceFragment = gql`
    fragment AccountingInvoiceFragment on invoices {
        id
        invoiceNumber
        invoiceDate
        dueDate
        status
        pdf
        totalAmount
        unpaidAmount
        customer {
            id
            name
            contact
            email
        }
        lineItems {
            id
            description
            amount
            price
            quantity
            feeId
            refunded
        }
        bankTransactions {
            id
            amount
            transaction {
                id
                permaLink
                externalMemo
                bankInternalMemo
                amount
                postedAt
                account {
                    id
                    name
                }
            }
        }
    }
`;

export const GET_INVOICES = gql`
    ${accountingInvoiceFragment}
    query GetInvoices($startTime: bigint!, $endTime: bigint!, $isStaffingCustomer: [Boolean!]!) {
        invoices(
            where: {
                archived: { _eq: false }
                invoiceDate: { _gte: $startTime, _lte: $endTime }
                lineItems: { refunded: { _neq: true } }
                customer: { isStaffingCustomer: { _in: $isStaffingCustomer } }
            }
            order_by: { invoiceDate: desc }
        ) {
            ...AccountingInvoiceFragment
        }
    }
`;

export const GET_UNPAID_INVOICES = gql`
    ${accountingInvoiceFragment}
    query GetUnpaidInvoices($startTime: bigint!, $endTime: bigint!) {
        invoices(
            where: {
                archived: { _eq: false }
                unpaidAmount: { _gt: 0 }
                lineItems: { refunded: { _neq: true } }
                invoiceDate: { _gte: $startTime, _lte: $endTime }
            }
            order_by: { invoiceDate: desc }
        ) {
            ...AccountingInvoiceFragment
            payments {
                id
                amount
                date
            }
        }
    }
`;

export const GET_BANK_TRANSACTIONS = gql`
    query GetBankTransactions($startTime: bigint!, $endTime: bigint!) {
        bankTransactions: bank_transactions(
            where: { postedAt: { _gte: $startTime, _lte: $endTime } }
            order_by: { postedAt: desc }
        ) {
            id
            permaLink
            externalMemo
            bankInternalMemo
            amount
            postedAt
            ignoreExcessAmount
            account {
                id
                name
            }
            invoices(where: { invoice: { id: { _is_null: false } } }) {
                id
                amount
                invoice {
                    id
                    invoiceNumber
                    pdf
                    lineItems {
                        id
                        description
                        amount
                        price
                        quantity
                        feeId
                        refunded
                    }
                }
            }
        }
    }
`;

export const SET_TRANSACTION_IGNORED = gql`
    mutation SetTransactionIgnored($transactionId: String!, $ignored: Boolean!) {
        update_bank_transactions(where: { id: { _eq: $transactionId } }, _set: { ignored: $ignored }) {
            affected_rows
        }
    }
`;

export const ASSIGN_INVOICES_TO_TRANSACTION = gql`
    mutation AssignInvoicesToTransaction($records: [invoice_bank_transactions_insert_input!]!) {
        insert_invoice_bank_transactions(objects: $records) {
            affected_rows
        }
    }
`;

export const DELETE_BANK_TRANSACTION_INVOICES = gql`
    mutation DeleteBankTransactionInvoices($invoiceIds: [String!]!, $bankTransactionId: String!) {
        delete_invoice_bank_transactions(
            where: { invoiceId: { _in: $invoiceIds }, bankTransactionId: { _eq: $bankTransactionId } }
        ) {
            affected_rows
        }
    }
`;
