// cspell:ignore Ondemand
import { css } from '@emotion/core';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { Check, Error, OndemandVideo, Subject } from '@material-ui/icons';
import { orderBy } from 'lodash';
import React from 'react';
import { convertToHTML } from 'shared/common/text-to-html';
import { hasRole } from 'shared/models/user';
import { reprocessRecording } from '../api';

import { pageBgColor } from '../common/css-variables';
import { DurationProgress } from '../common/duration-progress';
import { fullDateTime } from '../common/timestamp';
import { Meeting } from '../graphql/queries/clients';
import { useModal } from '../hooks/use-modal';
import { usePromptInfo } from '../hooks/use-prompt-info';
import { useSession } from '../hooks/use-session';

const styles = (theme: Theme) => css`
    overflow: auto;
    padding: 25px 50px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    background: ${pageBgColor};

    .meetings {
        width: 960px;
        margin: 0 auto;
        background: white;
        border: thin solid ${theme.palette.divider};
        border-radius: ${theme.shape.borderRadius}px;

        .secondary-text-span {
            line-height: 22px;
        }

        .MuiListItem-container {
            border-bottom: thin solid ${theme.palette.divider};

            .MuiListItemSecondaryAction-root {
                .MuiIconButton-root {
                    opacity: 0;
                    transition: opacity 0.2s;

                    &.visible {
                        opacity: 1;
                    }
                }
            }

            &:hover {
                background: ${theme.palette.action.hover};

                .MuiListItemSecondaryAction-root {
                    .MuiIconButton-root {
                        opacity: 1;
                    }
                }
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }
`;

const expectedDurationSeconds = 180;

export const Meetings: React.FC<{ meetings: Meeting[]; jobIds: string[]; refetch: () => Promise<any> }> = ({
    meetings,
    jobIds,
    refetch
}) => {
    const theme = useTheme();
    const { userPermissions } = useSession();
    const { showPromptInfo } = usePromptInfo();
    const { getConfirmation, setAlert } = useModal();
    const [processing, setProcessing] = React.useState(false);
    const [summaryOpen, setSummaryOpen] = React.useState<Meeting | null>(null);

    const handleSummaryClick = (meeting: Meeting) => () => {
        setSummaryOpen(meeting);
    };

    const handleCloseSummary = () => {
        setSummaryOpen(null);
    };

    const handleShowPromptInfo = () => {
        showPromptInfo({
            inferenceId: summaryOpen?.summaryGen?.inferenceId,
            resultsFile: summaryOpen?.summaryGen?.resultFile
        });
    };

    const reprocessMeeting = async (meeting: Meeting) => {
        setProcessing(true);
        try {
            await reprocessRecording(meeting.id, !!meeting.processedAt, jobIds);
            refetch();
        } catch {
            setAlert(
                'Error processing meeting',
                'An error occurred while processing the meeting. Please try again later.'
            );
        }
        setProcessing(false);
    };

    const handleProcessMeeting = (meeting: Meeting) => (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        const confirmationText = meeting.processedAt
            ? 'Are you sure you want to reprocess this meeting? This will use AI to update the relevant data in the Job and the Client fields.'
            : 'Are you sure you want to process this meeting? This will use AI to update the relevant data in the Job and the Client fields.';
        const confirmationTitle = meeting.processedAt ? 'Reprocess Meeting' : 'Process Meeting';
        getConfirmation(() => reprocessMeeting(meeting), confirmationText, confirmationTitle);
    };

    const meetingsList = meetings?.map((meeting) => {
        const processedInfo = meeting.processedAt ? (
            <span>
                Processed at: {fullDateTime(meeting.processedAt)}
                <br />
                Click to reprocess
            </span>
        ) : (
            <span>
                Not Processed
                <br /> Click to process
            </span>
        );
        const participants = orderBy(
            meeting.participants,
            [(p) => (!p.email ? 0 : 1), (p) => (p.email?.match(/@getrocket.com/i) ? 0 : 1), (p) => p.name ?? p.email],
            ['desc', 'desc', 'asc']
        )
            ?.map((p) => p.name ?? p.email)
            .join(', ');
        const jobs = orderBy(meeting.jobs, [(j) => (jobIds.includes(j.id) ? 1 : 0)], ['desc'])
            .map((j) => j.title)
            .join(', ');
        const secondaryText = (
            <span className="secondary-text-span">
                {meeting.duration} minutes
                <br />
                {participants}
                <br />
                {jobs}
            </span>
        );
        return (
            <ListItem key={meeting.id} button={true} onClick={handleSummaryClick(meeting)}>
                <ListItemText primary={fullDateTime(meeting.recordedAt)} secondary={secondaryText} />
                <ListItemSecondaryAction>
                    <Tooltip title={meeting.summary ? 'View Summary' : 'Summary not available'}>
                        <span>
                            <IconButton disabled={!meeting.summary} onClick={handleSummaryClick(meeting)}>
                                <Subject />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={meeting.link ? 'View Recording' : 'Recording not available'}>
                        <span>
                            <IconButton href={meeting.link} target="_blank" disabled={!meeting.link}>
                                <OndemandVideo />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title={processedInfo}>
                        <IconButton
                            onClick={handleProcessMeeting(meeting)}
                            className={meeting.processedAt ? '' : 'visible'}
                        >
                            {meeting.processedAt ? <Check /> : <Error />}
                        </IconButton>
                    </Tooltip>
                </ListItemSecondaryAction>
            </ListItem>
        );
    });

    const noMeetings =
        meetings?.length === 0 ? (
            <ListItem>
                <ListItemText primary="No Meetings" secondary="No recorded meetings available" />
            </ListItem>
        ) : null;

    const loadingMeetings = !meetings ? <LinearProgress variant="indeterminate" /> : null;

    const showPromptInfoButton = hasRole(userPermissions, 'prompts_editor') ? (
        <Button onClick={handleShowPromptInfo}>Show Prompt Info</Button>
    ) : null;

    const summaryDialog = summaryOpen ? (
        <Dialog open={true} onClose={handleCloseSummary} fullWidth={true} maxWidth="md">
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Meeting Summary
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div dangerouslySetInnerHTML={{ __html: convertToHTML(summaryOpen.summary) }} />
            </DialogContent>
            <DialogActions>
                {showPromptInfoButton}
                <Button onClick={handleCloseSummary}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    const processingIndicator = processing ? (
        <Dialog open={true} maxWidth="sm" fullWidth={true}>
            <DialogContent style={{ padding: '24px' }}>
                <DurationProgress duration={expectedDurationSeconds} />
            </DialogContent>
        </Dialog>
    ) : null;

    return (
        <div css={styles(theme)}>
            <List className="meetings">
                {meetingsList}
                {noMeetings}
                {loadingMeetings}
            </List>
            {summaryDialog}
            {processingIndicator}
        </div>
    );
};
