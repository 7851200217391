import { css } from '@emotion/core';
import {
    Button,
    Checkbox,
    Chip,
    Collapse,
    Divider,
    ListItemText,
    MenuItem,
    TextField,
    Theme,
    Typography,
    useTheme
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Autocomplete, FilterOptionsState, GetTagProps, RenderInputParams } from '@material-ui/lab';
import { escapeRegExp, flatten, startCase } from 'lodash';

import React from 'react';
import { personVisaOptionLabels, VisaStatus } from 'shared/models/person';
import {
    currentCompanyOptions,
    diversityOptions,
    Filters,
    minYearsExpOptions,
    progressOptions,
    schoolRankOptions,
    timeWindowOptions
} from 'shared/types/candidates-search';
import { idealGroups, jobDisciplines } from '../common/job-disciplines';
import { rocketLocations } from '../common/mapping/location';
import { presetPersonTags } from '../common/preset-person-tags';
import { Client } from '../graphql/queries/clients';
import { User } from '../graphql/queries/user';
import { renderPersonTagOptions } from './person-tags';

const styles = (theme: Theme) => css`
    width: 360px;
    overflow-x: hidden;

    .title {
        padding: 17px 20px 18px;
        border-bottom: thin solid ${theme.palette.divider};
    }

    .filters-content {
        padding: 20px;
    }

    .filter-field {
        margin: 20px 0 40px;
    }

    .buttons {
        text-align: right;
    }

    .chips-list {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding-top: 14px;
    }

    .chips-input {
        display: flex;
        flex-wrap: wrap;
    }

    .filters-section-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        text-transform: uppercase;
        cursor: pointer;
        margin-bottom: 20px;
        font-weight: 600;
        padding-left: 5px;

        hr {
            margin: 0 5px 0 8px;
            flex: 1 1 auto;
        }
    }

    .actions {
        text-align: right;
    }
`;

interface Sections {
    jobData: boolean;
    recruiterData: boolean;
    profileData: boolean;
}

interface CandidatesSearchFiltersProps {
    clients: Client[];
    users: User[];
    filters: Filters;
    onChange: (filter: Partial<Filters>) => void;
    onSearch: () => void;
    jobs: { ids: string[]; titles: { [id: string]: { label: JSX.Element; text: string } } };
}

export const CandidatesSearchFilters: React.FC<CandidatesSearchFiltersProps> = ({
    clients,
    filters,
    onChange,
    users,
    onSearch,
    jobs
}) => {
    const theme = useTheme();
    const [keyword, setKeyword] = React.useState('');
    const [expanded, setExpanded] = React.useState<Sections>({ jobData: true, recruiterData: true, profileData: true });

    const handleToggleExpanded = (field: keyof Sections) => () =>
        setExpanded({ ...expanded, [field]: !expanded[field] });

    const handleFieldChange =
        <T extends keyof Filters>(field: T) =>
        (event: React.ChangeEvent<{ value: any }>) => {
            if (field === 'disciplines') {
                const map = flatten((event.target.value as string[]).map((d) => idealGroups[d]));
                const profileGroup = filters.profileGroup.filter((t) => map.indexOf(t) !== -1);
                onChange({ ...filters, profileGroup, disciplines: event.target.value as string[] });
            } else {
                onChange({ ...filters, [field]: event.target.value as Filters[T] });
            }
        };

    const handleAutoCompleteChange =
        <T extends keyof Filters>(field: T) =>
        (_1: React.ChangeEvent<{}>, value: string[]) => {
            onChange({ ...filters, [field]: value });
        };

    const handleTextChange = (event: React.ChangeEvent<{ value: any }>) => {
        setKeyword(event.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && keyword.trim()) {
            if (filters.keywords.indexOf(keyword.trim()) === -1) {
                onChange({ ...filters, keywords: filters.keywords.concat(keyword.trim()) });
            }
            setKeyword('');
        }
        if (e.key === 'Backspace') {
            if (keyword.length === 0) {
                onChange({ ...filters, keywords: filters.keywords.slice(0, -1) });
            }
        }
    };

    const handleChipRemove = (index: number) => () => {
        onChange({
            ...filters,
            keywords: filters.keywords.slice(0, index).concat(filters.keywords.slice(index + 1))
        });
    };

    const renderValue =
        <T extends keyof Filters>(field: T) =>
        (values: string[]) => {
            const suffix =
                field === 'assignees'
                    ? 'Assignees'
                    : field === 'disciplines'
                      ? 'Disciplines'
                      : field === 'visas'
                        ? 'Visa Statuses'
                        : field === 'profileGroup'
                          ? 'Profile Groups'
                          : field === 'excludedClients'
                            ? 'Excluded Clients'
                            : 'Values';
            if (values.length === 1) {
                return field === 'visas'
                    ? personVisaOptionLabels.get(values[0] as VisaStatus)
                    : field === 'excludedClients'
                      ? clients.filter((f) => f.id === values[0])[0].name
                      : values[0];
            } else {
                return `${values.length} ${suffix}`;
            }
        };

    const assigneeOpts = users?.map((user) => (
        <MenuItem key={user.id} value={user.id}>
            <Checkbox checked={filters.assignees.indexOf(user.id) !== -1} />
            <ListItemText primary={user.name.full} />
        </MenuItem>
    ));

    const disciplineMenuOpts = jobDisciplines.map((o) => (
        <MenuItem value={o} key={o}>
            <Checkbox checked={filters.disciplines.indexOf(o) !== -1} />
            <ListItemText primary={startCase(o)} />
        </MenuItem>
    ));

    const visaOpts = personVisaOptionLabels
        .keySeq()
        .toArray()
        .map((o) => (
            <MenuItem value={o} key={o}>
                <Checkbox checked={filters.visas.indexOf(o) !== -1} />
                <ListItemText primary={personVisaOptionLabels.get(o)} />
            </MenuItem>
        ));

    const timeWindowOpts = timeWindowOptions.map((o) => (
        <MenuItem value={o} key={o}>
            {o}
        </MenuItem>
    ));

    const progressOpts = progressOptions.map((o) => (
        <MenuItem value={o} key={o}>
            {o}
        </MenuItem>
    ));

    const schoolRankOpts = schoolRankOptions.map((o) => (
        <MenuItem key={o} value={o}>
            {o}
        </MenuItem>
    ));

    const minYearsExpOpts = minYearsExpOptions.map((o) => (
        <MenuItem key={o} value={o}>
            {o}
        </MenuItem>
    ));

    const profileGroups =
        filters.disciplines.length > 0 ? flatten(filters.disciplines.map((discipline) => idealGroups[discipline])) : [];

    const profileGroupOpts = profileGroups?.map((o) => (
        <MenuItem value={o} key={o}>
            <Checkbox checked={filters.profileGroup.indexOf(o) !== -1} />
            <ListItemText primary={startCase(o)} />
        </MenuItem>
    ));

    const getJobTitle = (id: string) => jobs.titles[id].label;
    const jobFilterOptions = (options: string[], state: FilterOptionsState<string>) =>
        options.filter((id) => jobs.titles[id].text.match(new RegExp(escapeRegExp(state.inputValue), 'i')));
    const renderJobTags = (ids: string[], getTagProps: GetTagProps) =>
        ids.map((id, index) => <Chip key={index} label={jobs.titles[id].label} {...getTagProps({ index })} />);

    const keywordChips = filters.keywords.map((t, i) => <Chip key={i} label={t} onDelete={handleChipRemove(i)} />);
    const keywordAdornment = filters.keywords.length > 0 ? <div className="chips-list">{keywordChips}</div> : null;

    const renderInput = (label: string) => (params: RenderInputParams) => (
        <TextField {...params} variant="outlined" label={label} fullWidth={true} />
    );

    const hideProgress = filters.timeWindow.length > 0 ? false : true;
    const disableProfileGroup = filters.disciplines.length > 0 ? false : true;

    const renderTags = (tags: string[], getTagProps: GetTagProps) =>
        tags.map((tag, index) => <Chip key={index} label={renderPersonTagOptions(tag)} {...getTagProps({ index })} />);

    const renderExcludedClientTags = (ids: string[], getTagProps: GetTagProps) =>
        ids.map((id, index) => (
            <Chip key={id} label={clients.filter((f) => f.id === id)[0].name} {...getTagProps({ index })} />
        ));

    const getExcludedClientOptions = (option: string) => clients.filter((f) => f.id === option)[0].name;

    return (
        <div css={styles(theme)}>
            <div className="title">
                <Typography variant="h3">Filters</Typography>
            </div>
            <div className="filters-content">
                <div className="filters-section-title" onClick={handleToggleExpanded('jobData')}>
                    <div>Job Activity Data</div>
                    <Divider />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </div>
                <Collapse in={expanded.jobData}>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            options={jobs.ids}
                            renderOption={getJobTitle}
                            renderTags={renderJobTags}
                            filterOptions={jobFilterOptions}
                            value={filters.specificJobs}
                            onChange={handleAutoCompleteChange('specificJobs')}
                            renderInput={renderInput('Specific Jobs')}
                        />
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.timeWindow ?? ''}
                            variant="outlined"
                            label="Activity Time Window"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('timeWindow')}
                        >
                            {timeWindowOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.progress ?? ''}
                            variant="outlined"
                            label="Candidate Progress"
                            fullWidth={true}
                            select={true}
                            hidden={hideProgress}
                            onChange={handleFieldChange('progress')}
                        >
                            {progressOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.assignees ?? []}
                            variant="outlined"
                            label="Assignee"
                            fullWidth={true}
                            select={true}
                            hidden={hideProgress}
                            onChange={handleFieldChange('assignees')}
                            SelectProps={{ multiple: true, renderValue: renderValue('assignees') }}
                        >
                            {assigneeOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            options={clients.map((c) => c.id)}
                            getOptionLabel={getExcludedClientOptions}
                            renderTags={renderExcludedClientTags}
                            value={filters.excludedClients}
                            onChange={handleAutoCompleteChange('excludedClients')}
                            renderInput={renderInput('Exclude Candidates Sent to Clients')}
                        />
                    </div>
                </Collapse>
                <div className="filters-section-title" onClick={handleToggleExpanded('recruiterData')}>
                    <div>Recruiting Data</div>
                    <Divider />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </div>
                <Collapse in={expanded.recruiterData}>
                    <div className="filter-field">
                        <TextField
                            value={Number(filters.favoritesOnly ?? false)}
                            variant="outlined"
                            label="Favorites Only"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('favoritesOnly')}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            freeSolo={true}
                            options={presetPersonTags}
                            value={filters.tags}
                            onChange={handleAutoCompleteChange('tags')}
                            renderInput={renderInput('Tags')}
                            renderOption={renderPersonTagOptions}
                            renderTags={renderTags}
                        />
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={Number(filters.blacklistedOnly ?? false)}
                            variant="outlined"
                            label="Blacklisted Only"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('blacklistedOnly')}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </TextField>
                    </div>
                </Collapse>
                <div className="filters-section-title" onClick={handleToggleExpanded('profileData')}>
                    <div>Profile Data</div>
                    <Divider />
                    {expanded ? <ExpandLess /> : <ExpandMore />}
                </div>
                <Collapse in={expanded.profileData}>
                    <div className="filter-field">
                        <TextField
                            value={filters.visas ?? []}
                            variant="outlined"
                            label="Visa Status"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('visas')}
                            SelectProps={{ multiple: true, renderValue: renderValue('visas') }}
                        >
                            {visaOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.disciplines || []}
                            variant="outlined"
                            label="Discipline"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('disciplines')}
                            SelectProps={{ multiple: true, renderValue: renderValue('disciplines') }}
                        >
                            {disciplineMenuOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.profileGroup}
                            variant="outlined"
                            label="Profile Group"
                            fullWidth={true}
                            select={true}
                            disabled={disableProfileGroup}
                            onChange={handleFieldChange('profileGroup')}
                            SelectProps={{ multiple: true, renderValue: renderValue('profileGroup') }}
                        >
                            {profileGroupOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            freeSolo={false}
                            options={diversityOptions}
                            value={filters.diversity}
                            onChange={handleAutoCompleteChange('diversity')}
                            renderInput={renderInput('Diversity')}
                        />
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={Number(filters.csDegreeOnly ?? false)}
                            variant="outlined"
                            label="CS Degree Only"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('csDegreeOnly')}
                        >
                            <MenuItem value={1}>Yes</MenuItem>
                            <MenuItem value={0}>No</MenuItem>
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.schoolRank ?? ''}
                            variant="outlined"
                            label="School Rank"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('schoolRank')}
                        >
                            {schoolRankOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            freeSolo={true}
                            options={rocketLocations}
                            value={filters.locations}
                            onChange={handleAutoCompleteChange('locations')}
                            renderInput={renderInput('Locations')}
                        />
                    </div>
                    <div className="filter-field">
                        <Autocomplete
                            multiple={true}
                            filterSelectedOptions={true}
                            freeSolo={true}
                            options={currentCompanyOptions}
                            value={filters.currentCompany}
                            onChange={handleAutoCompleteChange('currentCompany')}
                            renderInput={renderInput('Current Company')}
                        />
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={filters.minYearsExp}
                            variant="outlined"
                            label="Min Years of Experience"
                            fullWidth={true}
                            select={true}
                            onChange={handleFieldChange('minYearsExp')}
                        >
                            {minYearsExpOpts}
                        </TextField>
                    </div>
                    <div className="filter-field">
                        <TextField
                            value={keyword}
                            variant="outlined"
                            label="Profile Keywords"
                            fullWidth={true}
                            onChange={handleTextChange}
                            onKeyDown={handleKeyDown}
                            InputProps={{ className: 'chips-input', startAdornment: keywordAdornment }}
                        />
                    </div>
                </Collapse>
                <div className="actions">
                    <Button key="search" variant="outlined" onClick={onSearch}>
                        Search
                    </Button>
                </div>
            </div>
        </div>
    );
};
