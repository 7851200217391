import { css } from '@emotion/core';
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { CheckCircleOutline, LocalAtm } from '@material-ui/icons';
import React, { useState } from 'react';

import { fullDate } from '../../common/timestamp';
import { Commission } from '../../graphql/queries/commissions';
import { useSession } from '../../hooks/use-session';
import { Column, CommissionsDataPanel } from '../accounting/commissions-data-panel';
import { dataPanelDialogStyles } from '../data-panel';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    ${notificationStyles(theme)}

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }
`;

const columns: Column[] = ['amount', 'incurredAt', 'dueAt', 'commissionType', 'client', 'job', 'candidate'];

export const CommissionProcessedNotification: React.FC<{
    commissions: Commission[];
    userId: string;
    processedAt: number;
    onComplete: () => void;
}> = ({ commissions, userId, processedAt, onComplete }) => {
    const theme = useTheme();
    const { user } = useSession();
    const [open, setOpen] = useState(false);

    const handleToggleDetails = () => {
        setOpen(!open);
    };

    const handleMarkComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onComplete();
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = <Typography variant="h6">Commissions Paid Out</Typography>;
    const totalAmount = commissions.map((c) => c.amount).reduce((a, b) => a + b, 0);
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            ${totalAmount.toLocaleString()}
            <br />
            {commissions.length} commissions were paid out
        </Typography>
    );

    const dialog = open ? (
        <Dialog
            open={true}
            onClose={handleToggleDetails}
            fullWidth={true}
            maxWidth="lg"
            css={dataPanelDialogStyles(theme)}
        >
            <DialogTitle>
                <Typography variant="h4" component="div">
                    Commissions - {fullDate(processedAt)}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <CommissionsDataPanel columns={columns} commissions={commissions} />
            </DialogContent>
            <DialogActions>
                <Button href="/commissions">View All Commissions</Button>
                <Button onClick={handleToggleDetails}>Close</Button>
            </DialogActions>
        </Dialog>
    ) : null;

    return (
        <ListItem css={styles(theme)} onClick={handleToggleDetails} button={true}>
            <ListItemAvatar>
                <Avatar>
                    <LocalAtm />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{fullDate(processedAt)}</span>
                </Typography>
            </div>
            {actions}
            {dialog}
        </ListItem>
    );
};
