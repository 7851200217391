import { css } from '@emotion/core';
import {
    Avatar,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Theme,
    Tooltip,
    Typography,
    useTheme
} from '@material-ui/core';
import { ArrowForward, AttachMoney, CheckCircleOutline } from '@material-ui/icons';
import { startCase } from 'lodash';
import React from 'react';
import { InvoiceStatus } from 'shared/models/invoice';

import { fullDate, interviewTime } from '../../common/timestamp';
import { FileDownloadLink } from '../../core-ui/file-download-link';
import { Invoice } from '../../graphql/queries/notifications';
import { useSession } from '../../hooks/use-session';
import { notificationStyles } from './notification-styles';

const styles = (theme: Theme) => css`
    .file-download-link {
        display: block;
    }

    .MuiListItem-root {
        ${notificationStyles(theme)}
    }

    .item-actions {
        display: flex;
        position: absolute;
        bottom: 10px;
        right: 12px;
        opacity: 0;

        .MuiIconButton-root {
            margin-left: 5px;
        }
    }

    &:hover {
        background: rgba(0, 0, 0, 0.025);
        .item-actions {
            opacity: 1;
        }
    }

    .change {
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
            margin: 0 5px;
            font-size: 1rem;
        }
    }
`;

export const InvoiceStatusChangeNotification: React.FC<{
    invoice: Invoice;
    oldStatus: InvoiceStatus;
    newStatus: InvoiceStatus;
    userId: string;
    createdAt: number;
    onComplete: () => void;
}> = ({ invoice, oldStatus, newStatus, userId, createdAt, onComplete }) => {
    const theme = useTheme();
    const { user } = useSession();

    const handleMarkComplete = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onComplete();
    };

    const actions =
        user.id === userId ? (
            <div className="item-actions">
                <Tooltip title="OK">
                    <IconButton size="small" onClick={handleMarkComplete}>
                        <CheckCircleOutline fontSize="small" />
                    </IconButton>
                </Tooltip>
            </div>
        ) : null;

    const primary = <Typography variant="h6">Invoice Status Changed</Typography>;
    const change = (
        <div className="change">
            {startCase(oldStatus.toLowerCase())} <ArrowForward /> {startCase(newStatus.toLowerCase())}
        </div>
    );
    const postNotificationChange =
        invoice.status !== newStatus ? (
            <div className="change">
                Latest Status: <b>{startCase(invoice.status.toLowerCase())}</b>
            </div>
        ) : null;
    const secondary = (
        <Typography variant="body2" color="textSecondary" component="div">
            {invoice.invoiceNumber} ({fullDate(invoice.invoiceDate)})
            <br />
            {invoice.customer.name}
            <br />
            {change}
            {fullDate(createdAt)}
            <br />
            {postNotificationChange}
        </Typography>
    );

    const listItem = (
        <ListItem button={true}>
            <ListItemAvatar>
                <Avatar>
                    <AttachMoney />
                </Avatar>
            </ListItemAvatar>
            <ListItemText disableTypography={true} primary={primary} secondary={secondary} />
            <div>
                <Typography color="textSecondary" noWrap={true} variant="caption" className="caption" component="div">
                    <span className="left-pad">{interviewTime(createdAt)}</span>
                </Typography>
            </div>
            {actions}
        </ListItem>
    );

    const container = invoice.pdf ? (
        <FileDownloadLink path={invoice.pdf} filename={invoice.pdf.split('/')?.pop()}>
            {listItem}
        </FileDownloadLink>
    ) : (
        listItem
    );

    return <div css={styles(theme)}>{container}</div>;
};
