import { gql, useQuery } from '@apollo/client';

import { ModelParameters } from 'shared/common/llm';

// subset of runtime constants server/src/common/runtime-constants.ts
interface ConstantsTypes {
    internalClientIds: string[];
    maxSearchResultsToScoreUsingLLM: number;
    profileScoringModelOptions: ModelParameters[];
    profileScoringDefaultModelParameters: ModelParameters;
}

const GET_CONSTANTS = gql`
    query GetGlobalConstants($names: [String!]!) {
        constants: global_constants(where: { name: { _in: $names } }) {
            name
            value
        }
    }
`;

// Define the useConstants hook with type enforcement
const useConstants = () => {
    const getConstant = <T extends keyof ConstantsTypes>(name: T): ConstantsTypes[T] => {
        // Use useQuery hook to fetch constant based on name
        const { data } = useQuery(GET_CONSTANTS, {
            variables: { names: [name] }
        });

        // Return and constant value
        return data?.constants.find((c: any) => c.name === name)?.value;
    };

    const getConstants = <T extends keyof ConstantsTypes>(names: T[]): { [K in T]: ConstantsTypes[K] } => {
        // Use useQuery hook to fetch multiple constants
        const { data } = useQuery(GET_CONSTANTS, {
            variables: { names }
        });

        // Return loading, error, and constants values as a dictionary
        const constants = names.reduce(
            (acc, name) => {
                acc[name] = data?.constants.find((c: any) => c.name === name)?.value;
                return acc;
            },
            {} as { [K in T]: ConstantsTypes[K] }
        );

        return constants;
    };

    return { getConstant, getConstants };
};

export default useConstants;
