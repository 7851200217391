import { useMutation } from '@apollo/client';
import { css } from '@emotion/core';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Typography
} from '@material-ui/core';
import { escapeRegExp, orderBy } from 'lodash';
import React from 'react';

import { Company } from '../graphql/queries/clients';
import { CREATE_COMPANY_TAG, UPDATE_COMPANY_TAGS } from '../graphql/queries/companies';
import { useSession } from '../hooks/use-session';
import { useSnackbar } from '../hooks/use-snackbar';

const dialogStyle = css`
    .MuiDialogContent-root {
        padding-top: 20px;
        padding-bottom: 20px;
    }
`;

const defaultExpanded = 10;

export const CompanyTags: React.FC<{ company: Company; searchText: string }> = ({ company, searchText }) => {
    const chipsRef = React.useRef<HTMLSpanElement>(null);
    const [hasExpanded, setHasExpanded] = React.useState<boolean>(false);
    const [tags, setTags] = React.useState<string[]>([]);
    const [adding, setAdding] = React.useState<boolean>(false);
    const [tagInput, setTagInput] = React.useState<string>('');
    const [expanded, setExpanded] = React.useState<number>(0);
    const [updateTag] = useMutation<{}, { companyId: string; tag: string; userId: string; deleted: boolean }>(
        UPDATE_COMPANY_TAGS
    );
    const [createTag, { loading }] = useMutation<{}, { companyId: string; tag: string }>(CREATE_COMPANY_TAG);
    const { setSnackbar } = useSnackbar();
    const { user } = useSession();

    React.useEffect(() => {
        const uniqueTags: Set<string> = new Set();
        company?.tags?.forEach((tag) => {
            if (!uniqueTags.has(tag.tag)) {
                uniqueTags.add(tag.tag);
            }
        });
        setTags(Array.from(uniqueTags).sort());
    }, [company]);

    React.useEffect(() => {
        if (expanded === 0 && hasExpanded && chipsRef.current) {
            chipsRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [expanded]);

    const handleDelete = (tag: string) => () => {
        setTags(tags.filter((t) => t !== tag));
        updateTag({ variables: { companyId: company.id, tag, userId: user.id, deleted: true } });
        const undoDelete = () => {
            setTags(tags);
            setSnackbar('Tag restored');
            updateTag({ variables: { companyId: company.id, tag, userId: null, deleted: false } });
        };
        setSnackbar(
            'Tag removed',
            <Button style={{ color: 'white' }} onClick={undoDelete}>
                Undo
            </Button>
        );
    };

    const handleExpand = () => {
        expanded === 0 ? setExpanded(defaultExpanded) : setExpanded(tags.length);
        setHasExpanded(true);
    };
    const handleCollapse = () => setExpanded(0);

    const handleAddClick = () => {
        setAdding(true);
    };

    const handleCancelAdd = () => setAdding(false);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => setTagInput(e.target.value);

    const handleCreate = async () => {
        const value = tagInput?.trim();
        if (value) {
            setSnackbar('Adding tag...');
            await createTag({ variables: { companyId: company.id, tag: value } });
            setTags([...tags, value].sort());
            setTagInput('');
            setAdding(false);
            setSnackbar('Tag added');
        }
    };

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            handleCreate();
        }
    };

    const searchRegex = searchText ? new RegExp(escapeRegExp(searchText), 'i') : undefined;
    const sortedTags = searchText ? orderBy(tags, [(v) => v?.match(searchRegex), (v) => v], ['asc', 'asc']) : tags;
    const viewedTags = sortedTags.slice(0, expanded);

    const list = viewedTags.map((tag) => (
        <Chip variant="outlined" key={tag} className="chip" label={tag} onDelete={handleDelete(tag)} />
    ));
    const addButton =
        expanded > 0 || tags.length === 0 ? (
            <Chip variant="outlined" key="add" className="chip add-chip" label="Add" onClick={handleAddClick} />
        ) : null;
    const hideButton =
        expanded > 0 ? (
            <Chip variant="outlined" key="hide" className="chip" label="Close" onClick={handleCollapse} />
        ) : null;

    const expandAllButton =
        sortedTags.length > expanded ? (
            <Chip
                variant="outlined"
                key="expand"
                className="chip add-chip"
                label={`${sortedTags.length - expanded} ${expanded > 0 ? 'more' : 'tags'}`}
                onClick={handleExpand}
            />
        ) : null;

    const addForm = (
        <Dialog css={dialogStyle} open={adding} onClose={handleCancelAdd} maxWidth="sm" fullWidth={true}>
            <DialogTitle>
                <Typography variant="h4" component="div">
                    {company?.name} - Add Tag
                </Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    label="Tag"
                    variant="outlined"
                    fullWidth={true}
                    autoFocus={true}
                    onChange={handleInputChange}
                    value={tagInput}
                    disabled={loading}
                    onKeyPress={handleKeyPress}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelAdd} disabled={loading}>
                    Cancel
                </Button>
                <Button onClick={handleCreate} disabled={loading}>
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );

    return (
        <span className="chips-list" ref={chipsRef}>
            {list}
            {expandAllButton}
            {hideButton}
            {addButton}
            {addForm}
        </span>
    );
};
