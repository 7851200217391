import { useTheme } from '@material-ui/core';
import { escapeRegExp } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import { internalClientIds, JobType } from 'shared/common/job-constants';
import { currencyFormat } from 'shared/common/string-format-utils';

import { useQuery } from '@apollo/client';
import { hasRole } from 'shared/models/user';
import { createDuration, defaultPeriods, Duration } from '../common/duration';
import { PageDialogLink } from '../common/page-dialog-link';
import { SearchTextField } from '../common/search-text-field';
import { JobEdit } from '../containers/job-edit';
import { Fee, MONTHLY_RPO_FEES } from '../graphql/queries/billing';
import { FeesProvider, useFees } from '../hooks/use-fees';
import { useLocalStorage } from '../hooks/use-local-storage';
import { useSession } from '../hooks/use-session';
import { billingPageRoutes } from './billing-page-header';
import { ClientInvoicesFees } from './client-invoices-fees';
import { dataPanelContainerStyles, selectorStyles } from './data-panel';
import { CellValue, DataPanel, DataPanelColumn } from './data-panel';
import { DurationSelector } from './duration-selector';
import { JobsTypeSelector, jobTypesForFilter } from './jobs-type-selector';
import { NavigationSelect } from './navigation-select';

type Column = 'month' | 'client' | 'job' | 'amount' | 'recruiter' | 'accountManager';

const columns: Array<DataPanelColumn<Column>> = [
    { id: 'month', label: 'Month' },
    { id: 'client', label: 'Client' },
    { id: 'job', label: 'Job' },
    { id: 'recruiter', label: 'Recruiter' },
    { id: 'accountManager', label: 'Account Manager' },
    { id: 'amount', label: 'Amount' }
];

const FeeCell: React.FC<{ value: CellValue; fee: Fee }> = ({ value, fee }) => {
    const { onEditFee } = useFees();

    const handleClick = () => {
        onEditFee?.(fee);
    };

    return (
        <span className="clickable-cell" onClick={handleClick}>
            ${(value as number).toLocaleString()}
        </span>
    );
};

export const MonthlyFees = (): JSX.Element => {
    const theme = useTheme();
    const [searchText, setSearchText] = React.useState('');
    const [duration, setDuration] = React.useState<Duration>(createDuration({ period: 'month', offset: 0 }));
    const [jobTypes, setJobTypes] = useLocalStorage<JobType[]>('monthly-fees-job-types', jobTypesForFilter.get('all'));
    const { userPermissions } = useSession();
    const disabled = !hasRole(userPermissions, 'billing_admin');

    const { data, refetch } = useQuery<
        { fees: Fee[] },
        { jobType: string[]; startTime: number; endTime: number; excludeClientIds: string[] }
    >(MONTHLY_RPO_FEES, {
        variables: {
            endTime: duration.end,
            excludeClientIds: internalClientIds,
            jobType: jobTypes,
            startTime: duration.start
        }
    });

    const getCellValue = (column: Column) => (fee: Fee) => {
        switch (column) {
            case 'month':
                return fee.incurredAt;
            case 'client':
                return fee.client?.name || '-';
            case 'job':
                return fee.job?.title || '-';
            case 'amount':
                return fee.amount;
            case 'recruiter':
                return fee.recruiter?.name || '-';
            case 'accountManager':
                return fee.accountManager?.name || '-';
        }
    };

    const getCellRenderer = {
        amount: (value: CellValue, fee: Fee) => <FeeCell value={value} fee={fee} />,
        client: (value: CellValue, fee: Fee) => (
            <PageDialogLink
                Component={ClientInvoicesFees}
                componentProps={{ clientId: fee.client?.id, activeTab: 'fees' }}
                url={`/client/${fee.client?.id}/fees`}
            >
                <a href={`/client/${fee.client?.id}/fees`}>{value}</a>
            </PageDialogLink>
        ),
        job: (value: CellValue, fee: Fee) => (
            <PageDialogLink Component={JobEdit} componentProps={{ id: fee.jobId }} url={`/job/${fee.jobId}/edit`}>
                <a href={`/job/${fee.jobId}/edit`}>{value}</a>
            </PageDialogLink>
        ),
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const getCellFilterValue = {
        month: (value: CellValue) => moment(value as number).format('MMM YYYY')
    };

    const renderSummary = React.useCallback(
        (fees: Fee[]) => {
            const total = fees.reduce((acc, fee) => acc + fee.amount, 0);
            return data?.fees ? <div>Total: {currencyFormat(total)}</div> : null;
        },
        [data?.fees]
    );

    const rowFilter = React.useCallback(
        (fee: Fee) => {
            if (!searchText) return true;

            const searchRegex = new RegExp(escapeRegExp(searchText), 'i');
            return !!(
                fee.recruiter?.name?.match(searchRegex) ||
                fee.client?.name?.match(searchRegex) ||
                fee.job?.title?.match(searchRegex) ||
                fee.accountManager?.name?.match(searchRegex)
            );
        },
        [searchText]
    );

    return (
        <div css={dataPanelContainerStyles(theme)}>
            <div className="selectors">
                <div css={selectorStyles}>
                    <SearchTextField value={searchText} onValueChange={setSearchText} variant="outlined" />
                    <NavigationSelect routes={billingPageRoutes} variant="outlined" />
                    <DurationSelector selected={duration} onSelect={setDuration} periods={defaultPeriods} />
                    <JobsTypeSelector selected={jobTypes} onChange={setJobTypes} />
                </div>
            </div>
            <div className="data-panel">
                <FeesProvider data={data?.fees} disabled={disabled} refetch={refetch}>
                    <DataPanel
                        columns={columns}
                        data={data?.fees}
                        getCellValue={getCellValue}
                        getCellRenderer={getCellRenderer}
                        getCellFilterValue={getCellFilterValue}
                        sortableColumns={['month', 'amount', 'recruiter', 'accountManager', 'client']}
                        filterableColumns={['month', 'client', 'recruiter', 'accountManager']}
                        rowFilter={rowFilter}
                        initialSortColumn="month"
                        initialSortDirection="desc"
                        renderSummary={renderSummary}
                    />
                </FeesProvider>
            </div>
        </div>
    );
};
